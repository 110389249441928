import React, {useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Button, Card,
    Chip, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, List, ListItem, ListItemText, Paper,
    Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddUserTag from "./addUserTag";

function TagItem({group, toDelete, users}) {
    const [selected, setSelected] = useState(undefined)
    return <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={group.id}>
            <Typography variant="title">
                {group.name}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Box>
                {group.tags?.map(tag => <Chip
                        key={tag.id}
                        color="primary"
                        label={tag.name}
                        variant={selected?.id === tag.id ? 'filled' : 'outlined'}
                        onClick={() => setSelected(tag)}
                        onDelete={() => toDelete(tag)}
                        sx={{margin: (theme) => theme.spacing(1)}}
                    />
                )}
            </Box>

            {selected && <Box sx={{my: 1}}>
                <Typography>Users with {selected.name} :</Typography>
                <List sx={{maxHeight: "500px", overflow: "scroll"}}>
                    {users.filter(el => el.userTags.find(tt => tt.id === selected.id)).map((user, key) =>
                        <ListItem key={key}><ListItemText primary={user.name} secondary={user.email} /></ListItem>
                    )}
                </List>
            </Box>}

            <AddUserTag tagGroupId={group.id}
                        // onCreated={(newTag) => setTags([...tags, newTag])}
            />
        </AccordionDetails>
    </Accordion>
}

export default function UserTagList({tags, users, onDelete}) {
    const [toDelete, setToDelete] = useState(undefined);
    const [open, setOpen] = useState(null)

    function handleDelete() {
        if (onDelete && toDelete) onDelete(toDelete.id)
        setToDelete(undefined);
    }

    function handleClose() {
        setToDelete(undefined);
    }

    return <Box sx={{paddingY: (theme) => theme.spacing(4)}}>
        {tags && tags.map((group, index) => <TagItem key={group.id} group={group} toDelete={setToDelete} users={users}/>)}
        <Dialog
            open={!!toDelete}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Are you sure to delete the tag [${toDelete && toDelete.name}] ?`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You will lost every links between tags and users forever !
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleDelete} autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </Box>
}