import React, {useEffect, useState} from 'react'
import {
    Autocomplete,
    Box, Button,
    Checkbox, FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, TextField,
    Typography
} from "@mui/material";
import {REQUEST_STATUS, useRequest} from "../../hooks/request";
import UserTagsSelector from "./userTags.selector";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

export default function UsersSelector({onSelect, searchSelect= true, tags = true, leads = true, defaultIncludedIds = []}) {
    const {response} = useRequest('/client/list')
    const {response: leadsResponse} = useRequest('/lead/list')
    const [users, setUsers] = useState();
    const [selectedTags, setSelectedTags] = useState([]);
    const [visible, setVisible] = useState([])
    const [exclude, setExclude] = useState([])
    const [include, setInclude] = useState([])
    const [notifyLeads, setNotifyLeads] = useState(true);
    const [visibleLeads, setVisibleLeads] = useState([])

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS && response.data) {
            setUsers(response.data)
            if (defaultIncludedIds.length > 0) {
                setInclude([...include, ...response.data.filter(el => defaultIncludedIds.includes(el.id))])
            }
        }
    }, [response])

    useEffect(() => {
        if (selectedTags && onSelect) onSelect({tags: selectedTags.map(el => el.id), include: include.map(el => el.id), exclude: exclude.map(el => el.id), leads: visibleLeads.map(el => el.id)})
    }, [selectedTags, include, exclude, visibleLeads])

    useEffect(() => {
        if (tags && selectedTags && users) {
            const grIds = [];
            selectedTags.forEach(tag => {
                if (!grIds.find(el => el === tag.tagGroupId)) grIds.push(tag.tagGroupId)
            })
            let tmp = [];
            let tmp2 = [];
            if (grIds.length > 0) {
                tmp = [...users];
                if (leadsResponse && leadsResponse.status === REQUEST_STATUS.SUCCESS && leadsResponse.data) {
                    tmp2 = [...leadsResponse.data]
                }
                grIds.forEach(groupId => {
                    const needTags = selectedTags.filter(tag => tag.tagGroupId === groupId);
                    tmp = tmp.filter(user => user.userTags && user.userTags.find(tag => needTags.find(need => need.id === tag.id)))
                    tmp2 = tmp2.filter(user => user.userTags && user.userTags.find(tag => needTags.find(need => need.id === tag.id)))
                })
            }
            setVisible(tmp)
            if (leads) setVisibleLeads(tmp2);
        }
    }, [selectedTags])

    const handleExclude = (user) => {
        if (exclude.find(el => el.id === user.id)) {
            setExclude(exclude.filter(el => el.id !== user.id))
        } else {
            setExclude([...exclude, user])
        }
    }

    const handleExcludeIncluded = (user) => {
        if (include.find(el => el.id === user.id)) {
            setInclude(include.filter(el => el.id !== user.id))
        }
    }

    const handleToInclude = (val) => {
        if (val && !include?.find(el => el.id === val.id)) {
            setInclude([...include, val])
        }
    }

    return (<Box sx={{flex: 1}}>
        {tags && <UserTagsSelector onSelect={setSelectedTags}/>}
        {searchSelect && users && <Box>
            <Autocomplete
                onChange={(event,newValue) => {handleToInclude(newValue)}}
                disablePortal
                id={'user-selector-autocomplete'}
                options={users.filter(el => !visible.find(vv => vv.id === el.id))}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="User" />}
                renderOption={(props, option) => (
                    <Box component="li" sx={{p: 1}} {...props}>
                        {option.name} {option.email}
                    </Box>
                )}
                sx={{my: 1}}
            />
        </Box>}
        {leads && <FormControlLabel control={<Checkbox checked={notifyLeads} onChange={() => {setNotifyLeads(!notifyLeads)}}/>} label="Notify Leads from selected tags by mails" />}
        <Typography variant="h5" align="center" sx={{mt: 1}}>Selected Users</Typography>
        <List sx={{bgcolor: 'background.paper', '& ul': { padding: 0 }}}>
            {(!visible || (visible.length < 1 && include.length < 1)) && <ListItem><ListItemText primary="No users selected (select a tag or add an user)"/></ListItem>}
            {include.map(user =>
                <ListItem
                    key={user.id}
                    secondaryAction={
                        <IconButton onClick={() => handleExcludeIncluded(user)}>
                            <ClearIcon />
                        </IconButton>
                    }
                >
                    <ListItemText primary={user.name} secondary={user.email}/>
                </ListItem>
            )}
            {visible.map(user =>
                <ListItem
                    key={user.id}
                    disablePadding
                >
                    <ListItemButton onClick={() => handleExclude(user)}>
                        <ListItemText primary={user.name} secondary={user.email}/>
                        <ListItemIcon>
                            <Checkbox
                                edge="end"
                                checked={!exclude.find(el => el.id === user.id)}
                                disableRipple
                            />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
            )}
        </List>
        { notifyLeads && leads &&
            <>
                <Typography variant="h5" align="center" sx={{mt: 1}}>Leads</Typography>
                {visibleLeads.map(lead =>
                    <ListItem
                        key={lead.id}
                        disablePadding
                    >
                        <ListItemButton>
                            <ListItemText primary={lead.name} secondary={lead.email}/>
                        </ListItemButton>
                    </ListItem>
                )}
            </>

        }

    </Box>)
}