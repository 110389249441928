import React, { useEffect, useRef, useState } from "react";
import { Autocomplete } from "@mui/lab";
import {
  REQUEST_METHOD,
  useLazyRequest,
  useRequest,
} from "../../hooks/request";
import { TextField } from "@mui/material";

export default function ProductSearch({
  onSelect,
  sx,
  focus = false,
  defaultSelected = undefined,
  excludeIds = [],
}) {
  const inputRef = useRef(undefined);
  const { request, loading, response } = useLazyRequest(
    "/product/search",
    REQUEST_METHOD.GET
  );
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    request();
  }, []);

  useEffect(() => {
    if (response?.data && value === "" && defaultSelected) {
      setValue(response.data.find((el) => el.id === +defaultSelected));
      onSelect(response.data.find((el) => el.id === +defaultSelected));
    }
  }, [response]);

  useEffect(() => {
    if (inputRef && inputRef.current && focus) {
      inputRef.current.focus();
    }
  }, [inputRef, response]);

  const searchProduct = (str) => {
    if (!loading) request({ name: str }, {});
  };

  return (
    <React.Fragment>
      <Autocomplete
        options={
          response?.data
            ? response?.data.filter(
                (el) => !excludeIds.find((tt) => tt == el.id)
              )
            : []
        }
        sx={{ width: "100%", ...sx }}
        getOptionLabel={(pdt) => (pdt === "" ? pdt : pdt.name)}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        filterOptions={(x) => x}
        loading={loading}
        onChange={(_, newValue) => {
          onSelect(newValue);
          setValue(newValue);
        }}
        onInputChange={(_, newInputValue) => {
          searchProduct(newInputValue);
          setInputValue(newInputValue);
        }}
        value={value}
        inputValue={inputValue}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Product"
            variant="outlined"
            inputRef={inputRef}
          />
        )}
      />
    </React.Fragment>
  );
}
