import React, {useEffect, useState} from 'react'
import {useRefetchContext} from "../../../contexts/refetch.context";
import {useSnackbar} from "notistack";
import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest} from "../../../hooks/request";
import {Box, Button} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import BookForm from "../book.form";

export default function BookFormController({book, type = "sell", product, onSubmit, drawSource = true}) {
    const {setToRefetch} = useRefetchContext()
    const {enqueueSnackbar} = useSnackbar()
    const {response: putResponse, loading: putLoading, request: put} = useLazyRequest(`/book/${book && book.id}/close`, REQUEST_METHOD.PUT)
    const {response: postResponse, loading: postLoading, request: post} = useLazyRequest(`/book/${type.toLowerCase()}`, REQUEST_METHOD.POST)

    const [data, setData] = useState(null)

    const _onSubmit = () => {
        if (!data) return
        post({}, {
            price: +data.price, //elem.offers && elem.offers[0] && elem.offers[0].bestPrice,
            stock: +data.stock,
            minQuantity: +data.minQuantity,
            meta: {estimateTimeOfArrival: +data.estimatedTimeOfArrival, source: data.source, sourceId: data.sourceId, manual: 1},
            productId: product.id,
            parentId: book && book.id,
            userId: book && book.userId,
            from: book && book.from
        })
    }

    useEffect(() => {
        if (postResponse && postResponse.status === REQUEST_STATUS.SUCCESS) {
            enqueueSnackbar('Offer created', {variant: "success"})
            setToRefetch(['/book'])
            onSubmit && onSubmit(postResponse.data)
        }
    }, [postResponse])

    useEffect(() => {
        if (putResponse && putResponse.status === REQUEST_STATUS.SUCCESS) {
            enqueueSnackbar('Offer closed', {variant: "success"})
            setToRefetch(['/book'])
            onSubmit && onSubmit()
        }
    }, [putResponse])

    return (
        <React.Fragment>
            <BookForm drawSource={drawSource} book={book} product={product} onChange={setData} />

            <Box sx={{display: 'flex', justifyContent: "flex-end", gap: 2}}>
                {book && <Button sx={{width: "12%"}} variant="contained" color={"error"} onClick={() => put()}
                         fullWidth>{'close'}</Button>}
                <LoadingButton loading={putLoading || postLoading} sx={{width: "12%"}} variant="contained" onClick={_onSubmit} disabled={!data} fullWidth>{'submit'}</LoadingButton>
            </Box>
        </React.Fragment>
    )
}