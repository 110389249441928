import React, {useEffect, useState} from 'react';
import {
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	ComposedChart,
	Legend,
	BarChart,
	Bar
} from 'recharts';
import {Box, Typography, useTheme} from "@mui/material";
import moment from "moment";
import {useLazyRequest} from "../../hooks/request";
import {initStockTrends} from "../../utils/stockTrend";



export default React.memo(function StockTrendSummary({productId}) {
	const {request, response, loading} = useLazyRequest(`/book/product/${productId}`)
	const theme = useTheme()
	const [series, setSeries] = useState(undefined)

	function getGraphColor (index) {
		switch (index) {
			case 0:
				return theme.palette.info;
			case 1:
				return theme.palette.secondary;
			default:
				return theme.palette.warning;

		}
		return;
	}

	useEffect(() => {
		if (!series && !loading && !response) {
			request({types: ['WTS'], stats: ['OPEN', 'CLOSE']})
		}
	}, [productId])

	useEffect(() => {
		if (response && response.data) {
			setSeries(initStockTrends(response.data))
		}
	}, [response])
	if (!series) return <Box/>

	return (
		<ResponsiveContainer width={"100%"} height={300}>
			<BarChart
				width={500}
				height={300}
				data={series}
				margin={{
					top: 20,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="date"/>
				<YAxis />
				<Legend />
				<Bar dataKey="td" stackId="a" fill="#00B0E2" name="TechData" barSize={7} />
				<Bar dataKey="ac" stackId="a" fill="#ff7800" name="A&C"  barSize={7} />
				<Bar dataKey="ig" stackId="a" fill="#3171B8" name="Ingram"  barSize={7}/>
				<Tooltip content={({ active, payload, label }) =>
					<Box sx={{backgroundColor: theme.palette.background.paper, p: 1, borderRadius: 2}}>
						{payload.map(pp => <Typography>{`${pp?.name}: ${pp?.value}`}</Typography>)}
						<Typography>{label}</Typography>
					</Box>
			} />
			</BarChart>
		</ResponsiveContainer>
	)
})