import React, {useEffect, useState} from 'react';
import {
    XAxis,
    YAxis, BarChart,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend, Bar
} from 'recharts';
import {Box, Typography, useTheme} from "@mui/material";
import {useLazyRequest} from "../../hooks/request";
import {initMarketTrends} from "../../utils/marketTrend";



export default function MarketTrendSummary({productId}) {
    const {request, response, loading} = useLazyRequest(`/product/market-trends/${productId}`)
    const theme = useTheme()
    const [series, setSeries] = useState(undefined)

    function getGraphColor (index) {
        switch (index) {
            case 0:
                return theme.palette.info;
            case 1:
                return theme.palette.secondary;
            default:
                return theme.palette.warning;

        }
        return;
    }

    useEffect(() => {
        if (!series && !loading && !response) {
            request()
        }
    }, [productId])

    useEffect(() => {
        if (response && response.data) {
            console.log(response.data)
            setSeries(initMarketTrends(response.data))
        }
    }, [response])

    if (!series) return <Box/>


    return (
        <ResponsiveContainer width={"100%"} height={300}>
            <BarChart
              width={500}
              height={300}
              data={series}
              margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
              }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date"/>
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="supply" fill={theme.palette.error.main} barSize={5} />
                <Bar dataKey="demand" fill={theme.palette.success.main} barSize={5}/>
                <Tooltip content={({ active, payload, label }) =>
                  <Box sx={{backgroundColor: theme.palette.background.paper, p: 1, borderRadius: 2}}>
                      {payload.map(pp => <Typography>{`${pp?.name}: ${pp?.value}`}</Typography>)}
                      <Typography>{label}</Typography>
                  </Box>
                } />
            </BarChart>
        </ResponsiveContainer>
    )
}