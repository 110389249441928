import React from 'react'
import {Box, Paper, TextField, Typography} from "@mui/material";
import NumTextField from "../../NumTextField";
import UserSearch from "../../clients/user.search";
import SelectRelation from "./selectRelation";
import ProductMetrics from "../../product/productMetrics";
import CreateRowInputs from "./createRowInputs";

function SelectFrom({row, onRowChange}) {
    if (row.type === 'ASK') return null
    if (row.type === 'BID') return <React.Fragment>
        <Typography variant='h6'>From</Typography>
        <Paper sx={{padding: 2}}>
            <UserSearch
                sx={{ marginTop: 2}}
                type={['DISTRIBUTOR']}
                label={'Distributor'}
                onSelect={(user) => onRowChange({...row, chainHelperCreateWts: {type: 'WTS', stock: 0, price: 0, userId: user?.id, meta: {estimatedTimeOfArrival: 0}}, chainHelperWtsSelected: null, attachedTo: null})}
            />
        </Paper>
    </React.Fragment>
    return (
        <React.Fragment>
            <Typography variant='h6'>From</Typography>
            <Paper sx={{padding: 2}}>
                <UserSearch
                    focus={true}
                    type={row.type === 'WTS' ? ['DISTRIBUTOR'] : ['CUSTOMER']}
                    label={row.type === 'WTS' ? 'Distributor' : 'Customer'}
                    onSelect={(user) => onRowChange({...row, userId: user?.id})}
                />
            </Paper>
        </React.Fragment>
    )
}

export default function CreatRowForm({row, onChange, product}) {


    return (
        <Box>
            <Typography variant="h6" sx={{marginBottom: 3}}>Create {row.type.toUpperCase()}</Typography>

            <Typography >{product.name}</Typography>
            <ProductMetrics product={product} />

            <Box sx={{marginTop: 3}}>
                <SelectFrom  row={row} onRowChange={onChange} />
            </Box>

            <Box sx={{marginTop: 3}}>
                <TextField fullWidth label="Note" multiline={true} onChange={(val) => onChange({...row, meta: {...row.meta, note: val.target.value}})}/>
            </Box>


            <Box sx={{marginTop: 3}}>
                <Typography variant={'h6'}>Order inputs</Typography>

                <Paper sx={{padding: 2}}>
                    <CreateRowInputs row={row} onRowChange={onChange} />
                </Paper>
            </Box>

            {row.type === 'BID' &&  <Box sx={{marginTop: 3}}>
                <SelectRelation row={row} onRowChange={onChange}/>
            </Box>}



        </Box>
    )
}