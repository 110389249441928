import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material';
import AuthContext from "./contexts/auth.context";
import AppRouter from "./router/appRouter";
import RefetchContext from "./contexts/refetch.context";
import { SnackbarProvider } from 'notistack'
import SettingsContext from './contexts/settings.context';
import 'react-virtualized/styles.css';
import ModalsContext from "./contexts/modals.context";

export const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'dark' ?
      {
        neutral: {
          main: '#121519',
          contrastText: '#fff',
        },
      } : {
        neutral: {
          main: '#d5d5d5',
          contrastText: '#101010',
        },
        background: {
          default: '#efefef'
        }
      })
  },
})


function App() {
  const [mode, setMode] = useState('dark');
  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
      },
    }),
    [],
  );

  let theme = useMemo(() => createTheme((adaptV4Theme(getDesignTokens(mode)))), [mode]);

  theme = createTheme(theme, {
    components: {
      MuiTypography: {
        styleOverrides: {
          caption: {
            [theme.breakpoints.down('md')]: {
              fontSize: "0.5rem"
            }
          },
          body1: {
            root: {
              fontSize: "0.8rem",
            },
            [theme.breakpoints.down('md')]: {
              fontSize: "0.7rem"
            }
          }
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: "7px",
          }
        }
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: "none"
          }
        }
      },
      MuiCssBaseline: {
        '@global': {
          '*': {
            boxSizing: 'border-box'
          },
          body: {
            backgroundColor: "rgb(233, 233, 233)"
          },

          "#root, body, html, #root-container": {
            height: "100%"
          },
          a: {
            textDecoration: "none",
            color: "black"
          }
        },
      },
    }
  })

  return (
    <ColorModeContext.Provider value={colorMode}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthContext>
            <RefetchContext>
              <SettingsContext>
                <SnackbarProvider>
                  <ModalsContext>
                    <AppRouter />
                  </ModalsContext>
                </SnackbarProvider>
              </SettingsContext>
            </RefetchContext>
          </AuthContext>
        </ThemeProvider>
      </StyledEngineProvider>
    </ColorModeContext.Provider>
  );
}

ReactDOM.render(<App />, document.querySelector('#root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
