import React, { useEffect, useState } from "react";
import axios from "axios";
import { useRefetchContext } from "../contexts/refetch.context";
import { useAuth } from "../contexts/auth.context";
import { useHistory } from "react-router-dom";

const baseURL = process.env.REACT_APP_API_URL || "https://api.mono.lu";
// const baseURL = "http://localhost:8080";
// const baseURL = 'http://18.198.207.104:8080

export const REQUEST_METHOD = {
  PUT: "put",
  GET: "get",
  POST: "post",
  DELETE: "delete",
};

export const REQUEST_STATUS = {
  SUCCESS: "success",
  ERROR: "error",
};

export const useRequest = (
  url,
  method = REQUEST_METHOD.GET,
  _params = {},
  _body = {}
) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const { toRefetch } = useRefetchContext();

  const [params, setParams] = useState(_params); //used for the toReload event
  const [body, setBody] = useState(_body); //used for the toReload event
  const { user, requestToken } = useAuth();

  const history = useHistory();

  const fetch = (_Fparams, _Fbody, retry = 0, token = undefined) => {
    if (!_Fparams) _Fparams = params;
    if (!_Fbody) _Fbody = body;

    if (_Fparams !== params) setParams(_Fparams);
    if (_Fbody !== body) setBody(_Fbody);

    setLoading(true);
    axios({
      baseURL,
      method,
      headers: {
        Authorization: user?.token
          ? `Bearer ${token ?? user.token}`
          : undefined,
      },
      url,
      params: _Fparams,
      data: _Fbody,
    })
      .then((response) => {
        setLoading(false);
        if (response.status !== 200) {
          setData({ status: REQUEST_STATUS.ERROR });
        } else {
          setData({ status: REQUEST_STATUS.SUCCESS, data: response.data });
        }
      })
      .catch(async (error) => {
        setLoading(false);
        setData({
          status: REQUEST_STATUS.ERROR,
          message: error?.response?.data?.message,
        });
        if (error.response && error.response.status === 401) {
          console.log(retry);
          if (retry < 3) {
            console.log(retry);
            const token = await requestToken();
            if (token) fetch(_Fparams, _body, retry + 1, token);
          } else {
            localStorage.removeItem("user");
            history.push("/auth/signIn");
          }
        }
      });
  };

  useEffect(() => {
    if (
      toRefetch &&
      Array.isArray(toRefetch) &&
      toRefetch.find((elem) => elem === url) &&
      method === REQUEST_METHOD.GET
    ) {
      fetch(params, body);
    }
  }, [toRefetch]);

  useEffect(() => {
    fetch(params, body);
  }, []);

  return { refetch: fetch, loading, response: data };
};

export const useLazyRequest = (url, method = REQUEST_METHOD.GET) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const { toRefetch } = useRefetchContext();

  const [params, setParams] = useState(null); //used for the toReload event
  const [body, setBody] = useState(null); //used for the toReload event
  const { user, requestToken } = useAuth();

  const history = useHistory();

  const request = (_params = {}, _body = {}, retry = 0, token = undefined) => {
    if (_params !== params) setParams(_params);
    if (_body !== body) setBody(_body);

    setLoading(true);
    setData(undefined);

    axios({
      baseURL,
      url,
      headers: {
        Authorization: user?.token
          ? `Bearer ${token ?? user.token}`
          : undefined,
      },
      method,
      params: _params,
      data: _body,
    })
      .then((response) => {
        setLoading(false);
        if (response.status !== 200) {
          setData({ status: REQUEST_STATUS.ERROR });
          setData(response.data);
        } else {
          setData({ status: REQUEST_STATUS.SUCCESS, data: response.data });
        }
      })
      .catch(async (error) => {
        setLoading(false);
        setData({ status: REQUEST_STATUS.ERROR });
        if (error.response && error.response.status === 401 && retry < 3) {
          const token = await requestToken();
          if (token) request(_params, _body, retry + 1, token);
        } else if (error.response && error.response.status === 401) {
          localStorage.removeItem("user");
          history.push("/auth/signIn");
        }
      });
  };

  useEffect(() => {
    if (
      data &&
      toRefetch &&
      Array.isArray(toRefetch) &&
      toRefetch.find((elem) => elem === url) &&
      method === REQUEST_METHOD.GET
    ) {
      request(params, body);
    }
  }, [toRefetch]);

  return { request, loading, response: data };
};
