import React, {useEffect, useState} from 'react'
import {Box, Paper, TextField, Typography} from "@mui/material";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import {useHistory, useNavigate} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";

export default function SignInEmailPage() {
    const auth = getAuth();
    const history = useHistory()
    const [email, setEmail] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined)

    const handleSignIn = () => {
        if (!window.location.href || !isSignInWithEmailLink(auth, window.location.href)) {
            setError('You must use the link received by mail, or ask for a new one.')
            return;
        }
        setLoading(true)
        signInWithEmailLink(auth, email, window.location.href).then((result) => {
            setLoading(false)
            history.replace('/auth/setPassword')
        })
            .catch((error) => {
                setLoading(false)
                setError('Error, Please contact the support.')
            });
    }

    return <Box sx={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Paper sx={{padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2}}>
            {error && <Typography color='error'>{error}</Typography>}
            <TextField label="Email" required type='email' value={email} onChange={(event) => setEmail(event.target.value)} />
            <LoadingButton disabled={!email} loading={loading} onClick={() => handleSignIn()} variant='outlined'>Activate your account</LoadingButton>
        </Paper>
    </Box>
}