import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { useModalsContext } from "../contexts/modals.context";

export default function ActionsBar({
  buyerId = undefined,
  sellerId = undefined,
  disWts = false,
  disWtb = false,
  disBid = false,
  disAsk = false,
  productId = undefined,
}) {
  const { setOpenRow, setOpenTP } = useModalsContext();

  return (
    <Stack direction="row" sx={{ flexWrap: "wrap", gap: 1 }}>
      <Button
        sx={{ flex: 1 }}
        size="small"
        variant="contained"
        color={"warning"}
        onClick={() =>
          setOpenRow({ type: "wts", open: true, sellerId, productId })
        }
        disabled={disWts}
      >
        WTS
      </Button>
      <Button
        sx={{ flex: 1 }}
        size="small"
        variant="contained"
        color={"error"}
        onClick={() =>
          setOpenRow({ type: "bid", open: true, sellerId, productId })
        }
        disabled={disBid}
      >
        BID
      </Button>
      <Button
        sx={{ flex: 1 }}
        size="small"
        variant="contained"
        color={"success"}
        onClick={() =>
          setOpenRow({ type: "ask", open: true, buyerId, productId })
        }
        disabled={disAsk}
      >
        ASK
      </Button>
      <Button
        sx={{ flex: 1 }}
        size="small"
        variant="contained"
        color={"primary"}
        onClick={() =>
          setOpenRow({ type: "wtb", open: true, buyerId, productId })
        }
        disabled={disWtb}
      >
        WTB
      </Button>
      {/* <Button variant="contained" color={"secondary"} sx={{mr: 1}} onClick={() => setOpenTP({open: true, buyerId, productId})}>TP</Button> */}
    </Stack>
  );
}
