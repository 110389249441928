import React from "react";
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import {ArrowRight} from "@mui/icons-material";

export default function LeadsList({leads, onSelect}){
    const drawRow = (lead) => {
        return (
            <ListItem hover sx={{":hover": {cursor: 'pointer'}, bgcolor: 'background.paper', borderRadius: 1, my: 1}} onClick={() => onSelect && onSelect(lead.id)} secondaryAction={
                <IconButton edge="end" aria-label="see">
                    <ArrowRight />
                </IconButton>
            }>

                <ListItemText
                    primary={lead.name}
                    secondary={lead.email}
                />
            </ListItem>
        )
    }
    return  <Box>
        <List>
            {leads && leads.map(cl => drawRow(cl))}
        </List>
    </Box>

}