import {REQUEST_STATUS, useRequest} from "../../hooks/request";
import React, {useEffect, useState} from "react";
import {Box, CircularProgress} from "@mui/material";
import {useHistory} from "react-router-dom";
import LeadsList from "./leadsList";

export default function LeadsListController(){
    const {loading, response} = useRequest('/lead/list');
    const [leads, setLeads] = useState([]);
    const history = useHistory()

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            setLeads(response.data)
        }
    }, [response])

    return <div>
        {loading ?
            <Box sx={{padding: 5, display: 'flex', justifyContent: 'center'}}>
                <CircularProgress size={50} />
            </Box>
            :
            <LeadsList leads={leads} onSelect={(id) => history.push(`/lead/${id}`)}/>
        }
    </div>

}