import {REQUEST_STATUS, useRequest} from "../../hooks/request";
import React, {useEffect, useState} from "react";
import {Box, CircularProgress, Typography} from "@mui/material";
import ClientsList from "./clientsList";
import {useHistory} from "react-router-dom";

export default function ClientsListController({search}){
    const {loading, response, refetch} = useRequest('/client/list');
    const [clients, setClients] = useState([]);
    const history = useHistory()



    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            if (search && search !== '') {
                setClients(response.data.filter(el => el.name?.includes(search) || el.email?.includes(search)))
            } else {
                setClients(response.data)
            }
        }
    }, [response, search])

    return <div>
        {loading ?
            <Box sx={{padding: 5, display: 'flex', justifyContent: 'center'}}>
                <CircularProgress size={50} />
            </Box>
            :
            <ClientsList clients={clients} onSelect={(id) => history.push(`/client/${id}`)} onChange={() => refetch()}/>
        }
    </div>

}