import moment from 'moment'

export const initSeries = (data) => {
    let flattedData = []

    for (let datum of data ) {
        flattedData.push(datum)
        if (datum.meta && datum.meta.noChanges && Array.isArray(datum.meta.noChanges)) {
            for (let noChangeDate of datum.meta.noChanges) {
                flattedData.push({...datum, createdAt: noChangeDate})
            }
        }
    }

    flattedData = flattedData.sort((a,b) => {
        let dateA = new Date(a.createdAt).getTime();
        let dateB = new Date(b.createdAt).getTime();
        return dateA - dateB;})
    return flattedData.map(priceTrend => ({x: new Date(priceTrend.createdAt), y: priceTrend.price}))
}

export const initPriceTrends = (data, formula) => {
    const series = new Map()
    const names = new Set()
    const namesAx = new Set()

    if (data?.main) {
        for (let datum of data.main ) {
            if (datum.price) {
                names.add(datum.name)
                const date = moment(datum.createdAt).format("DD-MM-YYYY");
                if (series.has(date)) {
                    let tmp = {...series.get(date)}
                    tmp[datum.name] = datum.price + formula;
                    series.set(date, tmp)
                } else {
                    let tmp = {time: new Date(datum.createdAt)}
                    tmp[datum.name] = datum.price + formula;
                    series.set(date,tmp)
                }
            }
        }
    }
    if (data?.ax) {
        for (let datum of data?.ax ) {
            if (datum.price) {
                namesAx.add(datum.name)
                const date = moment(datum.createdAt).format("DD-MM-YYYY");
                if (series.has(date)) {
                    let tmp = {...series.get(date)}
                    tmp[datum.name] = datum.price + formula;
                    series.set(date, tmp)
                } else {
                    let tmp = {time: new Date(datum.createdAt)}
                    tmp[datum.name] = datum.price + formula;
                    series.set(date,tmp)
                }
            }
        }
    }
    return {keys: Array.from(names.values()), axKeys: Array.from(namesAx.values()), series: Array.from(series.values()).reverse()}

    // flattedData = flattedData.sort((a,b) => {
    //     let dateA = new Date(a.createdAt).getTime();
    //     let dateB = new Date(b.createdAt).getTime();
    //     return dateA - dateB;})
    //
    // return flattedData.map(priceTrend => ({x: new Date(priceTrend.createdAt), y: priceTrend.price}))
}