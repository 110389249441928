import React, {useState} from 'react'

export const Context = React.createContext(null)

export default function RefetchContext({children}) {
    const [toRefetch, setToRefetch] = useState()

    return (
        <Context.Provider value={{toRefetch, setToRefetch}}>
            {children}
        </Context.Provider>
    )
}

export const useRefetchContext = () => (React.useContext(Context))