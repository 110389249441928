import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest, useRequest} from "../../hooks/request";
import React, {useEffect, useState} from "react";
import {Box, CircularProgress} from "@mui/material";
import {useHistory} from "react-router-dom";
import TargetPriceList from "./targetPriceList";

export default function TargetPricesController(){
    const {loading, response, refetch} = useRequest('/target-price/list');
    const {request, loading: loadingDel, response: responseDel} = useLazyRequest('/target-price/delete', REQUEST_METHOD.DELETE);

    useEffect(() => {
        refetch()
      }, [responseDel])

    if (loading || loadingDel) return <Box sx={{padding: 5, display: 'flex', justifyContent: 'center'}}>
        <CircularProgress size={50} />
    </Box>

    return <div>
        {response?.data && <TargetPriceList tps={response.data} onDelete={(id) => request({id})}/>}
    </div>

}