import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest} from "../../hooks/request";
import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    InputLabel, OutlinedInput, Paper, Stack,
    Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useRefetchContext} from "../../contexts/refetch.context";
import {useSnackbar} from "notistack";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth"

const URL_REDIRECT_SIGNIN = 'https://stock.lu/auth/emailSignIn'

export default function AddClient({clients}){
    const [isAdding, setIsAdding] = useState(false);
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    // const [password, setPassword] = useState()
    const {request, loading, response} = useLazyRequest('/client', REQUEST_METHOD.POST)

    const {setToRefetch} = useRefetchContext()
    const {enqueueSnackbar} = useSnackbar()

    const onAddClient = () => {
        if (disabled()) return;
        request({},  {email, name})
    }

    const disabled = () => {
        return !name || !email
    }

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            const auth = getAuth();
            // sendSignInLinkToEmail(auth, email, {url: URL_REDIRECT_SIGNIN,  handleCodeInApp: true}).then(() => {
            // }).catch((error) => {
            //     console.log(error)
            // });
            setToRefetch(['/client/list']);

            enqueueSnackbar('Client created !', {variant: "success"});
            setName(undefined);
            setEmail(undefined);
            // setPassword(undefined)
            setIsAdding(false);
        }
    }, [loading, response])

    return  <div>
        {isAdding && <Paper sx={{p: 4}}>
            <Stack spacing={2}>
                <Typography sx={{textAlign: 'left'}} variant="subtitle1">Add a client</Typography>
                <FormControl variant="outlined" required>
                    <InputLabel id="name-select-label">Name</InputLabel>
                    <OutlinedInput
                        id="name"
                        label="Name"
                        type="text"
                        value={name}
                        onChange={(({ target: { value } }) => setName(value))}
                    />
                </FormControl>
                <FormControl variant="outlined" required>
                    <InputLabel id="name-select-label">Email</InputLabel>
                    <OutlinedInput
                        id="email"
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(({ target: { value } }) => setEmail(value))}
                    />
                </FormControl>
                {/*<FormControl variant="outlined" required>*/}
                {/*    <InputLabel id="name-select-label">Password</InputLabel>*/}
                {/*    <OutlinedInput*/}
                {/*        id="password"*/}
                {/*        label="Password"*/}
                {/*        type="text"*/}
                {/*        value={password}*/}
                {/*        onChange={(({ target: { value } }) => setPassword(value))}*/}
                {/*    />*/}
                {/*</FormControl>*/}
            </Stack>
        </Paper>}
        <Box sx={{display: 'flex', justifyContent: 'end', marginTop: theme => theme.spacing(2)}}>
            <Button sx={{marginX: 1}} variant="outlined" onClick={() => setIsAdding(!isAdding)}>{isAdding ? 'Cancel' : 'Add Client'}</Button>
            {isAdding && <Button sx={{marginX: 1}} variant="contained" disabled={loading || disabled()} onClick={onAddClient} startIcon={<AddIcon/>}>Add client</Button>}
        </Box>


    </div>

}