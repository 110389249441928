import {Chip, CircularProgress, Container, Paper, Typography, useTheme} from "@mui/material";
import {Row} from "../components/book/row";
import {formatRow, getTypeColor} from "../utils/book.utils";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {REQUEST_METHOD, useLazyRequest} from "../hooks/request";
import GoBack from "../components/goBack";
import {Box} from "@mui/system";
import WtbActions from "../components/book/row/wtbActions";
import AskActions from "../components/book/row/askActions";
import WtsActions from "../components/book/row/wtsActions";
import BidActions from "../components/book/row/bidActions";
import RowStateIcon from "../components/book/row/rowStateIcon";
import BuyHistory from "../components/buy/buyHistory";
import {useRefetchContext} from "../contexts/refetch.context";
import SelectRelation from "../components/book/createRow/selectRelation";
import {LoadingButton} from "@mui/lab";
import RowNote from "../components/book/row/rowNote";
import ProductMetrics from "../components/product/productMetrics";

function RenderRowSummary({row}) {
	const theme = useTheme()
	const history = useHistory()
	console.log(row.user)
	return (
		<Paper sx={{padding: 2, display: "flex", flexDirection: 'row', justifyContent: 'space-between'}}>
			<Box sx={{flex: 1}}>
				<Typography variant={"caption"}>Type</Typography>
				<Typography sx={{color: getTypeColor(theme, row.type)}} >{row.type}</Typography>
			</Box>
			<Box sx={{flex: 2}} onClick={() => row?.user.type === 'CUSTOMER' ? history.push('/client/' + row?.user.id) : history.push('/distributor/' + row?.user.id)}>
				<Typography variant={"caption"}>From</Typography>
				<Typography >{row.user?.name}</Typography>
			</Box>

			<Box sx={{textAlign: 'right', flex: 1}}>
				<Typography  variant={"caption"}>ETA</Typography>
				<Typography >{row.meta?.estimatedTimeOfArrival || '-'}</Typography>
			</Box>

			<Box sx={{textAlign: 'right', flex: 1}}>
				<Typography  variant={"caption"}>Price</Typography>
				<Typography >{row.price.toFixed(2)}</Typography>
			</Box>

			<Box sx={{textAlign: 'right', flex: 1}}>
				<Typography variant={"caption"}>Stock</Typography>
				<Typography>{row.stock || '-'}</Typography>
			</Box>

			<Box sx={{alignSelf: 'center', marginLeft: 2}}>
				<RowStateIcon row={row} iconsSize={50}/>
			</Box>
		</Paper>
	)
}

function RenderRelation({row}) {
	const history = useHistory()
	const location = useLocation()
	const route = useRouteMatch()

	const detailsUrl = route.path === "/book/:rowId" ? `/book/` : `/product/book/`

	return (
		<Box sx={{display: {xs: 'block', sm: 'flex'}, flexWrap:  {xs: 'none', sm: 'wrap'}}}>
			<Box sx={{width: 'auto', flexGrow: 1, flexShrink: 1, marginRight: {xs: 0, sm: 2}}}>
				<Typography variant={'h6'}>Attached to</Typography>
				{!!row.attachedTo.length ? row.attachedTo.map(elem => <Row showState withState row={formatRow(elem)} onClick={() => history.push(`${detailsUrl}${elem.id}`)} />) : <Box sx={{textAlign:'center'}}>None</Box>}
			</Box>
			<Box sx={{width: 'auto', flexGrow: 1, flexShrink: 1}}>
				<Typography variant={'h6'}>Attached by</Typography>
				{!!row.attachedBy.length ? row.attachedBy.map(elem => <Row showState withState row={formatRow(elem)} onClick={() => history.push(`${detailsUrl}${elem.id}`)} />) : <Box sx={{textAlign:'center'}}>None</Box>}
			</Box>
		</Box>
	)
}

function RenderSelectRelation({row}) {

	const [relation, setRelation] = useState({
		...row,
		chainHelperWtsSelected: null,
		chainHelperCreateWts: null,
		attachedTo: null,
	})
	const {loading, response, request} = useLazyRequest(`/book/wtb/${row.id}/attachTo`, REQUEST_METHOD.POST)
	const {setToRefetch} = useRefetchContext()

	useEffect(() => {
		if (response && response.status !== 'error') {
			setToRefetch([`/book/details/${row.id}`])
		}
	}, [response])

	const onConnect = () => {
		request({}, relation)
	}

	const isDisabled = () => {
		return !relation.chainHelperWtsSelected && !relation.chainHelperCreateWts && !relation.attachedTo
	}

	return (
		<React.Fragment>
			<SelectRelation row={relation} onRowChange={setRelation} />
			<Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
				<Box/>
				<LoadingButton loading={loading} onClick={onConnect} disabled={isDisabled()}>Connect</LoadingButton>
			</Box>
		</React.Fragment>
	)

}

function RenderAction({row, onSubmit}) {

	switch (row.type) {
		case 'WTB':
			return <WtbActions row={row} onSubmit={onSubmit} />
		case 'ASK':
			return <AskActions row={row} onSubmit={onSubmit} />
		case 'BID':
			return <BidActions row={row} onSubmit={onSubmit} />
		case 'WTS':
			return <WtsActions row={row} onSubmit={onSubmit} />
	}
}

function RenderRow ({row, onSubmit}) {
	const history = useHistory()

	return (
		<React.Fragment>
			<Box sx={{}}>
				<Typography onClick={() => history.push('/product/' + row?.product?.id)}>{row.product?.name}</Typography>
				{row.product?.metrics && <Box sx={{mb: 1}}><ProductMetrics product={row.product}/></Box>}
				<RenderRowSummary row={row} />
			</Box>
			<Box  sx={{marginTop: 2}}>
				<RowNote row={row}/>
			</Box>


			<Box sx={{marginTop: 3}}>
				<BuyHistory buy={row} />
			</Box>

			{
				row.type === 'ASK' && <Box>
					<Typography variant={'h6'}>Visible by</Typography>
					{row.scope?.length ?  <Box>
						<Typography>Groups</Typography>
						{row.scope.map(scope => <Chip
							key={scope.id}
							color="primary"
							label={scope.name}
							sx={{margin: (theme) => theme.spacing(1)}}
						/>)}
					</Box> : undefined}

					{row.includedUsers?.length ?  <Box>
						<Typography>Included Users</Typography>
						{row.includedUsers.map(user => <Chip
							key={user.id}
							color="success"
							label={user.name}
							sx={{margin: (theme) => theme.spacing(1)}}
						/>)}
					</Box> : undefined}

					{row.excludedUsers?.length ?  <Box>
						<Typography>Excluded Users</Typography>
						{row.excludedUsers.map(user => <Chip
							key={user.id}
							color="error"
							label={user.name}
							sx={{margin: (theme) => theme.spacing(1)}}
						/>)}
					</Box> : undefined}
				</Box>
			}

			{
				row.type === 'WTB'
				&& (!row.attachedTo || !row.attachedTo.length)
				&& <Box sx={{marginTop: 3}}>
					<RenderSelectRelation row={row} />
				</Box>
			}
			{/* <Box sx={{marginTop: 3}}>
				<RenderRelation row={row} />
			</Box> */}
			<Box sx={{my: 3}}>
				<RenderAction row={row} onSubmit={onSubmit} />
			</Box>
		</React.Fragment>
	)
}

export default React.memo(function EditRowController({}) {
	const {rowId} = useParams()
	const {loading, response, request} = useLazyRequest(rowId ? `/book/details/${rowId}` :  undefined)
	const {setToRefetch} = useRefetchContext()

	useEffect(() => {
		if (rowId) request()
	}, [rowId])

	const onSubmit = () => {
		request()
		setToRefetch(['/product', '/book'])
	}

	if (!loading && !response) return null
	if (response && !response.data) return <Typography>Error</Typography>

	return (
		<Container>
			<GoBack/>
			{loading ?
				<Box sx={{display: 'flex', justifyContent: 'center', py: 2}}>
					<CircularProgress size={50}/>
				</Box>
				:
				<RenderRow row={formatRow(response.data)} onSubmit={onSubmit} />
			}
		</Container>
	)
})