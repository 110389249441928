import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest} from "../../hooks/request";
import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    InputLabel, OutlinedInput, Paper, Stack,
    Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useRefetchContext} from "../../contexts/refetch.context";
import {useSnackbar} from "notistack";

export default function AddUserTag({tagGroupId, onCreated}){
    const [isAdding, setIsAdding] = useState(false);
    const [name, setName] = useState('')
    const {request, loading, response} = useLazyRequest('/user-tag', REQUEST_METHOD.PUT)

    const {setToRefetch} = useRefetchContext()
    const {enqueueSnackbar} = useSnackbar()

    const onAddClient = () => {
        if (disabled()) return;
        request({},  {name, tagGroupId})
    }

    const disabled = () => {
        return !name
    }

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            enqueueSnackbar('Tag Group added !', {variant: "success"});
            setName('');
            if (onCreated)
                onCreated(response.data)
            else {
                setToRefetch(['/user-tag']);
                setIsAdding(false);
            }
        }
    }, [loading, response])

    return  <div>
        {isAdding && <Paper sx={{padding: theme => theme.spacing(2)}} elevation={3}>
            <Stack spacing={2}>
                <Typography sx={{textAlign: 'left'}} variant="subtitle1">{tagGroupId ? 'Add a Tag' :'Add a Tag Group'}</Typography>
                <FormControl variant="outlined" required>
                    <InputLabel id="name-select-label">Name</InputLabel>
                    <OutlinedInput
                        id="name"
                        label="Name"
                        type="text"
                        value={name}
                        onChange={(({ target: { value } }) => setName(value))}
                    />
                </FormControl>
            </Stack>
        </Paper>}
        <Box sx={{display: 'flex', justifyContent: 'end', marginTop: theme => theme.spacing(2)}}>
            <Button sx={{marginX: 1}} variant="outlined" onClick={() => setIsAdding(!isAdding)}>{isAdding ? 'Cancel' : 'Add Tag'}</Button>
            {isAdding && <Button sx={{marginX: 1}} variant="contained" disabled={loading || disabled()} onClick={onAddClient} startIcon={<AddIcon/>}>{tagGroupId ? 'Add Tag' :'Add Tag Group'}</Button>}
        </Box>


    </div>

}