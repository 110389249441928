import React, {useState} from 'react'
import {Box} from "@mui/system";
import {FormControlLabel, Radio} from "@mui/material";
import NumTextField from "../../NumTextField";
import {LoadingButton} from "@mui/lab";

export default function RejectAction({setReject, row, loading, onSubmit}) {
	const [price, setPrice] = useState(0)
	const [stock, setStock] = useState(0)

	return (
		<React.Fragment>
			<NumTextField label={'Quantity'} value={stock} onChange={setStock} />
			<NumTextField label={'Price'} sx={{mx: 1}} value={price} onChange={setPrice}  />

			<Box>
				<LoadingButton loading={loading} onClick={() => setReject(false)}>Abort</LoadingButton>
				<LoadingButton disabled={!stock && !price} loading={loading} onClick={() => onSubmit({proposedPrice: +price > 0 ? +price : undefined, proposedStock: +stock > 0 ? +stock : undefined})}>Counter</LoadingButton>
			</Box>
		</React.Fragment>
	)
}