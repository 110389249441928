import React, {useEffect, useState} from 'react'
import {Box, TextField, Typography} from "@mui/material";
import NumTextField from "../NumTextField";

export default function BookForm({onChange, book = undefined, product = undefined, drawSource = true}) {
    const [data, setData] = useState({
        stock: book && book.stock ? book.stock : 0,
        price: book && book.price ? book.price : 0,
        source: book && book.meta.source ? book.meta.source : undefined,
        sourceId: book && book.meta.sourceId ? book.meta.sourceId : undefined,
        minQuantity: book && book.minQuantity ? book.minQuantity : 0,
        estimatedTimeOfArrival: book && book.meta.estimatedTimeOfArrival ? book.meta.estimatedTimeOfArrival : null,
    })

    useEffect(() => {
        if (data) onChange(data)
    }, [data])

    const onItemChange = (field, value) => {
        let newObj = {...data}
        newObj[field] = value;
        setData(newObj)
    }

    return (
        <Box sx={{display: 'flex', flexDirection: "column",  gap: 2}}>
            {drawSource && <Box sx={{my: 2, display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center'}}>
                <TextField
                    label="Source name"
                    variant="outlined"
                    color="secondary"
                    focused
                    disabled={book}
                    onChange={({target: {value}}) => onItemChange('source', value)}
                    inputProps={{inputMode: "text"}}
                    sx={{p: 0, m: 0, width: {width: "100%", sm: 'auto'}}} value={data.source}/>
                <TextField
                    label="Source product Id"
                    variant="outlined"
                    color="secondary"
                    disabled={book}
                    focused
                    onChange={({target: {value}}) => onItemChange('sourceId', value)}
                    inputProps={{inputMode: "text"}}
                    sx={{p: 0, m: 0, width: {width: "100%", sm: 'auto'}}} value={data.sourceId}/>
            </Box>}

            <Box sx={{my: 2, display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center'}}>
                {/*<TextField*/}
                {/*    label="Min quantity"*/}
                {/*    variant="outlined"*/}
                {/*    color="secondary"*/}
                {/*    focused*/}
                {/*    onChange={({target: {value}}) => onItemChange('minQuantity', +value.replace(/[,]/g, '.').replace(/[^0-9.]/g, ''))}*/}
                {/*    inputProps={{inputMode: "decimal", pattern: "[-+]?[0-9]*[.,]?[0-9]+", type: "tel"}}*/}
                {/*    sx={{p: 0, m: 0, width: {width: "100%", sm: 'auto'}}} value={data.minQuantity} />*/}
                <NumTextField
                    label="Quantity"
                    variant="outlined"
                    color="secondary"
                    onChange={value => onItemChange('stock', value)}
                    sx={{ p: 0, m: 0, width: { width: "100%", sm: 'auto' } }}
                    value={data.stock} />
                <NumTextField
                    label="Price"
                    variant="outlined"
                    color="secondary"
                    onChange={value => onItemChange('price', value)}
                    sx={{ p: 0, m: 0, width: { width: "100%", sm: 'auto' } }}
                    value={data.price} />

                {drawSource &&
                    <NumTextField
                        label="Estimated time of Arrival"
                        variant="outlined"
                        color="secondary"
                        onChange={value => onItemChange('estimatedTimeOfArrival', value)}
                        sx={{ p: 0, m: 0, width: { width: "100%", sm: 'auto' } }}
                        value={data.estimatedTimeOfArrival} />}
            </Box>
        </Box>

    )
}