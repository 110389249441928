import {REQUEST_STATUS, useRequest} from "../../hooks/request";
import React, {useEffect, useState} from "react";
import {Box, CircularProgress} from "@mui/material";
import {useHistory} from "react-router-dom";
import DistributorList from './distributorList.js';

export default function DistributorListController({search}){
    const {loading, response} = useRequest('/distributor/list');
    const [distris, setDistris] = useState([]);
    const history = useHistory()

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            if (search && search !== '') {
                setDistris(response.data.filter(el => el.name?.includes(search) || el.email?.includes(search)))
            } else {
                setDistris(response.data)
            }
        }
    }, [response, search])

    return <div>
        {loading ?
            <Box sx={{padding: 5, display: 'flex', justifyContent: 'center'}}>
                <CircularProgress size={50} />
            </Box>
            :
            <DistributorList distributors={distris} onSelect={(id) => history.push(`/distributor/${id}`)}/>
        }
    </div>

}