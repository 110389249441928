import React, { useState, useEffect } from 'react'
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Close, Done, HourglassBottom } from "@mui/icons-material";
import moment from "moment";
import { Row } from "./row";
import { formatRow } from "../../utils/book.utils";
import { List, WindowScroller, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once

export default React.memo(function Book({ book, showProduct = false, showState = false, onClick, showNote = false }) {
    const theme = useTheme()
    const [myBook, setMyBook] = useState([]);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        if (book) {
            let tmp = book.filter(el => el?.user?.name && el.user.name !== 'Yukatel').map(formatRow);
            setMyBook(tmp);
        }
    }, [book])

    const rowRenderer = ({ key, index, style, parent, isScrolling }) => {
        return <Box key={`RenderRow_${index}`} style={style}>
            <Row onClick={onClick} row={myBook[index]} color={theme.palette.success.main} showNote={showNote} withState={true} showProduct={showProduct} showState={showState} />
        </Box>
    }

    if (book.length > 50) {
        return <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
                <AutoSizer disableHeight={true}>
                    {({ width }) => (
                        <List
                            autoHeight
                            height={height}
                            onScroll={onChildScroll}
                            isScrolling={isScrolling}
                            rowCount={myBook.length}
                            rowHeight={isSmall ? 57 : 65}
                            rowRenderer={rowRenderer}
                            scrollTop={scrollTop}
                            width={width}
                            overscanRowCount={10}
                            scrollToAlignment={'start'}
                        />)}
                </AutoSizer>
            )}
        </WindowScroller>
    }

    return (
        <Box>
            {myBook.map((row, index) => {
                return (
                    <Box key={`RenderRow_${index}`}>
                        <Row showNote={showNote} onClick={onClick} row={row} color={theme.palette.success.main} withState={true} showProduct={showProduct} showState={showState} />
                    </Box>)
            })}
        </Box>
    )
})