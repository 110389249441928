import React, {useEffect} from 'react'
import {REQUEST_METHOD, REQUEST_STATUS, useRequest} from "../../hooks/request";
import {Badge} from "@mui/material";

export default function PendingBidBadge({children}) {
    const {response, refetch} = useRequest('/buy/count', REQUEST_METHOD.GET, {states: ['OPEN']}) //TODO #138 /buy as been deleted

    useEffect(() => {
        const interval = setInterval(() => {
            if (!response || response.status === REQUEST_STATUS.SUCCESS) {
                //refetch()
            }
        }, 10000)

        return () => clearImmediate(interval)
    }, [])

    return (
        <Badge showZero={false} color="primary" badgeContent={response && response.status === REQUEST_STATUS.SUCCESS && response.data ? response.data : 0}>
            {children}
        </Badge>
    )
}