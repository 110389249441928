import React from 'react'
import {Box, Button, Paper, Typography} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {useRequest} from "../hooks/request";
import moment from 'moment';
import {useHistory} from "react-router-dom";

function ProfitableUnofferedProducts({data}) {
    const history = useHistory()

    return <Paper elevation={3} sx={{minHeight: '150px', flexBasis: {md: '50%', xs: '100%'}, maxWidth: {md: '45%', xs: '100%'}, borderRadius: 2, p: 2, display: 'flex', flexDirection: 'row'}}>
        <Box sx={{justifyContent: 'space-between', display: 'flex', flexDirection: 'column', flex: 5}}>
            <Typography typography="subtitle">Profitable without offers</Typography>
            <Typography typography="h4">{data.value}</Typography>
            <Typography typography="span">Last crawled: {moment(data.lastCrawl).format("DD/MM hh:mm")}</Typography>
        </Box>
        <Box sx={{flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Button variant='outlined' endIcon={<SendIcon />} onClick={() => history.push('/product?orderBy=deltaBenef')}>Go</Button>
        </Box>

    </Paper>
}

function TotalProducts({data}) {
    const history = useHistory()

    return <Paper elevation={3} sx={{minHeight: '150px', flexBasis: {md: '50%', xs: '100%'}, maxWidth: {md: '45%', xs: '100%'}, borderRadius: 2, p: 2, display: 'flex', flexDirection: 'row'}}>
        <Box sx={{justifyContent: 'space-between', display: 'flex', flexDirection: 'column', flex: 5}}>
            <Typography typography="subtitle">Available products</Typography>
            <Typography typography="h4">{data.value}</Typography>
            <Typography typography="span">Last crawled: {moment(data.lastCrawl).format("DD/MM hh:mm")}</Typography>
        </Box>
        <Box sx={{flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Button variant='outlined' endIcon={<SendIcon />} onClick={() => history.push('/product')}>Go</Button>
        </Box>

    </Paper>
}

function PendingWTB({data}) {
    const history = useHistory()

    return <Paper elevation={3} sx={{minHeight: '150px', flexBasis: {md: '50%', xs: '100%'}, maxWidth: {md: '45%', xs: '100%'}, borderRadius: 2, p: 2, display: 'flex', flexDirection: 'row'}}>
        <Box sx={{justifyContent: 'space-between', display: 'flex', flexDirection: 'column', flex: 5}}>
            <Typography typography="subtitle">Pending WTBs</Typography>
            <Typography typography="h4">{data.value}</Typography>
            <Typography typography="span"/>
        </Box>
        <Box sx={{flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Button variant='outlined' endIcon={<SendIcon />} onClick={() => history.push('/book', {type: 'WTB', state: 'OPEN'})}>Go</Button>
        </Box>

    </Paper>
}

function PendingBID({data}) {
    const history = useHistory()

    return <Paper elevation={3} sx={{minHeight: '150px', flexBasis: {md: '50%', xs: '100%'}, maxWidth: {md: '45%', xs: '100%'}, borderRadius: 2, p: 2, display: 'flex', flexDirection: 'row'}}>
        <Box sx={{justifyContent: 'space-between', display: 'flex', flexDirection: 'column', flex: 5}}>
            <Typography typography="subtitle">Pending BIDs</Typography>
            <Typography typography="h4">{data.value}</Typography>
            <Typography typography="span"/>
        </Box>
        <Box sx={{flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Button variant='outlined' endIcon={<SendIcon />} onClick={() => history.push('/book', {type: 'BID', state: 'OPEN'})}>Go</Button>
        </Box>

    </Paper>
}

function TotalASK({data}) {
    const history = useHistory()

    return <Paper elevation={3} sx={{minHeight: '150px', flexBasis: {md: '50%', xs: '100%'}, maxWidth: {md: '45%', xs: '100%'}, borderRadius: 2, p: 2, display: 'flex', flexDirection: 'row'}}>
        <Box sx={{justifyContent: 'space-between', display: 'flex', flexDirection: 'column', flex: 5}}>
            <Typography typography="subtitle">Clients Offers available</Typography>
            <Typography typography="h4">{data.value}</Typography>
            <Typography typography="span"/>
        </Box>
        <Box sx={{flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Button variant='outlined' endIcon={<SendIcon />} onClick={() => history.push('/book', {type: 'ASK', state: 'OPEN'})}>Go</Button>
        </Box>

    </Paper>
}


export default function BoardPage() {
    const {response} = useRequest(`/board`)

    return (
        <Box sx={{p: 5, display: 'flex', flexFlow: 'row wrap'}}>

            {
                response && response.data && <Box sx={{flex: {md: 1, xs: "unset"}, display: 'flex', flexFlow: 'row wrap', gap: 3, justifyContent: "space-around"}}>
                    <PendingWTB data={response.data.wtbNeedAction}/>
                    <PendingBID data={response.data.bidPending}/>
                    <TotalASK data={response.data.totalSellOffer}/>
                    <ProfitableUnofferedProducts data={response.data.profitable}/>
                </Box>
            }



            <Box sx={{flex: 1}}>

            </Box>
        </Box>
    )
}