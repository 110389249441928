import React, {useState} from 'react'

export const Context = React.createContext(null)

export default function SettingsContext({children}) {
    const [marketFilters, setMarketFilters] = useState(JSON.parse(localStorage.getItem('marketFilters')) || {types: [], orderBy: 'alphabet', category: 'ALL'});
    const [onSearch, setOnSearch] = useState(false);
    

    const handleSetFilters = (filters) => {
        localStorage.setItem('marketFilters', JSON.stringify(filters));
        setMarketFilters(filters)
    }

    return (
        <Context.Provider value={{marketFilters, setMarketFilters: handleSetFilters, onSearch: onSearch, setOnSearch: setOnSearch}}>
            {children}
        </Context.Provider>
    )
}

export const useSettingsContext = () => (React.useContext(Context))