import React, {useState} from 'react'
import {Button, Container, Typography} from "@mui/material";
import TargetPriceMultiCreateModal from "../components/targetPrice/multiCreate.dialog";
import {Box} from "@mui/system";
import TargetPricesController from "../components/targetPrice/targetPrices.controller";
import {useModalsContext} from "../contexts/modals.context";

export default function TargetPricePage () {
	const {setOpenTP} = useModalsContext()

	return <Container>
		<Typography sx={{textAlign: 'left', my: 2, fontWeight: 'bold'}} variant="h4">Active Target Prices</Typography>
		<Box sx={{display: 'flex', justifyContent: 'end', marginTop: theme => theme.spacing(2)}}>
			<Button variant="outlined" sx={{mr: 1}} onClick={() => setOpenTP({open: true})}>Create Target Price</Button>
		</Box>
		<TargetPricesController/>
	</Container>
}