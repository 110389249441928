import React from 'react'
import {Button, Container, Typography} from "@mui/material";
import WatchListController from '../components/watchlist/watchlist.controller';

export default function WatchListPage () {

	return <Container>
		<Typography sx={{textAlign: 'left', my: 2, fontWeight: 'bold'}} variant="h4">RFTs</Typography>
		<WatchListController/>
	</Container>
}