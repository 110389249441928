import React, {useState} from 'react'
import {Box, Container, TextField, Typography} from "@mui/material";
import ClientsListController from "../components/clients/clientsList.controller";
import AddClient from "../components/clients/addClient";

export default function ClientsPage() {
    const [search, setSearch] = useState(undefined)
    return (
        <Container>
            <Typography sx={{textAlign: 'left', my: 2, fontWeight: 'bold'}} variant="h4">Buyers</Typography>
            <AddClient/>
            <Box sx={{mt: 2, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <TextField label="User" onChange={(val) => setSearch(val.target.value)}/>
            </Box>
            <ClientsListController search={search}/>
        </Container>
    )
}