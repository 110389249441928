import React, { useEffect, useState } from "react";
import { Typography, Box, Paper, Chip } from "@mui/material";
import UserSearch from "../clients/user.search.js";
import ProductSearch from "../product/product.search";

export default React.memo(function BookFilters({
  onFiltersChange,
  defaultFilters,
  fixedUserId,
}) {
  const [types, setTypes] = useState(
    defaultFilters?.monotypes
      ? defaultFilters.monotypes
      : ["BID", "WTS", "WTB", "ASK"]
  );
  const [states, setStates] = useState(
    defaultFilters?.states ? defaultFilters.states : ["OPEN"]
  );
  const [statesextra, setStatesextra] = useState(
    defaultFilters?.statesextra ? defaultFilters.statesextra : []
  );
  const [productId, setProductId] = useState(
    defaultFilters?.productId ? defaultFilters.productId : undefined
  );
  const [userId, setUserId] = useState(undefined);
  const [onlyManual, setOnlyManual] = useState(false);

  useEffect(() => {
    onFiltersChange({
      ...defaultFilters,
      user: userId,
      productId,
      states,
      monotypes: types,
      statesextra,
      onlyManual,
    });
  }, [userId, productId, states, types, statesextra, onlyManual]);

  return (
    <Paper element={2} sx={{ p: 2 }}>
      <Box>
        <Typography variant="subtitle1">Type</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Chip
            onClick={() =>
              types.find((el) => el === "WTS")
                ? setTypes(types.filter((tt) => tt !== "WTS"))
                : setTypes([...types, "WTS"])
            }
            color={types.find((el) => el === "WTS") ? "warning" : "default"}
            label="WTS"
          />
          <Chip
            onClick={() =>
              types.find((el) => el === "BID")
                ? setTypes(types.filter((tt) => tt !== "BID"))
                : setTypes([...types, "BID"])
            }
            color={types.find((el) => el === "BID") ? "error" : "default"}
            label="BID"
          />
          <Chip
            onClick={() =>
              types.find((el) => el === "ASK")
                ? setTypes(types.filter((tt) => tt !== "ASK"))
                : setTypes([...types, "ASK"])
            }
            color={types.find((el) => el === "ASK") ? "success" : "default"}
            label="ASK"
          />
          <Chip
            onClick={() =>
              types.find((el) => el === "WTB")
                ? setTypes(types.filter((tt) => tt !== "WTB"))
                : setTypes([...types, "WTB"])
            }
            color={types.find((el) => el === "WTB") ? "primary" : "default"}
            label="WTB"
          />
        </Box>
        <Typography variant="subtitle1">State</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Chip
              onClick={() =>
                states.find((el) => el === "OPEN")
                  ? setStates(states.filter((tt) => tt !== "OPEN"))
                  : setStates([...states, "OPEN"])
              }
              color={states.find((el) => el === "OPEN") ? "warning" : "default"}
              label="Open"
            />
            <Chip
              onClick={() =>
                states.find((el) => el === "CLOSE")
                  ? setStates(states.filter((tt) => tt !== "CLOSE"))
                  : setStates([...states, "CLOSE"])
              }
              color={states.find((el) => el === "CLOSE") ? "error" : "default"}
              label="Closed"
            />
          </Box>
          <Chip
            onClick={() => setOnlyManual(!onlyManual)}
            color={onlyManual ? "success" : "default"}
            variant="outlined"
            label="Manual only"
          />
        </Box>

        {states.find((el) => el === "CLOSE") && (
          <>
            <Typography variant="subtitle1">Extra State</Typography>

            <Box sx={{ display: "flex", gap: 1 }}>
              <Chip
                onClick={() => setStatesextra([])}
                color={statesextra.length < 1 ? "primary" : "default"}
                label="-"
              />
              <Chip
                onClick={() =>
                  statesextra.find((el) => el === "ACCEPTED")
                    ? setStatesextra(
                        statesextra.filter((tt) => tt !== "ACCEPTED")
                      )
                    : setStatesextra([...statesextra, "ACCEPTED"])
                }
                color={
                  statesextra.find((el) => el === "ACCEPTED")
                    ? "success"
                    : "default"
                }
                label="Accepted"
              />
              <Chip
                onClick={() =>
                  statesextra.find((el) => el === "CANCELED")
                    ? setStatesextra(
                        statesextra.filter((tt) => tt !== "CANCELED")
                      )
                    : setStatesextra([...statesextra, "CANCELED"])
                }
                color={
                  statesextra.find((el) => el === "CANCELED")
                    ? "error"
                    : "default"
                }
                label="Canceled"
              />
              <Chip
                onClick={() =>
                  statesextra.find((el) => el === "REJECTED")
                    ? setStatesextra(
                        statesextra.filter((tt) => tt !== "REJECTED")
                      )
                    : setStatesextra([...statesextra, "REJECTED"])
                }
                color={
                  statesextra.find((el) => el === "REJECTED")
                    ? "warning"
                    : "default"
                }
                label="Rejected"
              />
            </Box>
          </>
        )}
        {!fixedUserId && (
          <Box sx={{ display: "flex", flex: 1, gap: 1, mt: 2, width: "200px" }}>
            <UserSearch
              onSelect={(user) => setUserId(user ? user.id : undefined)}
              type={["CUSTOMER", "INTERNAL", "DISTRIBUTOR", "RESELLER"]}
            />
          </Box>
        )}
        <Box sx={{ display: "flex", flex: 1, gap: 1, mt: 2 }}>
          <ProductSearch
            defaultSelected={productId}
            onSelect={(product) =>
              setProductId(product ? product.id : undefined)
            }
          />
        </Box>

        {/*{productId && <Box sx={{ mt: 1 }}>*/}
        {/*	<Chip label={`Product ${productId}`} onDelete={() => setProductId(undefined)}>*/}
        {/*		{productId}*/}
        {/*	</Chip>*/}
        {/*</Box>}*/}
      </Box>
    </Paper>
  );
});
