import React, {useEffect} from 'react'
import {REQUEST_METHOD, useLazyRequest} from "../../../hooks/request";
import {Box} from "@mui/material";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";

export default function AskActions({row, onSubmit}) {
    const {response, loading, request} = useLazyRequest(`/book/ask/${row.id}`, REQUEST_METHOD.POST)
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if (response && response.status !== 'error') {
            enqueueSnackbar('Ask updated', {variant: "success"})
            onSubmit(response.data)
        }
    }, [response])

    const onClose = () => {
        request()
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Box/>
            <LoadingButton loading={loading} onClick={onClose} disabled={row.state === 'CLOSE'}>Close</LoadingButton>
        </Box>
    )
}