import React, {useState} from 'react'
import {Box, Button, Container} from '@mui/material';
import UnmappedPriceTrendSolverModal from "../modals/unmappedPriceTrendSolver.modal";
import ProductsController from "../components/product/productsController";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import TargetPriceMultiCreateModal from "../components/targetPrice/multiCreate.dialog";
import MultiCreateDialog from "../components/book/dialog/multiCreate.dialog";
import {useModalsContext} from "../contexts/modals.context";
import ActionsBar from "../components/actionsBar";

export default function ProductsPage() {
    const history = useHistory()
    const [mapProduct, setMapProduct] = useState(null)
    const {url} = useRouteMatch();
    const {pathname} = useLocation()
    const {setOpenRow, setOpenTP} = useModalsContext()

    // const [createOffer, setCreateOffer] = useState(null)
    // const [createBuy, setCreateBuy] = useState(false)
    // const [createCustomerBuy, setCreateCustomerBuy] = useState(false)
    // const [selectedProduct, setSelectedProduct] = useState(false);

    const goToDetails = React.useCallback(
        () => (id) => {
            history.push(`product/${id}`)
            //navigate({to: "details", props: {productId: id}})
        }, [])

    const goToEdit = React.useCallback(
        (navigate) => (id) => {
            navigate({to: "editRow", props: {rowId: id}})
        }, [])

    return (
        <Box display={pathname === url ? 'block' : 'none'}>

            <Container>
                <UnmappedPriceTrendSolverModal product={mapProduct} onClose={() => setMapProduct(null)}/>

                {/* <Box sx={{display: 'flex', justifyContent: 'end', mt: {lg: 1}, flexWrap: 'wrap'}}> */}
                    {/* <ActionsBar/> */}
                    {/*<Button variant="contained" sx={{mr: 1}} onClick={() => setOpenRow('wts')} color={"warning"}>WTS²</Button>*/}
                    {/*<Button variant="contained" sx={{mr: 1}} onClick={() => setOpenTP({open: true})}>Ask Target Price</Button>*/}
                    {/* <Button sx={{ml: 2}} variant="contained" onClick={() => history.push('/product/create')}>Add product</Button> */}
                {/* </Box> */}

                <ProductsController
                    onSelectProduct={goToDetails()}
                    onMapUnmappedProduct={(pdt) => setMapProduct(pdt)}
                    onCreateBid={(productId) => history.push(`/product/${productId}/book/edit-row/bid`)}
                    onCustomWts={(productId) => history.push(`/product/${productId}/book/edit-row/wts`)}
                    onCreateWtb={(productId) => history.push(`/product/${productId}/book/edit-row/wtb`)}
                    onCreatAsk={(productId) => history.push(`/product/${productId}/book/edit-row/ask`)}
                />
            </Container>
        </Box>
    )
}