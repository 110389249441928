import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import moment from "moment";
import { getTypeColor } from "../../utils/book.utils";
import RowStateIcon from "./row/rowStateIcon";

export const Row = ({ row, color, withState = false, showProduct = false, showState = false, onClick, showNote = false }) => {
	const theme = useTheme()

	if (!row) return <Box />

	const renderPrice = (row) => {
		return (
			<Box sx={{ flex: 1, gap: 1, display: 'flex', flexDirection: "row", justifyContent: 'end', alignItems: 'center' }}>
				<Typography color={color}>{row ? row.price.toFixed(2) : '-'}</Typography>
				{row && withState && showState && <RowStateIcon row={row} />}
			</Box>
		)
	}

	const renderName = (row) => {
		let name = row && row.user ? row.user.name : "-";
		if (row && row.from === "INTERNAL") {
			if (row.type === 'ASK') {
				if (row.scope && row.scope.length) {
					name = ''
					row.scope.forEach((scope) => {
						if (name === '') {
							name = scope.name
						} else {
							name = `${name}, ${scope.name}`
						}
					})
				} else if (row.includedUsers?.length === 1) {
					name = `to ${row.includedUsers[0].name}`
				} else if (row.includedUsers?.length === 2) {
					name = `to ${row.includedUsers[0].name} & ${row.includedUsers[1].name}`
				}
				else if (row.includedUserIds && row.includedUserIds.length) {
					name = `Exclusive to ${row.includedUserIds.length} cs.`
				} else {
					name = "All"
					if (row.excludedUserIds && row.excludedUserIds.length) {
						name += `, except ${row.excludedUserIds.length} cs.`
					}
				}
			} else if (row.type === 'BID') {
				if (row.attachedTo?.length && row.attachedTo[0].user) {
					name = `to ${row.attachedTo[0].user.name}`
				} else if (row.meta?.sellerId && row.includedUsers?.length === 1) {
					name = `to ${row.includedUsers[0].name}`
				} else {
					name = 'MONO'
				}
			} else {
				name = "MONO"
			}
		}
		// if (row.type === 'WTS' && row.meta?.source) name = row.user.name
		return (
			<Box sx={{ flex: 3, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
				<Typography sx={{ textAlign: 'left', color: row && row.from === "INTERNAL" ? theme.palette.secondary.main : theme.palette.text.primary }} >{name}</Typography>
			</Box>
		)
	}

	return (
		<Box onClick={() => onClick && onClick(row.id)}>
			<Box
				sx={{
					borderBottom: `solid 1px`, borderBottomColor: 'divider', pt: 1, pb: 1, bgcolor: "inherit", '&:hover': {
						backgroundColor: (onClick && row?.state === 'OPEN') ? 'neutral.main' : 'inherit',
						cursor: (onClick && row?.state === 'OPEN') ? 'pointer' : 'inherit'
					}
				}}>
				{showProduct && <Box sx={{ flex: 1, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}><Typography sx={{}}>{row?.product?.name}</Typography></Box>}
				<Box sx={{
					display: 'flex', flexDirection: "row", justifyContent: 'space-between',
					alignItems: 'center', gap: 1
				}}>
					{showProduct && <Box sx={{ flex: 1 }}>
						<Typography sx={{ textAlign: 'left' }}>{moment(row.createdAt).format('DD/MM')}</Typography>
					</Box>}
					{!showProduct && <Box sx={{ flex: 1 }}>
						<Typography sx={{ textAlign: 'left', fontSize: '13px' }}>{moment().diff(row.updatedAt, 'days') > 0 ? ` ${moment().diff(row.updatedAt, 'days')}D` : ''}</Typography>
					</Box>}
					<Box sx={{ flex: 1 }}>
						<Typography sx={{ textAlign: 'left', color: getTypeColor(theme, row.type) }} >{row.type}</Typography>
					</Box>
					{renderName(row)}
					<Box sx={{ flex: 1 }}>
						<Typography>{row.stock || '-'}</Typography>
					</Box>
					{renderPrice(row)}
				</Box>
			</Box>
			{showNote && row.meta?.note && <Box sx={{ paddingX: 2, paddingY: 1 }}>
				<Typography sx={{
					display: '-webkit-box',
					overflow: 'hidden',
					WebkitBoxOrient: 'vertical',
					WebkitLineClamp: 1,
				}}
					variant="body1">{row.meta?.note}</Typography>
			</Box>}
		</Box>

	)
}