import React from 'react'
import {useHistory} from "react-router-dom";
import {Button} from "@mui/material";

export default function GoBack({sx}) {
    const history = useHistory()

    if (!history || !history.goBack) return null
    return (
        <Button sx={{...sx, marginBottom: 1, marginTop: 1}} onClick={history.goBack}>Back</Button>
    )
}