import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {Box, Button, Container, TextField, Typography} from "@mui/material";
import CreatRowForm from "../components/book/createRow/creatRow.form";
import GoBack from "../components/goBack";
import UsersSelector from "../components/clients/users.selector";
import moment from "moment";
import copy from "copy-to-clipboard";
import {REQUEST_METHOD, useLazyRequest, useRequest} from "../hooks/request";
import Loading from "../components/loading";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {useRefetchContext} from "../contexts/refetch.context";

const getStr = (row, product) => {
    if (row.type === 'BID')    return `BID\n${row.stock} x ${product?.name} at ${(+row.price)?.toFixed(2)} €` //TODO: SKU
    return `${row.stock} x ${product?.name} at ${(+row.price)?.toFixed(2)} €${row.meta?.estimatedTimeOfArrival ? `, ETA ${row.meta.estimatedTimeOfArrival} days` : ','}`
}

function ClipBoard({row, product}) {
    const [str, setStr] = useState(getStr(row, product))

    useEffect(() => {
        setStr(getStr(row, product))
    }, [row, product])

    return (
        <Box sx={{width: 1, height: 1}}>
            <TextField
                multiline
                rows={10}
                fullWidth
                value={str}
                onChange={({target: {value}}) => setStr(value)} />
            <Button sx={{mt: 1}} onClick={() => copy(str)} fullWidth variant='contained' color='secondary'>Copy</Button>
        </Box>
    )
}

export default function CreateRowPage({}) {
    const {rowType, productId} = useParams()
    const {response, loading} = useRequest(`/product/details/${productId}`)
    const {response: putResp, loading: putLoading, request} = useLazyRequest('/book', REQUEST_METHOD.PUT)
    const history = useHistory()
    const {enqueueSnackbar} = useSnackbar()
    const {setToRefetch} = useRefetchContext()

    const [row, setRow] = useState({
        productId,
        type: rowType.toUpperCase(),
        price: 0,
        stock: 0,
        meta: {estimatedTimeOfArrival: 0},
        expireAt: null,
        userId: null,
        attachedTo: null,
        stateExtra: rowType.toUpperCase() === 'BID' || rowType.toUpperCase() === 'WTB' ? undefined : undefined,
        chainHelperWtsSelected: null,
        chainHelperCreateWts: null
    })

    const [step, setStep] = useState(0)

    useEffect(() => {
        if (putResp && putResp.status !== 'error') {
            enqueueSnackbar(`${rowType.toUpperCase()} created`, {variant: "success"})
            setToRefetch(['/product', '/book'])
            history.goBack() //TODO push
        }
    }, [putResp])

    const onNextStep = () => {
        if (step === 0) {
            if (step === 0 && (rowType.toUpperCase() === 'ASK')) setStep(step + 1)
            else if (step === 0 && (rowType.toUpperCase() === 'BID')) setStep(step + 2)
            else request({}, {...row, stock: +row.stock, price: +row.price, expireAt: row.expireAt ? moment().add(row.expireAt, 'd') : undefined})
        }

        if (step === 1 && (rowType.toUpperCase() === 'ASK')) {
            setStep(step + 1)
        }

        if (step === 2 && (rowType.toUpperCase() === 'ASK'|| rowType.toUpperCase() === 'BID')) {
            request({}, {...row, stock: +row.stock, price: +row.price,expireAt: row.expireAt ? moment().add(row.expireAt, 'd') : undefined})
        }
    }

    const onUsersSelectorChange = (users) => {
        setRow({
            ...row,
            scopeIds: (users && users.tags && users.tags.length) ? users.tags : undefined,
            includedUserIds: (users && users.include && users.include.length) ? users.include : undefined,
            excludedUserIds: (users && users.exclude && users.exclude.length) ? users.exclude : undefined,
        })
    }

    const isChainHelperDisable = () => {
        return !row.chainHelperCreateWts || (!row.chainHelperCreateWts.price || !row.chainHelperCreateWts.stock || !row.chainHelperCreateWts.userId)
    }

    const isDisable = () => {
        if (step === 0) {
            return (!row.price && (row.type !== 'WTB' && row.type !== 'WTS')) || (!row.stock && (row.type !== 'WTB' && row.type !== 'WTS'))
                || (rowType.toUpperCase() === 'BID' && (!row.attachedTo && !row.chainHelperCreateWts?.userId))
                // || ((rowType.toUpperCase() !== 'WTS' && rowType.toUpperCase() !== 'WTB') && !row.attachedTo && !row.chainHelperWtsSelected && isChainHelperDisable())
                || ((rowType.toUpperCase() === 'WTB' || rowType.toUpperCase() === 'WTS') && !row.userId)
        }
        return false
    }

    if (loading || !response) return <Loading loading={loading} />
    if (response.status === 'error') return <Typography color='palette.error.main'>{response.message ? response.message : `Something goes wrong: ${response.message}`}</Typography>
    if (putResp?.status === 'error') return <Typography color='palette.error.main'>{putResp.message ? putResp.message : `Something goes wrong: ${putResp.message}`}</Typography>

    return (
        <Container>
            <GoBack/>

            {step === 0 && <CreatRowForm row={row} onChange={setRow} product={response.data} />}
            {step === 1 && (rowType.toUpperCase() === 'ASK') && <UsersSelector  onSelect={onUsersSelectorChange} />}
            {step === 2 && (rowType.toUpperCase() === 'ASK' || rowType.toUpperCase() === 'BID') && <ClipBoard row={row} product={response.data}/> }

            <Box sx={{marginTop: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Box/>
                <LoadingButton onClick={onNextStep} disabled={isDisable() || putLoading} variant='contained' loading={putLoading}>Next</LoadingButton>
            </Box>
        </Container>
    )
}