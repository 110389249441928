import React, {useState} from 'react'
import TargetPriceMultiCreateModal from "../components/targetPrice/multiCreate.dialog";
import MultiCreateDialog from "../components/book/dialog/multiCreate.dialog";

export const Context = React.createContext(null)

export default function ModalsContext({children}) {
    const [tp, setTp] = useState({open: false});
    const [row, setRow] = useState({open: false});

    return (
        <Context.Provider value={{setOpenRow: setRow, setOpenTP: setTp}}>
            <TargetPriceMultiCreateModal onClose={() => setTp({open: false})} data={tp} />
            <MultiCreateDialog onClose={() => setRow({open: false})} data={row} />
            {children}
        </Context.Provider>
    )
}

export const useModalsContext = () => (React.useContext(Context))