import React, {useEffect, useState} from 'react'
import {
    Box,
    Chip,
    Paper,
    TextField,
    Typography
} from "@mui/material";

export function RenderGroup ({group, deleteMode, onDelete}) {
  return (
      <Box>
          <Typography fontWeight={"bold"}>{group.name}:</Typography>
          {group.tags.map(tag => deleteMode ? <Chip
              key={tag.id}
              color="primary"
              label={tag.name}
              onDelete={() => onDelete(tag.id)}
              sx={{margin: (theme) => theme.spacing(1)}}
          /> : <Chip
              key={tag.id}
              color="primary"
              label={tag.name}
              sx={{margin: (theme) => theme.spacing(1)}}
          />)}
      </Box>
  )
}


export function GroupedTags({tags, deleteMode, onDelete}) {
  const grouped = []

  for (let tag of tags) {
      let found = grouped.find(elem => elem.id === tag.tagGroupId)
      if (found) {
          found.tags.push(tag)
      } else {
          grouped.push({
              ...tag.tagGroup,
              tags: [tag]
          })
      }
  }

  return (
      <React.Fragment>
          {grouped.map((elem) => <RenderGroup group={elem} key={elem.id} onDelete={onDelete} deleteMode={deleteMode}/> )}
      </React.Fragment>
  )
}