import React, {useEffect, useState} from 'react'
import {
    Backdrop,
    Box,
    Button,
    Chip,
    CircularProgress,
    Container,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest, useRequest} from "../hooks/request";
import {Check, Edit} from "@mui/icons-material";
import LinkTagToLead from "../components/leads/linkTagToLead";
import Loading from "../components/loading";
import {LoadingButton} from "@mui/lab";

export default function LeadPage() {
    const {id} = useParams()
    const history = useHistory()
    const {response, loading, refetch} = useRequest(`/lead/${id}`)
    const {request, loading: editLoading, response: editResponse} = useLazyRequest(`/lead/${id}`, REQUEST_METHOD.PUT)
    const {request: promote, loading: promoteLoading, response: promoteResponse} = useLazyRequest(`/lead/${id}/promote`, REQUEST_METHOD.POST)
    const [user, setUser] = useState(undefined);
    const [editMode, setEditMode] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState();

    useEffect(() => {
        if (promoteResponse && promoteResponse.status === REQUEST_STATUS.SUCCESS) {
            history.push(`/client/${promoteResponse.data.id}`)
        }
    }, [promoteResponse])

    useEffect(() => {
        if (response && response.data) {
            setUser(response.data);
            if (response.data.phoneNumber) setPhoneNumber(response.data.phoneNumber);
        }
    }, [response])

    useEffect(() => {
        if (editResponse) {
            refetch()
        }
    }, [editResponse])

    const handleEditMode = () => {
        if (editMode) {
            request({},  {phoneNumber})
            setEditMode(false);
        } else {
            setEditMode(true)
        }
    }

    if (!user) return <Loading />
    return (
        <Container sx={{py: 2}}>
            {history.goBack && <Button onClick={() => history.goBack()} sx={{m: 1}} variant='text' color='inherit'>Back</Button>}
            {loading &&  <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>}

            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography sx={{textAlign: 'left', my: 2, fontWeight: 'bold'}} variant="h4">{user.name}</Typography>

                <div>
                    <Button disabled={loading || editLoading} variant="outlined" startIcon={!editMode ? <Edit/> : <Check/>} onClick={() => handleEditMode()}>{editMode ? 'Save' : 'Edit'}</Button>
                </div>
            </Box>

            {user && <Paper sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1}}>
                <Typography sx={{textAlign: 'left'}}>Email: <b>{user.email}</b></Typography>
                <Typography sx={{textAlign: 'left'}}>Company: <b>{user.companyName}</b></Typography>
                <Typography sx={{textAlign: 'left'}}>Area: <b>{user.areaCode}</b></Typography>

                {editMode ? <TextField sx={{margin: 1}} id="phone-number" label="Phone Number" variant="outlined" value={phoneNumber} onChange={event => setPhoneNumber(event.target.value)} />
                    : <Typography sx={{textAlign: 'left'}}>Tel: <b>{user.phoneNumber} {!user.phoneNumber && '-'}</b></Typography>}

            </Paper>}

            <Paper sx={{p: 2, my: 2}}>
                {user.userTags && user.userTags.map(tag => <Chip
                        key={tag.id}
                        color="primary"
                        label={tag.name}
                        // onDelete={() => toDelete(tag)}
                        sx={{margin: (theme) => theme.spacing(1)}}
                    />
                )}
                <LinkTagToLead leadId={id} linkedTags={user.userTags}/>
            </Paper>

            <Box sx={{display: 'flex', justifyContent: 'end'}}>
                <LoadingButton loading={promoteLoading} onClick={() => promote()}>promote to buyer</LoadingButton>
            </Box>
        </Container>
    )
}