import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { REQUEST_METHOD, useLazyRequest } from "../../hooks/request";
import Book from "./book";
import { CircularProgress } from "@mui/material";

export default React.memo(function BookController({
  showProduct = false,
  filters,
  showState = false,
  onClick,
  showNote = false,
}) {
  const { response, loading, request } = useLazyRequest(
    `/book`,
    REQUEST_METHOD.GET
  );
  useEffect(() => {
    if (!loading) {
      request({
        types: (filters && filters.types) || [],
        monotypes: (filters && filters.monotypes) || [],
        states: (filters && filters.states) || ["OPEN"],
        statesextra: (filters && filters.statesextra) || [],
        from: (filters && filters.from) || undefined,
        user: (filters && filters.user) || undefined,
        productId: (filters && filters.productId) || undefined,
        take: (filters && filters.take) || 1000,
        onlyManual: (filters && filters.onlyManual) || undefined,
      });
    }
  }, [
    filters.monotypes,
    filters.states,
    filters.user,
    filters.productId,
    filters.statesextra,
    filters.onlyManual,
  ]);

  return (
    <Box>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            m: 5,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {response && !loading && response.data && (
        <Book
          onClick={onClick}
          book={response.data}
          showProduct={showProduct}
          showState={showState}
          showNote={showNote}
        />
      )}
    </Box>
  );
});
