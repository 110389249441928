import React, { useEffect, useState } from 'react'
import { AppBar, Box, Button, Drawer, IconButton, Toolbar, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AppMenu from "./router/appMenu";
import { Search, Menu, Language } from "@mui/icons-material";
import clsx from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';
import PendingBidBadge from "./components/buy/pendingBidBadge";
import { useHistory, useLocation } from 'react-router-dom';
import SettingsContext, { useSettingsContext } from './contexts/settings.context';

function useIsWidthUp(breakpoint) {
	const theme = useTheme();
	return useMediaQuery(theme.breakpoints.up(breakpoint));
}

const drawerWidth = 210;

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.up('sm')]: {
			display: "flex",
		},
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},

	container: {
		height: "100%",
		overflow: "scroll"
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	drawerPaper: {
		[theme.breakpoints.up('sm')]: {
			top: '64px',
		},
		width: drawerWidth,
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		[theme.breakpoints.up('sm')]: {
			marginLeft: -drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`
		},
		marginTop: '65px',
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
}))

function AppLayout({ children }) {
	const theme = useTheme()
	const classes = useStyles()
	const isWidthUp = useIsWidthUp("md");
	const [open, setOpen] = useState(isWidthUp ? true : false);
	const location = useLocation()
	const history = useHistory()
	const { setOnSearch, onSearch } = useSettingsContext()

	useEffect(() => {
		setOpen(isWidthUp ? true : false)
	}, [isWidthUp])

	return (
		<Box className={classes.root}>
			<AppBar position="fixed" style={{ backgroundColor: theme.palette.background.paper }}
				className={classes.appBar} sx={{ top: isWidthUp ? 'unset' : 'auto', bottom: isWidthUp ? 'unset' : 0 }} >
				<Toolbar className={classes.toolbar} sx={{ alignSelf: 'center', justifyContent: "space-between", width: "100%", maxWidth: isWidthUp ? '30%' : "100%" }}>
					<IconButton onClick={() => setOpen(!open)} size="large">
						<PendingBidBadge>
							<Menu />
						</PendingBidBadge>
					</IconButton>
					<IconButton onClick={() => history.push('/distributors')} color={location.pathname.search('/distributors') >= 0 ? "primary" : "default"} size="large">
						<Box sx={{ fontSize: "1.5rem" }}>
							S
						</Box>
					</IconButton>
					<IconButton onClick={() => history.push('/product')} color={location.pathname.search('/product') >= 0 ? "primary" : "default"} size="large">
						<Language />
					</IconButton>
					<IconButton onClick={() => history.push('/clients')} color={location.pathname.search('/clients') >= 0 ? "primary" : "default"} size="large">
						<Box sx={{ fontSize: "1.5rem" }}>
							B
						</Box>
					</IconButton>

					<IconButton onClick={() => setOnSearch(onSearch ? false : true)} size="large">
						<Search />
					</IconButton>
				</Toolbar>
			</AppBar>

			<nav className={classes.drawer}>
				<Drawer
					classes={{
						paper: classes.drawerPaper,
					}}
					variant={isWidthUp ? "persistent" : "temporary"} //temporary
					anchor="left"
					open={open}
					onClose={() => setOpen(false)}>
					<Box>
						<AppMenu />
					</Box>
				</Drawer>
			</nav>

			<main
				style={{ marginBottom: "4rem" }}
				className={clsx(classes.content, {
					[classes.contentShift]: open,
				})}
			>
				{children}
			</main>
		</Box>
	);
}
export default AppLayout