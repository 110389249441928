import React, {useEffect, useState} from 'react'
import {REQUEST_METHOD, useLazyRequest} from "../../../hooks/request";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {Box} from "@mui/material";
import RejectAction from "./rejectAction";

export default function BidActions({row, onSubmit}) {
    const {request, response, loading} = useLazyRequest(`/book/bid/${row.id}`, REQUEST_METHOD.POST)
    const {enqueueSnackbar} = useSnackbar()
    const [rejected, setRejected] = useState(false) //TODO here the row can already be in rejected state withe a proposed price/stock

    useEffect(() => {
        if (response && response.status !== 'error') {
            enqueueSnackbar('BID updated', {variant: 'success'})
            onSubmit(response.data)
        }
    }, [response])

    const onAccepted = () => {
        request(null, {stateExtra: 'ACCEPTED'})
    }

    const onCanceled = () => {
        request(null, {stateExtra: 'CANCELED'})
    }

    const onRejected = (meta) => {
        request({}, {stateExtra: 'REJECTED', meta})
    }

    const preRejected = () => {
        setRejected(true)
    }

    if (rejected) {
        return <RejectAction onSubmit={onRejected} setReject={setRejected} loading={loading} row={row}/>
    }
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Box />

            <Box>
                <LoadingButton onClick={onCanceled} loading={loading} disabled={row.state === 'CLOSE'}>cancel</LoadingButton>
                <LoadingButton onClick={preRejected} loading={loading} disabled={row.state === 'CLOSE'}>rejected</LoadingButton>
                <LoadingButton onClick={onAccepted} loading={loading} disabled={row.state === 'CLOSE'}>accepted</LoadingButton>
            </Box>
        </Box>
    )

}