import React from 'react'
import {formatRow} from "../../../utils/book.utils";
import {Close, Done, DoorFront, HourglassBottom, MeetingRoom} from "@mui/icons-material";
import {useTheme} from "@mui/material";

export default function RowStateIcon({row, iconsSize = 18}) {
    const _row = formatRow(row)

    const theme = useTheme()

    if (_row.type === 'WTS' || _row.type === 'ASK') {
        if (_row.state === 'OPEN') {
            return <MeetingRoom sx={{width: iconsSize, fill: theme.palette.text.disabled}}/>
        } else {
            return <DoorFront sx={{width: iconsSize, fill: theme.palette.text.disabled}}/>
        }
    }

    if (_row.type === 'BID' || _row.type === 'WTB') {
        if (row.state === 'OPEN') {
            if (row.stateExtra === 'ACCEPTED') {
                return <Done sx={{ width: iconsSize, fill: theme.palette.text.disabled }} />
            } else if (row.stateExtra === 'REJECTED') {
                return <Close sx={{ width: iconsSize, fill: theme.palette.text.disabled }} />
            } else {
                return <HourglassBottom sx={{ width: iconsSize, fill: theme.palette.text.disabled }} />
            }
        } else {
            if (row.stateExtra === 'ACCEPTED') {
                return <Done sx={{ width: iconsSize, fill: theme.palette.success.main }} />
            } else if (row.stateExtra === 'CANCELED') {
                return <Close sx={{ width: iconsSize, fill: theme.palette.error.main }} />
            } else if (row.stateExtra === 'REJECTED') {
                return <Close sx={{ width: iconsSize, fill: theme.palette.warning.main }} />
            }
        }
    }
    return null
}