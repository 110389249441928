import React, { useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Box, Container, Divider } from "@mui/material";
import BookFilters from "./bookFilters";
import BookController from "./book.controller";

export default function BookWithFilters({ userId = undefined }) {
  const [filters, setFilters] = useState(undefined);
  const location = useLocation();

  const goToEdit = React.useCallback(
    () => (id) => {
      history.push(`/book/${id}`);
    },
    []
  );

  const history = useHistory();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <Box sx={{ display: pathname === url ? "block" : "none" }}>
      <BookFilters
        fixedUserId={userId}
        onFiltersChange={setFilters}
        defaultFilters={{
          user: userId,
          productId: location?.state?.productId || undefined,
          states: location?.state?.state
            ? location?.state?.state
            : ["OPEN", "CLOSE"],
          monotypes: location?.state?.type ? [location.state.type] : undefined,
          statesextra: location?.state?.statesextra
            ? [location.state.statesextra]
            : undefined,
        }}
      />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { md: "1fr 1fr", xs: "auto" },
          gridTemplateRows: { md: "auto", xs: "auto auto" },
          gap: 1,
        }}
      >
        {filters &&
          filters.monotypes.filter((el) => el === "WTS" || el === "BID")
            .length > 0 && (
            <BookController
              onClick={goToEdit()}
              showProduct
              filters={{
                productId: location?.state?.productId || undefined,
                ...filters,
                monotypes: filters.monotypes.filter(
                  (el) => el === "WTS" || el === "BID"
                ),
                user: userId ? userId : filters.user,
                take: 1000,
                onlyManual: filters.onlyManual,
              }}
            />
          )}
        <Divider sx={{ display: { md: "none", xs: "grid" } }} />
        {filters &&
          filters.monotypes.filter((el) => el === "WTB" || el === "ASK")
            .length > 0 && (
            <BookController
              onClick={goToEdit()}
              showProduct
              filters={{
                productId: location?.state?.productId || undefined,
                ...filters,
                monotypes: filters.monotypes.filter(
                  (el) => el === "WTB" || el === "ASK"
                ),
                user: userId ? userId : filters.user,
                take: 1000,
                onlyManual: filters.onlyManual,
              }}
            />
          )}
      </Box>
    </Box>
  );
}
