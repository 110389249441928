import React, {useEffect, useState} from 'react'
import {REQUEST_STATUS, useRequest} from "../hooks/request";
import Loading from "../components/loading";
import {Container, Grid} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import DistributorCrawlerCard from "../components/distributor/distributorCrawlerCard";
import PriceCrawlerCard from "../components/price/priceCrawlerCard";

const useStyle = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
}))

export default function CrawlerPage() {
    const classes = useStyle()

    const {response: cData} = useRequest('/crawler-log')
    const {response: dData} = useRequest('/distributor/list')

    const [loading, setLoading] = useState(false)

    const [distributors, setDistributors] = useState(null)
    const [priceSources, setPriceSources] = useState(null)

    useEffect(() => {
        if (cData && cData.status === REQUEST_STATUS.SUCCESS && dData &&  dData.status === REQUEST_STATUS.SUCCESS) {
            setDistributors(null)
            let res = []
            for (let _distributor of dData.data) {
                if (Array.isArray(cData.data))
                    _distributor.log = cData.data.find(elem => elem.name === _distributor.name)
                res.push(_distributor)
            }
            setDistributors(res)
            setPriceSources([{name: 'Yukatel', isOnline: true, log: cData.data.find(elem => elem.name === "Yukatel")}, {name: "Amazon", isOnline: false, log: undefined}])
        }
    }, [cData, dData])

    return (
        <Container>
            <Loading loading={!distributors}>
                <div className={classes.root}>
                    <h2>Distributors</h2>
                    <Grid container spacing={3}>
                        {distributors ? distributors.map(distributor => (<DistributorCrawlerCard loading={loading} isLoading={setLoading} distributor={distributor} />)) : undefined}
                    </Grid>
                </div>
                <div className={classes.root}>
                    <h2>Prices</h2>
                    <Grid container spacing={3}>
                        {priceSources ? priceSources.map(priceSource => (<PriceCrawlerCard loading={loading} isLoading={setLoading} source={priceSource}/>)) : undefined}
                    </Grid>
                </div>
            </Loading>
        </Container>
    )
}