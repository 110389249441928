import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  Tab,
  Tabs,
  TextField,
  Typography,
  IconButton,
  Input,
} from "@mui/material";
import {
  REQUEST_METHOD,
  REQUEST_STATUS,
  useLazyRequest,
} from "../hooks/request";
import ProductMetrics from "../components/product/productMetrics";
import PriceTrendSummary from "../components/priceTrend/priceTrend.summary";
import ProductSummaryButtons from "../components/product/productSummaryButtons";
import BookController from "../components/book/book.controller";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import GoBack from "../components/goBack";
import { LoadingButton } from "@mui/lab";
import SelectProduct from "../components/selectProduct";
import SelectPriceTrend from "../components/selectPriceTrend";
import ClearIcon from "@mui/icons-material/Clear";
import NumTextField from "../components/NumTextField";
import StockTrendSummary from "../components/priceTrend/stockTrend.summary";
import SelectMarketTrend from "../components/selectMarketTrend";
import MarketTrendSummary from "../components/priceTrend/marketTrend.summary";
import {
  StarOutline,
  Star,
  BookmarkBorder,
  Bookmark,
  PlusOne,
  Add,
} from "@mui/icons-material";
import ProductSearch from "../components/product/product.search";
const TabsContent = React.memo(({ id, currentTab, bookResponse }) => {
  const history = useHistory();
  const [conf, setConf] = useState(20);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box>
        {id ? (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { md: "1fr 1fr", xs: "auto" },
              gridTemplateRows: { md: "auto", xs: "auto auto" },
              gap: 1,
            }}
          >
            <BookController
              showNote
              onClick={(id) => history.push(`/product/book/${id}`)}
              filters={{
                monotypes: ["WTS", "BID"],
                productId: id,
                take: 7,
                states: ["OPEN"],
              }}
              showState={true}
            />
            <Divider sx={{ display: { md: "none", xs: "grid" } }} />
            <BookController
              showNote
              onClick={(id) => history.push(`/product/book/${id}`)}
              filters={{
                monotypes: ["ASK", "WTB"],
                productId: id,
                take: 7,
                states: ["OPEN"],
              }}
              showState={true}
            />
          </Box>
        ) : undefined}
      </Box>

      <Box>
        <Card>
          <Box p={1}>
            <Typography sx={{ m: 1 }}>Market Price</Typography>
            <PriceTrendSummary productId={id} />
          </Box>
        </Card>
        <Card sx={{ mt: 2 }}>
          <Box p={1}>
            <Typography sx={{ m: 1 }}>Supply/Demand</Typography>
            <MarketTrendSummary productId={id} />
          </Box>
        </Card>
        <Card sx={{ mt: 2 }}>
          <Box p={1}>
            <Typography sx={{ m: 1 }}>Price History</Typography>
            <PriceTrendSummary productId={id} formula={-conf} />
          </Box>
        </Card>
        <Card sx={{ mt: 2 }}>
          <Box p={1}>
            <Typography sx={{ m: 1 }}>Stock History</Typography>
            <StockTrendSummary productId={id} />
          </Box>
        </Card>
        <Box sx={{ mt: 4, display: "flex", flexDirection: "column" }}>
          {open && (
            <NumTextField value={conf} onChange={(val) => setConf(val)} />
          )}
          <Button onClick={() => setOpen(!open)}>Config</Button>
        </Box>
      </Box>
    </>
  );
});

export default React.memo(function ProductPage({}) {
  const [product, setProduct] = useState(null);
  // const [editTag, setEditTag] = useState(null)
  const history = useHistory();
  const { id } = useParams();
  const [edit, setEdit] = useState(false);
  const { request: requestWatch, response: responseWatch } = useLazyRequest(
    "/product/add-watchlist",
    REQUEST_METHOD.POST
  );
  const { request: requestAddRFQ, response: responseAddRFQ } = useLazyRequest(
    "/rfq",
    REQUEST_METHOD.POST
  );
  const { request: requestDelRFQ, response: responseDelRFQ } = useLazyRequest(
    "/rfq/delete",
    REQUEST_METHOD.DELETE
  );

  const { request, response, loading } = useLazyRequest(
    `/product/details/${id}`,
    REQUEST_METHOD.GET
  );
  const {
    response: bookResponse,
    request: bookRequest,
    loading: bookLoading,
  } = useLazyRequest(`/book/product/${id}`);
  const {
    response: updateResponse,
    request: updateRequest,
    loading: updateLoading,
  } = useLazyRequest(`/product/update/${id}`, REQUEST_METHOD.POST);
  const {
    response: mergeResponse,
    request: mergeRequest,
    loading: mergeLoading,
  } = useLazyRequest(`/product/merge/${id}`, REQUEST_METHOD.POST);
  const [currentTab, setCurrentTab] = useState("book");
  const [merge, setMerge] = useState(undefined);
  const [mapped, setMapped] = useState([]);
  const [mappedAX, setMappedAX] = useState([]);
  const [mappedMarket, setMappedMarket] = useState([]);
  const [iframes, setIframes] = useState([]);
  const [links, setLinks] = useState([]);
  const [newFrame, setNewFrame] = useState(undefined);
  const [newLink, setNewLink] = useState(undefined);

  useEffect(() => {
    if (id && !isNaN(id)) {
      request();
      bookRequest();
    }
  }, [id]);

  useEffect(() => {
    request();
  }, [responseWatch, responseAddRFQ, responseDelRFQ]);

  const handleEditProduct = () => {
    if (edit) {
      updateRequest(
        {},
        {
          product: {
            name: product.name,
            mappedNames: mapped,
            axMappedNames: mappedAX,
            marketMapppedNames: mappedMarket,
            links: links,
            iframes: iframes,
          },
        }
      );
      setEdit(false);
    } else {
      setEdit(true);
    }
  };

  useEffect(() => {
    if (response && response.status === REQUEST_STATUS.SUCCESS) {
      setProduct(response.data);
      setMapped(response.data?.mappedNames);
      setMappedAX(response.data?.axMappedNames);
      setMappedMarket(response.data?.marketMapppedNames);
      setIframes(response.data?.iframes);
      setLinks(response.data?.links);
    }
  }, [response]);

  useEffect(() => {
    if (
      mergeResponse &&
      merge &&
      mergeResponse.status === REQUEST_STATUS.SUCCESS
    ) {
      history.push(`/product/${merge.id}`);
      setMerge(undefined);
      setEdit(false);
    }
  }, [mergeResponse]);

  const getInternalSourcesId = () => {
    const rows = product?.book?.filter((el) => el.from === "DISTRIBUTOR");
    if (rows?.length) {
      return (
        <Box>
          {rows.map((el) => (
            <Typography>
              {el.user?.name}: {el.meta?.internalSourceId || "-"}
            </Typography>
          ))}
        </Box>
      );
    }
    return <Box />;
  };

  const getMappedNames = () => {
    return (
      <Card elevation={2} sx={{ p: 2 }}>
        <Typography>Price Trend Names:</Typography>
        <Box sx={{ ml: 1 }}>
          {mapped?.map((el) => (
            <Box
              sx={{
                my: 1,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography>{el}</Typography>
              {edit && (
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => setMapped(mapped.filter((it) => it !== el))}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
        {edit && (
          <Box sx={{}}>
            <SelectPriceTrend
              onChange={(el) => {
                if (el) setMapped([...mapped, el]);
              }}
              value={undefined}
            />
          </Box>
        )}
      </Card>
    );
  };

  const getMappedNamesAX = () => {
    return (
      <Card elevation={2} sx={{ p: 2 }}>
        <Typography>Price Annexe Names:</Typography>
        <Box sx={{ ml: 1 }}>
          {mappedAX?.map((el) => (
            <Box
              sx={{
                my: 1,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography>{el}</Typography>
              {edit && (
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                  onClick={() =>
                    setMappedAX(mappedAX.filter((it) => it !== el))
                  }
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
        {edit && (
          <Box sx={{}}>
            <SelectPriceTrend
              onChange={(el) => {
                if (el) setMappedAX([...mappedAX, el]);
              }}
              value={undefined}
            />
          </Box>
        )}
      </Card>
    );
  };

  const getMappedMappedNames = () => {
    return (
      <Card elevation={2} sx={{ p: 2 }}>
        <Typography>Supply/Demand Names:</Typography>
        <Box sx={{ ml: 1 }}>
          {mappedMarket?.map((el) => (
            <Box
              sx={{
                my: 1,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography>{el}</Typography>
              {edit && (
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                  onClick={() =>
                    setMappedMarket(mappedMarket.filter((it) => it !== el))
                  }
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
        {edit && (
          <Box sx={{}}>
            <SelectMarketTrend
              onChange={(el) => {
                if (el) setMappedMarket([...mappedMarket, el]);
              }}
              value={undefined}
            />
          </Box>
        )}
      </Card>
    );
  };

  const getIframeLinks = () => {
    return (
      <Card elevation={2} sx={{ p: 2 }}>
        <Typography>Iframe Product links:</Typography>
        <Box sx={{ ml: 1 }}>
          {iframes.map((el) => (
            <Box
              sx={{
                my: 1,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography>{el}</Typography>
              {edit && (
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => setIframes(iframes.filter((it) => it !== el))}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
        {edit && (
          <Box sx={{ display: "flex" }}>
            <TextField
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={(el) => setNewFrame(el.target.value)}
              value={newFrame}
            />
            <Button
              onClick={() => {
                setIframes([...iframes, newFrame]);
                setNewFrame(undefined);
              }}
              disabled={!newFrame}
              sx={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Add />
              <span>Add</span>
            </Button>
          </Box>
        )}
      </Card>
    );
  };
  const getExtLinks = () => {
    return (
      <Card elevation={2} sx={{ p: 2 }}>
        <Typography>Ext. Product links:</Typography>
        <Box sx={{ ml: 1 }}>
          {links.map((el) => (
            <Box
              sx={{
                my: 1,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography>{el}</Typography>
              {edit && (
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => setLinks(links.filter((it) => it !== el))}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
        {edit && (
          <Box sx={{ display: "flex" }}>
            <TextField
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={(el) => setNewLink(el.target.value)}
              value={newLink}
            />
            <Button
              onClick={() => {
                setLinks([...links, newLink]);
                setNewLink(undefined);
              }}
              disabled={!newLink}
              sx={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Add />
              <span>Add</span>
            </Button>
          </Box>
        )}
      </Card>
    );
  };

  const getMPNs = () => {
    return (
      <Box>
        <Typography>MPN:</Typography>
        <Box sx={{ ml: 1 }}>
          {product?.codes
            ?.filter((el) => el.type === "MPN")
            .map((el) => (
              <Typography>{el.code || "-"}</Typography>
            ))}
        </Box>
      </Box>
    );
  };

  const handleMerge = () => {
    if (merge && !mergeLoading && merge.id !== id) {
      mergeRequest({}, { mergeId: merge.id });
    }
  };

  const changeWatch = (event, productId, watch) => {
    event.stopPropagation();
    if (productId) {
      requestWatch({}, { productId: productId, watched: !!watch });
    }
  };

  const changeRFQ = (event, productId, add) => {
    event.stopPropagation();
    if (productId) {
      if (add) {
        requestAddRFQ({}, [{ productId: productId }]);
      } else {
        requestDelRFQ({ productId: productId }, {});
      }
    }
  };

  if (!id) return null;
  if (loading || bookLoading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Container>
    );
  }

  console.log(product);
  return (
    <Container>
      <GoBack />

      {product ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {edit ? (
                <TextField
                  sx={{ width: "100%" }}
                  value={product.name}
                  onChange={(val) =>
                    setProduct({ ...product, name: val.target.value })
                  }
                />
              ) : (
                <Typography variant={"h6"} sx={{ p: 0 }}>
                  {product.name}
                </Typography>
              )}
              {getMPNs()}
              {getInternalSourcesId()}
              {edit && getMappedNames()}
              {edit && getMappedNamesAX()}
              {edit && getMappedMappedNames()}
              {edit && getIframeLinks()}
              {edit && getExtLinks()}
              {edit && (
                <Card elevation={2} sx={{ p: 2 }}>
                  <Typography sx={{ mb: 1 }}>
                    Merge this product to another one:
                  </Typography>
                  <ProductSearch
                    defaultSelected={merge}
                    excludeIds={[id]}
                    onSelect={(productId) => setMerge(productId)}
                  />
                  <LoadingButton
                    size="small"
                    sx={{ mt: 1 }}
                    disabled={!merge}
                    loading={mergeLoading}
                    color="error"
                    variant={"contained"}
                    onClick={handleMerge}
                  >
                    Merge To
                  </LoadingButton>
                </Card>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                pb: 2,
                gap: 1,
                mt: 1,
              }}
            >
              <IconButton
                onClick={(event) =>
                  changeWatch(event, product.id, !product.isWatched)
                }
              >
                {!product.isWatched ? <StarOutline /> : <Star />}
              </IconButton>
              <IconButton
                onClick={(event) =>
                  changeRFQ(event, product.id, !product.rfqs?.length)
                }
              >
                {!product.rfqs?.length ? <BookmarkBorder /> : <Bookmark />}
              </IconButton>
              {edit && (
                <Button
                  size="small"
                  color="error"
                  variant={"contained"}
                  onClick={() => setEdit(false)}
                >
                  Cancel
                </Button>
              )}
              <LoadingButton
                loading={updateLoading}
                size="small"
                color="primary"
                variant={edit ? "contained" : "outlined"}
                onClick={handleEditProduct}
              >
                {edit ? "Save" : "Edit"}
              </LoadingButton>
            </Box>

            <Card sx={{ p: 1 }}>
              <ProductMetrics product={product} />

              <ProductSummaryButtons
                product={product}
                onCreateBid={(productId) =>
                  history.push(`/product/${productId}/book/edit-row/bid`)
                }
                onCustomWts={(productId) =>
                  history.push(`/product/${productId}/book/edit-row/wts`)
                }
                onCreateWtb={(productId) =>
                  history.push(`/product/${productId}/book/edit-row/wtb`)
                }
                onCreatAsk={(productId) =>
                  history.push(`/product/${productId}/book/edit-row/ask`)
                }
              />
            </Card>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => history.push("/book", { productId: id })}
            >
              Full Book
            </Button>
          </Box>

          <TabsContent
            id={id}
            bookResponse={bookResponse}
            currentTab={currentTab}
          />
          {iframes.map((frame, index) => (
            <Box key={index} sx={{ textAlign: "center" }}>
              <iframe src={frame} width="100%" height={"600px"} />
              <a href={frame} target="_blank">
                <Button>Go</Button>
              </a>
            </Box>
          ))}
          <Box
            sx={{
              textAlign: "center",
              gap: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {links.map((link, index) => (
              <a key={index} href={link} target="_blank">
                <Button>{link}</Button>
              </a>
            ))}
          </Box>
        </Box>
      ) : undefined}
    </Container>
  );
});
