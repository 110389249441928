import React from 'react'
import {Box} from "@mui/material";
import SetPassword from "../../components/auth/setPassword";

export default function SetPasswordPage() {


    return <Box sx={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <SetPassword/>
    </Box>
}