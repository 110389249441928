import React from "react";
import { Box, Container } from "@mui/material";
import BookWithFilters from "../components/book/bookWithFilters";
import ActionsBar from "../components/actionsBar";

export default function BookPage() {
  return (
    <Container sx={{ pt: "6px" }}>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          mt: { lg: 1 },
          flexWrap: "wrap",
          mb: 1,
        }}
      >
        <ActionsBar />
      </Box> */}
      <BookWithFilters />
    </Container>
  );
}
