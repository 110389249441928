import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  useTheme,
} from "@mui/material";
import React from "react";
import {
  Ballot,
  Dashboard,
  LeakAdd,
  LocalOffer,
  Logout,
  Person,
  LocationSearching,
  MenuBook,
  Business,
  TrackChanges,
  LightMode,
  DarkMode,
  Add,
  Bookmark,
  Star,
} from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import { ColorModeContext } from "../index";
import { getAuth } from "firebase/auth";

export default function AppMenu() {
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <List>
      <List>
        <ListItem
          onClick={() => history.push("/board")}
          button
          selected={location.pathname === "/board"}
        >
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Board" />
        </ListItem>
      </List>

      <List>
        <ListItem
          onClick={() => history.push("/product")}
          button
          selected={location.pathname.search("/product") >= 0}
        >
          <ListItemIcon>
            <Ballot />
          </ListItemIcon>
          <ListItemText primary="Market" />
        </ListItem>

        {/*<ListItem disabled onClick={() => history.push('/model')} button selected={location.pathname.search('/model') >= 0}>*/}
        {/*    <ListItemIcon>*/}
        {/*        <Ballot/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="By models"/>*/}
        {/*</ListItem>*/}

        {/*<ListItem disabled onClick={() => history.push('/price-tracking')} button selected={location.pathname.search('/price-tracking') >= 0}>*/}
        {/*    <ListItemIcon>*/}
        {/*        <Assessment/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="Price tracking"/>*/}
        {/*</ListItem>*/}
      </List>

      <ListItem
        onClick={() => history.push("/book")}
        button
        selected={location.pathname === "/book"}
      >
        <ListItemIcon>
          <MenuBook />
        </ListItemIcon>
        <ListItemText primary="Book" />
      </ListItem>

      <ListItem
        onClick={() => history.push("/rfq")}
        button
        selected={location.pathname === "/rfq"}
      >
        <ListItemIcon>
          <Bookmark />
        </ListItemIcon>
        <ListItemText primary="RFQ" />
      </ListItem>

      <ListItem
        onClick={() => history.push("/rft")}
        button
        selected={location.pathname === "/rft"}
      >
        <ListItemIcon>
          <Star />
        </ListItemIcon>
        <ListItemText primary="RFT" />
      </ListItem>

      {/* <ListItem onClick={() => history.push('/targetprices')} button selected={location.pathname === '/targetprices'} >
				<ListItemIcon>
					<TrackChanges />
				</ListItemIcon>
				<ListItemText primary="Target €" />
			</ListItem> */}

      <Divider />

      <List subheader={<ListSubheader component="div">Users</ListSubheader>}>
        <ListItem
          button
          selected={location.pathname.search("/clients") >= 0}
          onClick={() => history.push("/clients")}
        >
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary="Buyers" />
        </ListItem>

        {/* <ListItem
          button
          selected={location.pathname.search("/leads") >= 0}
          onClick={() => history.push("/leads")}
        >
          <ListItemIcon>
            <LocationSearching />
          </ListItemIcon>
          <ListItemText primary="Leads" />
        </ListItem> */}

        {/*<ListItem disabled button selected={location.pathname.search('/distributor') >= 0}>*/}
        {/*    <ListItemIcon>*/}
        {/*        <PeopleAlt/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="Distributor"/>*/}
        {/*</ListItem>*/}

        <ListItem
          button
          selected={location.pathname.search("/tags") >= 0}
          onClick={() => history.push("/tags")}
        >
          <ListItemIcon>
            <LocalOffer />
          </ListItemIcon>
          <ListItemText primary="Tags" />
        </ListItem>

        <ListItem
          button
          selected={location.pathname.search("/distributors") >= 0}
          onClick={() => history.push("/distributors")}
        >
          <ListItemIcon>
            <Business />
          </ListItemIcon>
          <ListItemText primary="Sellers" />
        </ListItem>
      </List>

      <Divider />

      <List subheader={<ListSubheader component="div">Settings</ListSubheader>}>
        <ListItem
          onClick={() => history.push("/product/create")}
          button
          selected={location.pathname.search("/product/create") >= 0}
        >
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <ListItemText primary={"Product"} />
        </ListItem>
        <ListItem
          onClick={colorMode.toggleColorMode}
          button
          selected={location.pathname.search("/crawler") >= 0}
        >
          <ListItemIcon>
            {theme.palette.mode === "dark" ? <LightMode /> : <DarkMode />}
          </ListItemIcon>
          <ListItemText
            primary={theme.palette.mode === "dark" ? "LightMode" : "DarkMode"}
          />
        </ListItem>
        <ListItem
          onClick={() => getAuth().signOut()}
          button
          selected={location.pathname.search("/crawler") >= 0}
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary={"SignOut"} />
        </ListItem>
      </List>

      {/* <List
                subheader={
                    <ListSubheader component='div'>
                        Monitoring
                    </ListSubheader>
                }>
                <ListItem onClick={() => history.push('/crawler')} button selected={location.pathname.search('/crawler') >= 0}>
                    <ListItemIcon>
                        <LeakAdd/>
                    </ListItemIcon>
                    <ListItemText primary="Crawlers"/>
                </ListItem>
            </List> */}
    </List>
  );
}
