import React, {useEffect} from 'react'
import { Button, Card, CardActions, CardContent, LinearProgress, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment";
import {REQUEST_STATUS, useLazyRequest} from "../../hooks/request";
import {useSnackbar} from "notistack";
import {useRefetchContext} from "../../contexts/refetch.context";

const useStyle = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        flex: 1
    }
}))

export default function PriceCrawlerCard({source, loading, isLoading}) {
    const classes = useStyle()

    const {enqueueSnackbar} = useSnackbar()
    const {setToRefetch} = useRefetchContext()

    const {request: fetchRequest, response: fetchResponse, loading: fetchLoading} = useLazyRequest(`/prices/start-crawler/${source.name}`)

    useEffect(() => {
        if (fetchResponse && fetchResponse.status === REQUEST_STATUS.SUCCESS) {
            setToRefetch(['/crawler-log'])
            enqueueSnackbar(`${fetchResponse.data.created} prices linked, ${fetchResponse.data.unmapped} prices unlinked`, {variant: "success"})
        }
    }, [fetchResponse])

    useEffect(() => (isLoading(fetchLoading)), [fetchLoading])

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography variant={'h6'}>{source.name}</Typography>
                <Typography>Last run: <b>{source.log ? moment(source.log.lastRun).format('HH:mm DD/MM/YYYY') : '-'}</b></Typography>
            </CardContent>
            <CardActions>
                <Button size="small" disabled={loading || fetchLoading || !source.isOnline} onClick={() => fetchRequest()}>Fetch</Button>
            </CardActions>
            {(fetchLoading) && <LinearProgress/>}

        </Card>
    )
}