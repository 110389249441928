import React, {useState} from 'react'
import {Box, Container, TextField, Typography} from "@mui/material";
import DistributorListController from '../components/distributor/distributorList.controller.js';
import AddDistributor from '../components/distributor/addDistributor.js';

export default function DistributorsPage() {
    const [search, setSearch] = useState(undefined)
    return (
        <Container>
            <Typography sx={{textAlign: 'left', my: 2, fontWeight: 'bold'}} variant="h4">Sellers</Typography>
            <AddDistributor/>
            <Box sx={{mt: 2, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <TextField label="Seller" onChange={(val) => setSearch(val.target.value)}/>
            </Box>
            <DistributorListController search={search}/>
        </Container>
    )
}