import React, {useState} from 'react'
import {Paper, TextField, Typography} from "@mui/material"
import { getAuth, updatePassword } from "firebase/auth"
import LoadingButton from '@mui/lab/LoadingButton'
import {useHistory} from "react-router-dom";

export default function SetPassword() {
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState(undefined)
    const [password2, setPassword2] = useState(undefined)
    const [error, setError] = useState(undefined)
    const history = useHistory()


    const handleChangePassword = () => {
        setLoading(true)
        const auth = getAuth()
        updatePassword(auth.currentUser, password)
            .then((userCredential) => {
                setLoading(false)
                history.replace(`/`)
            })
            .catch((error) => {
                setLoading(false)
                setError(error.message)
            })
    }

    return <Paper sx={{padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2}}>
        {error && <Typography color='error'>{error}</Typography>}
        <TextField label="New Password" required type='password' value={password} onChange={(event) => setPassword(event.target.value)} />
        <TextField label="Confirm New Password" required type='password' value={password2} onChange={(event) => setPassword2(event.target.value)}/>
        <LoadingButton disabled={!password2 || !password || password2 !== password} loading={loading} onClick={() => handleChangePassword()} variant='outlined'>Set password</LoadingButton>
    </Paper>
}