import moment from 'moment'

export const initStockTrends = (data) => {
    const series = []
    const open = {}
    for (let book of data.reverse()) {
        const foundIndex = series.findIndex(el => el.date === moment(book.createdAt).format("DD/MM/YYYY"))
        if (foundIndex >= 0) {
            if (book.user?.name === 'A&C System BE') series[foundIndex].ac = book.stock
            else if (book.user?.name === 'TechData BE') series[foundIndex].td = book.stock
            else if (book.user?.name === 'Ingram BE') series[foundIndex].ig = book.stock
        } else if (book.stock > 0) {
            let dt = {date: moment(book.createdAt).format("DD/MM/YYYY")}
            if (open.ac) dt.ac = open.ac
            if (open.td) dt.td = open.td
            if (open.ig) dt.ig = open.ig

            if (book.user?.name === 'A&C System BE') dt.ac = book.stock
            else if (book.user?.name === 'TechData BE') dt.td = book.stock
            else if (book.user?.name === 'Ingram BE') dt.ig = book.stock


            series.push(dt)
        }

        if (book.state === 'OPEN') {
            if (book.user?.name === 'A&C System BE') open.ac = book.stock
            else if (book.user?.name === 'TechData BE') open.td = book.stock
            else if (book.user?.name === 'Ingram BE') open.ig = book.stock
        }
    }

    return series;

    // flattedData = flattedData.sort((a,b) => {
    //     let dateA = new Date(a.createdAt).getTime();
    //     let dateB = new Date(b.createdAt).getTime();
    //     return dateA - dateB;})
    //
    // return flattedData.map(priceTrend => ({x: new Date(priceTrend.createdAt), y: priceTrend.price}))
}