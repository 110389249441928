import React, { useState } from "react";
import {
  Box,
  Chip,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SearchInput from "../searchInput";
import {
  Bookmark,
  BookmarkBorder,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Star,
  StarOutline,
} from "@mui/icons-material";
import { useSettingsContext } from "../../contexts/settings.context";

export default function ProductsSortAndFilter() {
  const { marketFilters, setMarketFilters } = useSettingsContext();

  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  // const [expend, setExpend] = useState(false)
  const { onSearch, setOnSearch } = useSettingsContext();

  return (
    <Box>
      <Box
        sx={{
          mt: 1,
          mb: !matches && 2,
          position: matches && "fixed",
          bottom: matches && 56,
          backgroundColor: matches && "background.paper",
          left: matches && 0,
          zIndex: matches && 10,
          p: matches && 1,
          width: 1,
        }}
      >
        <Box
          onClick={() => setOnSearch(false)}
          sx={{
            display: !matches || !onSearch ? "none" : "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h7">{!onSearch && "Filter / order"}</Typography>
          <IconButton>
            {onSearch ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </IconButton>
        </Box>

        <Box
          sx={{
            display: onSearch || !matches ? "flex" : "none",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box>
            <Typography>Filters by</Typography>

            <Box
              sx={{
                width: 1,
                overflowX: "auto",
                display: "flex",
                gap: 1,
                p: 1,
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <SearchInput
                value={marketFilters.productName}
                onSearch={(val) =>
                  setMarketFilters({ ...marketFilters, productName: val })
                }
                style={{ width: { md: "50%", xs: "100%" } }}
                submitOnEnter={true}
                focus={!!onSearch}
              />
              {/* <Chip onClick={() => setInvisibleOnly(!invisibleOnly)} color={invisibleOnly ? 'primary' : undefined} label="Invisible"/> */}
              {/* <Box>
                                <FormControl sx={{ minWidth: 150, mt: { xs: 1, sm: 0 } }}>
                                    <InputLabel id="category-select-label">Category</InputLabel>
                                    <Select labelId="category-select-label" id="category-id" value={category} label='Category' onChange={({ target: { value } }) => setCategory(value)} >
                                        <MenuItem value="ALL">
                                            All
                                        </MenuItem>
                                        <MenuItem value="Tablets">
                                            Tablets
                                        </MenuItem>
                                        <MenuItem value="Mobile phones">
                                            Mobile phones
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box> */}
              <Box sx={{ display: "flex", gap: 1 }}>
                <Chip
                  onClick={() =>
                    marketFilters.types.find((el) => el === "WTS")
                      ? setMarketFilters({
                          ...marketFilters,
                          types: marketFilters.types.filter(
                            (tt) => tt !== "WTS"
                          ),
                        })
                      : setMarketFilters({
                          ...marketFilters,
                          types: [...marketFilters.types, "WTS"],
                        })
                  }
                  color={
                    marketFilters.types.find((el) => el === "WTS")
                      ? "warning"
                      : "default"
                  }
                  label="WTS"
                />
                <Chip
                  onClick={() =>
                    marketFilters.types.find((el) => el === "BID")
                      ? setMarketFilters({
                          ...marketFilters,
                          types: marketFilters.types.filter(
                            (tt) => tt !== "BID"
                          ),
                        })
                      : setMarketFilters({
                          ...marketFilters,
                          types: [...marketFilters.types, "BID"],
                        })
                  }
                  color={
                    marketFilters.types.find((el) => el === "BID")
                      ? "error"
                      : "default"
                  }
                  label="BID"
                />
                <Chip
                  onClick={() =>
                    marketFilters.types.find((el) => el === "ASK")
                      ? setMarketFilters({
                          ...marketFilters,
                          types: marketFilters.types.filter(
                            (tt) => tt !== "ASK"
                          ),
                        })
                      : setMarketFilters({
                          ...marketFilters,
                          types: [...marketFilters.types, "ASK"],
                        })
                  }
                  color={
                    marketFilters.types.find((el) => el === "ASK")
                      ? "success"
                      : "default"
                  }
                  label="ASK"
                />
                <Chip
                  onClick={() =>
                    marketFilters.types.find((el) => el === "WTB")
                      ? setMarketFilters({
                          ...marketFilters,
                          types: marketFilters.types.filter(
                            (tt) => tt !== "WTB"
                          ),
                        })
                      : setMarketFilters({
                          ...marketFilters,
                          types: [...marketFilters.types, "WTB"],
                        })
                  }
                  color={
                    marketFilters.types.find((el) => el === "WTB")
                      ? "primary"
                      : "default"
                  }
                  label="WTB"
                />
              </Box>
              <IconButton
                onClick={() =>
                  setMarketFilters({
                    ...marketFilters,
                    watched: !marketFilters.watched,
                  })
                }
              >
                {!marketFilters.watched ? <StarOutline /> : <Star />}
              </IconButton>
              <IconButton
                onClick={() =>
                  setMarketFilters({
                    ...marketFilters,
                    rfq: !marketFilters.rfq,
                  })
                }
              >
                {!marketFilters.rfq ? <BookmarkBorder /> : <Bookmark />}
              </IconButton>
            </Box>
          </Box>

          <Box>
            <Typography>Sort by</Typography>

            <Box
              sx={{
                width: 1,
                overflowX: "auto",
                display: "flex",
                gap: 1,
                p: 1,
              }}
            >
              <Chip
                onClick={() =>
                  setMarketFilters({ ...marketFilters, orderBy: "alphabet" })
                }
                color={
                  marketFilters.orderBy === "alphabet" ? "primary" : undefined
                }
                label="Abc"
              />
              <Chip
                onClick={() =>
                  setMarketFilters({ ...marketFilters, orderBy: "deltaBenef" })
                }
                color={
                  marketFilters.orderBy === "deltaBenef" ? "primary" : undefined
                }
                label="TotalProfit"
              />
              <Chip
                onClick={() =>
                  setMarketFilters({ ...marketFilters, orderBy: "diffPrice" })
                }
                color={
                  marketFilters.orderBy === "diffPrice" ? "primary" : undefined
                }
                label="Diff. Price"
              />
              <Chip
                onClick={() =>
                  setMarketFilters({ ...marketFilters, orderBy: "chiDa" })
                }
                color={
                  marketFilters.orderBy === "chiDa" ? "primary" : undefined
                }
                label="ChiDa"
              />
              <Chip
                onClick={() =>
                  setMarketFilters({ ...marketFilters, orderBy: "quantity" })
                }
                color={
                  marketFilters.orderBy === "quantity" ? "primary" : undefined
                }
                label="Quantity"
              />
              <Chip
                onClick={() =>
                  setMarketFilters({ ...marketFilters, orderBy: "dateRow" })
                }
                color={
                  marketFilters.orderBy === "dateRow" ? "primary" : undefined
                }
                label="DateRow"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
