import React from 'react'
import { CircularProgress } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(4),
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

export default function Loading({children, loading}) {
    const classes = useStyles()

    if (loading) return (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    )
    return <React.Fragment>{children}</React.Fragment>
}