import React, {useState} from 'react';
import {Box, Button, TextField} from "@mui/material";
import {REQUEST_METHOD, useLazyRequest} from "../../../hooks/request";
import {LoadingButton} from "@mui/lab";

export default function RowNote({row}) {
    const [edit, setEdit] = useState(false)
    const [note, setNote] = useState(row?.meta?.note)
    const {response, loading, request} = useLazyRequest(`/book/${row.id}/meta`, REQUEST_METHOD.PUT)

    const saveNote = () => {
        request({}, {note})
        setEdit(false)
    }

    if (!row) return <Box/>
    return <Box sx={{flexDirection: 'column', display: 'flex'}}>
        {(note || edit) && <TextField inputProps={{
            readOnly: !edit,
        }} value={note} onChange={(val) => setNote(val.target.value)} multiline={true}/>}
        <LoadingButton loading={loading} onClick={() => edit ? saveNote() : setEdit(true)}>{edit ? 'Save' : note ? 'Edit' : 'Add' } note</LoadingButton>
    </Box>
}