import React from 'react'
import {TextField} from "@mui/material";

export default function NumTextField({ label, variant, color, sx, value, helperText, disabled, error, fullWidth, onChange}) {
    return (
        <TextField
            error={error}
            disabled={disabled}
            fullWidth={fullWidth}
            label={label}
            helperText={helperText}
            variant={variant}
            color={color}
            onFocus={(event => event.target.select())}
            onChange={({ target: { value } }) => onChange(value.replace(/[,]/g, '.').replace(/[^0-9.]/g, ''))}
            inputProps={{ inputMode: "decimal", pattern: "[-+]?[0-9]*[.,]?[0-9]+", min: 1, type: "tel" }}
            sx={sx}
            value={value} />
    )
}