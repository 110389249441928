import React from "react";
import {
	Box,
} from "@mui/material";
import UserCard from "../userCard";

export default function ClientsList({ clients, onSelect }) {
	return <Box>
		{clients && clients.map(cl => <UserCard user={cl} onClick={() => onSelect(cl.id)} />)}
	</Box>

}