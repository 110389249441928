import React, {useEffect, useState} from 'react'
import { Grid, Paper, TextField, Button, Box, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useAuth} from "../contexts/auth.context";
import {
    useHistory,
    useLocation
} from "react-router-dom"
import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest} from "../hooks/request";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    paper: {
        padding: theme.spacing(5),
    }
}))

export default function AuthPage() {
    const {setUser} = useAuth()
    const classes = useStyles();

    const history   = useHistory();
    const location  = useLocation();
    const { from }  = location.state || { from: { pathname: "/" } };

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const {request, response, loading, error} = useLazyRequest('/auth/login', REQUEST_METHOD.POST)

    const onLogIn = () => {
        request({}, {email, password}) //TODO encrypt password
    }

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            setUser(response.data)
            history.replace(from)
        } else if (error) {
            console.log(error)
        }
    }, [response, error])

    return (
        <Box className={classes.root}>
            <Grid item sm={6} xs={12}>
                <Paper className={classes.paper} >
                    <h1>Log in</h1>
                    <form>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <TextField required label="Email" variant="outlined" fullWidth value={email} onChange={({target: {value}}) => setEmail(value)} />
                            </Grid>
                            <Grid item xs>
                                <TextField required label="Password" type="password" variant="outlined" fullWidth value={password} onChange={({target: {value}}) => setPassword(value)} />
                            </Grid>

                            <Grid item xs>
                                <Button onClick={onLogIn} disabled={loading} variant="contained" color="primary" fullWidth>Log in</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>

                {loading && <LinearProgress/>}
            </Grid>
        </Box>
    )
}