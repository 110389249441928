import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest, useRequest} from "../../hooks/request";
import React, {useEffect, useState} from "react";
import {Box, CircularProgress} from "@mui/material";
// import {useHistory} from "react-router-dom";
import UserTagList from "./userTagList";
import {useRefetchContext} from "../../contexts/refetch.context";

export default function UserTagsListController(){
    const {refetch, loading, response} = useRequest('/user-tag');
    const {response: responseClients} = useRequest('/client/list')
    const {request, loading: loadingDelete, response: responseDelete} = useLazyRequest('/user-tag/delete', REQUEST_METHOD.DELETE)
    const {setToRefetch} = useRefetchContext()
    const [tags, setTags] = useState([]);

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            setTags(response.data)
        }
    }, [response])


    useEffect(() => {
        if (responseDelete && responseDelete.status === REQUEST_STATUS.SUCCESS) {
            setToRefetch(['/user-tag']);
        }
    }, [responseDelete])
    return (
        <div>
           <UserTagList tags={tags} users={responseClients?.data || []} onDelete={(id) => request({id}, {})}/>
        </div>
    )
}