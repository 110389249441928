import {
  REQUEST_METHOD,
  REQUEST_STATUS,
  useLazyRequest,
  useRequest,
} from "../../hooks/request";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";

export default function WatchListController({}) {
  const { loading, response, refetch } = useRequest("/rft/list");
  const { request: requestDelRFQ, response: responseDelRFQ } = useLazyRequest(
    "/rft/delete",
    REQUEST_METHOD.DELETE
  );
  const [selected, setSelected] = useState(undefined);
  const [brands, setBrands] = useState(undefined);
  const [txt, setTxt] = useState(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const [meta, setMeta] = useState(false);

  useEffect(() => {
    refetch();
  }, [responseDelRFQ]);

  useEffect(() => {
    if (response?.data) {
      const tmp = [];
      response.data.forEach((el) => {
        if (!tmp.find((tt) => tt.name === el.brand))
          tmp.push({ name: el.brand, selected: true });
      });
      setBrands(tmp);
    }
  }, [response]);

  useEffect(() => {
    if (response?.data && brands) {
      setSelected([
        ...response.data.filter(
          (el) => brands.find((brand) => brand.name === el.brand)?.selected
        ),
      ]);
    }
  }, [brands]);

  useEffect(() => {
    if (selected) {
      let res = "";
      for (let row of selected) {
        if (row) {
          res += `${row.name}`;
          if (meta) {
            if (row.book?.length && row.book[0].stock) {
              res += ` - ${row.book[0].stock}pcs`;
            }
            if (row.book?.length && row.book[0].price)
              res += ` - ${row.book[0].price}€`;
            else res += ` - Call`;
          }

          res += `\r\r`;
        }
      }
      setTxt(res);
    }
  }, [selected, meta]);

  if (loading)
    return (
      <Box sx={{ padding: 5, display: "flex", justifyContent: "center" }}>
        <CircularProgress size={50} />
      </Box>
    );

  const handleCheckbox = (rfq, checked) => {
    if (checked) {
      setSelected([...selected, rfq]);
    } else {
      setSelected(selected.filter((el) => el.id !== rfq.id));
    }
  };

  const handleBrandChange = (brand) => {
    let tmp = brands;
    const idx = tmp.findIndex((el) => el.name === brand.name);
    tmp[idx].selected = !brands[idx].selected;
    setBrands([...tmp]);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Chip
          sx={{ mx: 1 }}
          label={"Price / Qty"}
          onClick={() => {
            setMeta(!meta);
          }}
          color="primary"
          variant={meta ? "filled" : "outlined"}
        />
      </Box>
      <Box
        sx={{ overflow: "auto", display: "flex", flexDirection: "row", p: 1 }}
      >
        {brands &&
          brands.map((brand) => (
            <Chip
              sx={{ mx: 1 }}
              label={brand.name}
              onClick={() => {
                handleBrandChange(brand);
              }}
              color="secondary"
              variant={brand.selected ? "filled" : "outlined"}
            />
          ))}
      </Box>
      {selected &&
        brands &&
        response?.data?.map((rfq) => (
          <Box
            key={rfq.id}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selected.find((el) => el.id === rfq.id)}
                  onChange={(event) =>
                    handleCheckbox(rfq, event.target.checked)
                  }
                />
              }
              label={
                meta
                  ? `${rfq.name}${
                      rfq.book?.length && rfq.book[0].stock
                        ? ` - ${rfq.book[0].stock}pcs`
                        : ""
                    }${
                      rfq.book?.length && rfq.book[0].price
                        ? ` - ${rfq.book[0].price}€`
                        : ""
                    }`
                  : rfq.name
              }
            />
            <IconButton
              onClick={() => {
                requestDelRFQ({ id: rfq.id }, {});
              }}
            >
              <Clear color="error" />
            </IconButton>
          </Box>
        ))}
      <Divider sx={{ my: 5 }} />

      <Box sx={{ my: 1, display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            copy(txt, { format: "text/plain" });
            enqueueSnackbar("Text copied to clipboard", { variant: "success" });
          }}
        >
          Copy text
        </Button>
      </Box>

      {selected && (
        <TextField
          multiline
          fullWidth
          onChange={(value) => setTxt(value.target.value)}
          value={txt}
          sx={{}}
        />
      )}
    </Box>
  );
}
