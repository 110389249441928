import React from 'react'
import {Box, Checkbox, FormControlLabel} from "@mui/material";
import NumTextField from "../../NumTextField";
import UserSearch from "../../clients/user.search";
import moment from "moment";

export default function CreateRowInputs({row, onRowChange}) {
    return (
        <React.Fragment>
            {(row.type === 'BID' || row.type === 'WTB')
                // && (row.attachedTo || row.chainHelperWtsSelected || row.chainHelperCreateWts)
                && <FormControlLabel
                    sx={{ width: 'auto', marginBottom: 3}}
                    control={
                        <Checkbox checked={row.stateExtra === 'ACCEPTED'}
                                  onChange={() => row.stateExtra === 'ACCEPTED' ? onRowChange({...row, stateExtra: undefined}) : onRowChange({...row, stateExtra: 'ACCEPTED'})} />} label={'Accepted'} />
            }

            <Box sx={{display: 'flex', flexDirection: {sm: 'row', xs: 'column'}}}>


                <Box sx={{display: 'flex', flexDirection: 'row', width: {sm: (row.type === 'ASK' || row.type === 'WTS') ? '66%' : 1, xs: 1}}}>
                    <NumTextField
                        label="Quantity"
                        variant="outlined"
                        color="secondary"
                        onChange={stock => onRowChange({...row, stock})}
                        sx={{ paddingRight: 1, width: { sm: "50%", xs: 'auto' } }}
                        value={row.stock} />
                    <NumTextField
                        label="Price"
                        variant="outlined"
                        color="secondary"
                        onChange={price => onRowChange({...row, price})}
                        sx={{ paddingRight: {xs: 1}, width: { sm: "50%", xs: 'auto' } }}
                        value={row.price} />
                </Box>
                {(row.type === 'ASK' || row.type === 'WTS' || row.type === 'BID') &&
                    <NumTextField
                        label="Estimated time of Arrival"
                        variant="outlined"
                        color="secondary"
                        onChange={estimatedTimeOfArrival => onRowChange({...row, meta: {...row.meta, estimatedTimeOfArrival}})}
                        sx={{ paddingRight: {xs: 1}, marginTop: {xs: 2, sm: 0}, width: {sm: '33%', xs: 'auto'}}}
                        value={row.meta.estimatedTimeOfArrival} />
                }
              {(row.type === 'ASK' || row.type === 'WTS' || row.type === 'BID') &&
                <NumTextField
                  label="Expire (days)"
                  variant="outlined"
                  color="secondary"
                  onChange={expireAt => onRowChange({...row, expireAt: expireAt})}
                  sx={{ marginTop: {xs: 2, sm: 0}, width: {sm: '33%', xs: 'auto'}}}
                  value={row.expireAt} />
              }
            </Box>
        </React.Fragment>

    )
}