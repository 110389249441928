import React, { useEffect, useState } from 'react'
import {
	Backdrop, Box,
	Button,
	CircularProgress,
	Container,
	IconButton,
	Paper, TextField,
	Typography
} from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { REQUEST_METHOD, useLazyRequest, useRequest } from "../hooks/request";
import { Check, Edit, StarOutline, Star } from "@mui/icons-material";
import BookWithFilters from "../components/book/bookWithFilters";
import ActionsBar from "../components/actionsBar";
import LinkTagToUser from '../components/clients/linkTagToUser';
import { GroupedTags } from '../components/tag/tagsUtils';


export default function DistributorPage() {
	const { id } = useParams()
	const history = useHistory()
	const { response, loading, refetch } = useRequest(`/distributor/${id}`)
	const [user, setUser] = useState(undefined);
	const [editMode, setEditMode] = useState(false);
	const { request, loading: editLoading, response: editResponse } = useLazyRequest(`/client/${id}`, REQUEST_METHOD.PUT)
	const { request: unlinkTagRequest, loading: unlinkTagLoading, response: unlinkTagResponse } = useLazyRequest(`/user-tag/unlink`, REQUEST_METHOD.POST)
	const { request: requestFav, response: responseFav } = useLazyRequest('/user/favorite', REQUEST_METHOD.POST);

	const [email, setEmail] = useState();
	const [name, setName] = useState();
	const [phoneNumber, setPhoneNumber] = useState();

	useEffect(() => {
		if (response && response.data) {
			setUser(response.data);
		}
	}, [response])

	useEffect(() => {
		refetch()
	}, [editResponse, unlinkTagResponse, responseFav])

	const handleEditMode = () => {
		if (editMode) {
			request({}, { email, name, phoneNumber })
			setEditMode(false);
		} else {
			setEditMode(true)
		}
	}

	const unlinkTag = (tagId) => {
		if (tagId && id) {
			unlinkTagRequest({}, { userId: id, tagId: tagId });
		}
	}

	const changeFav = (event, userId, fav) => {
		event.stopPropagation()
		if (userId) {
			requestFav({}, { userId, favorite: !!fav })
		}
	}

	return (
		<Container sx={{ py: 2 }}>
			{history.goBack && <Button onClick={() => history.goBack()} sx={{ m: 1 }} variant='text' color='inherit'>Back</Button>}
			{loading && <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>}
			{user && <>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button size='small' disabled={loading || editLoading} variant="outlined" startIcon={!editMode ? <Edit /> : <Check />} onClick={() => handleEditMode()}>{editMode ? 'Save' : 'Edit'}</Button>
					</Box>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					{editMode ?
						<TextField sx={{ margin: 1 }} id="name" label="Name" variant="outlined" value={name ? name : user.name} onChange={event => setName(event.target.value)} />
						: <Typography sx={{ textAlign: 'left', my: 2, fontWeight: 'bold' }} variant="h4">{user.name}</Typography>}
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<IconButton onClick={(event) => changeFav(event, user.id, !user.isFavorite)}>
							{!user.isFavorite ? <StarOutline /> : <Star />}
						</IconButton>
					</Box>

				</Box>

				<Paper sx={{ p: 2 }}>
					{editMode ?
						<TextField sx={{ margin: 1 }} id="email" label="Email" variant="outlined" value={email ? email : user.email} onChange={event => setEmail(event.target.value)} />
						: <Typography sx={{ textAlign: 'left' }}>{user.email}</Typography>}
					{editMode ?
						<TextField sx={{ margin: 1 }} id="phone-number" label="Phone Number" variant="outlined" value={phoneNumber} onChange={event => setPhoneNumber(event.target.value)} />
						: <Typography sx={{ textAlign: 'left' }}>Tel. {user.phoneNumber} {!user.phoneNumber && '-'}</Typography>}

				</Paper>

				<Box sx={{ my: 2 }}>
					<Paper sx={{ p: 2 }}>
						<ActionsBar sellerId={parseInt(id)} />
						{/*<ActionsButton onWts={() => setWts(true)} />*/}
						{/*<MultiCreateDialog onClose={() => setWts(false)} open={wts} userId={parseInt(id)} />*/}
					</Paper>
				</Box>

				{user.userTags?.length > 0 && <Paper sx={{ marginTop: 3, padding: 2 }}>
					<GroupedTags tags={user.userTags} deleteMode={editMode} onDelete={unlinkTag} />
				</Paper>}

				{editMode && <Paper sx={{ marginTop: 3, padding: 2 }}>
					<LinkTagToUser userId={id} linkedTags={user.userTags} />
				</Paper>}


				<Box sx={{ mt: 2 }}>
					<BookWithFilters userId={id} />
				</Box>
			</>}

		</Container>
	)
}