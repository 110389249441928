import React, { useEffect, useRef, useState } from "react";
import { Autocomplete } from "@mui/lab";
import {
  useRequest,
  REQUEST_METHOD,
  REQUEST_STATUS,
  useLazyRequest,
} from "../../hooks/request";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  DialogActions,
  createFilterOptions,
} from "@mui/material";

const filter = createFilterOptions();

export default function UserSearch({
  onSelect,
  defaultValueId = undefined,
  type = ["CUSTOMER", "DISTRIBUTOR"],
  label = "User",
  sx,
  focus = false,
}) {
  const { response } = useRequest(`/user/search`, "POST", {}, { type: type });
  const {
    request: requestClient,
    loading: loadingClient,
    response: responseClient,
  } = useLazyRequest("/client", REQUEST_METHOD.POST);
  const {
    request: requestDistri,
    loading: loadingDistri,
    response: responseDistri,
  } = useLazyRequest("/distributor", REQUEST_METHOD.POST);
  const inputRef = useRef(undefined);
  const [value, setValue] = useState(undefined);
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    email: "",
    name: "",
  });

  useEffect(() => {
    if (onSelect && value !== undefined) {
      onSelect(value);
    }
  }, [value]);

  useEffect(() => {
    if (inputRef && inputRef.current && focus) {
      inputRef.current.focus();
    }
  }, [inputRef, response]);

  useEffect(() => {
    if (responseDistri?.data) {
      setValue(responseDistri.data);
    }
  }, [responseDistri]);

  useEffect(() => {
    if (responseClient?.data) {
      setValue(responseClient.data);
    }
  }, [responseClient]);

  const handleClose = () => {
    setDialogValue({
      email: "",
      name: "",
    });
    toggleOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (type.length === 1) {
      if (type[0] === "DISTRIBUTOR") {
        requestDistri({}, { email: dialogValue.email, name: dialogValue.name });
      } else {
        requestClient(
          {},
          { email: dialogValue.email, name: dialogValue.name, noAuth: true }
        );
      }
    }
    setDialogValue({
      email: "",
      name: "",
    });
    handleClose();
  };

  return (
    <React.Fragment>
      {response && response.data ? (
        <Autocomplete
          selectOnFocus
          clearOnBlur
          freeSolo
          handleHomeEndKeys
          sx={{ width: "100%", ...sx }}
          // getOptionLabel={({name, email}) => email ?`${name}, ${email}`:name}
          defaultValue={
            defaultValueId
              ? response.data.find((el) => el.id === defaultValueId)
              : undefined
          }
          // onChange={(_, user) => onSelect(user?.id)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              inputRef={inputRef}
            />
          )}
          value={value}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              // timeout to avoid instant validation of the dialog's form.
              setTimeout(() => {
                toggleOpen(true);
                setDialogValue({
                  email: newValue,
                  name: "",
                });
              });
            } else if (newValue && newValue.inputValue) {
              toggleOpen(true);
              setDialogValue({
                email: newValue.inputValue,
                name: "",
              });
            } else {
              setValue(newValue);
            }
          }}
          getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (option.email || option.name) {
              return option.email
                ? `${option.name}, ${option.email}`
                : option.name;
            }
            if (option.inputValue) {
              return option.title;
            }
            return option.title;
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (params.inputValue !== "") {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          options={response.data}
        />
      ) : undefined}

      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new Buyer/Seller</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.email}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  email: event.target.value,
                })
              }
              label="Email"
              type="text"
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value,
                })
              }
              label="Name"
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
