import React from 'react'
import {Container, Typography} from "@mui/material";
import AddUserTag from "../components/clients/addUserTag";
import UserTagsListController from "../components/clients/userTagsList.controller";

export default function TagsPage() {
    return (
        <Container>
            <Typography sx={{textAlign: 'left', my: 2, fontWeight: 'bold'}} variant="h4">Users Tags</Typography>
            <AddUserTag/>
            <UserTagsListController/>
        </Container>
    )
}