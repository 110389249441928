import React, {useEffect, useState} from 'react'
import {Box, Chip, Typography} from "@mui/material";
import {REQUEST_STATUS, useRequest} from "../../hooks/request";

export default function UserTagsSelector({onSelect}) {
    const {response, loading} = useRequest('/user-tag')
    const [tags, setTags] = useState();
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS && response.data) {
            setTags(response.data)
        }
    }, [response])

    useEffect(() => {
        if (selected && onSelect) onSelect(selected)
    }, [selected])

    const handleTagClick = (tag) => {
        if (selected.find(el => el.id === tag.id)) {
            setSelected(selected.filter(el => el.id !== tag.id));
        } else {
            setSelected([...selected, tag]);
        }
    }

    const renderTagCategory = (category) => {
        return <Box>
            <Typography variant="subtitle1" component="i">{category.name}</Typography>
            <Box>
                {category.tags && category.tags.map(tag => <Chip key={tag.id} sx={{m: 1}} label={tag.name} variant={selected.find(el => el.id === tag.id) ? 'filled' : 'outlined'} color="primary" onClick={() => handleTagClick(tag)}/>)}
            </Box>
        </Box>
    }

    return (<Box>
        <Typography variant="h5" align="center">Tags</Typography>
        {tags && tags.map(tag => renderTagCategory(tag))}
    </Box>)
}