import React, {useEffect} from 'react'
import {REQUEST_METHOD, useLazyRequest} from "../../../hooks/request";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {Box} from "@mui/system";

export default function WtsActions({row, onSubmit}) {
    const {request, response, loading} = useLazyRequest(`/book/wts/${row.id}`, REQUEST_METHOD.POST)
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if (response && response.status !== 'error') {
            enqueueSnackbar('Wts updated', {variant: 'success'})
            onSubmit(response.data)
        }
    }, [response])

    const onClose = () => {
        request()
    }

    return (
        <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Box/>
            <LoadingButton onClick={onClose} loading={loading} disabled={row.state === 'CLOSE'}>Close</LoadingButton>
        </Box>
    )
}