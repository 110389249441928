import { REQUEST_METHOD, REQUEST_STATUS, useLazyRequest, useRequest } from "../../hooks/request";
import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, CircularProgress, Divider, FormControlLabel, IconButton, TextField, Typography } from "@mui/material";
import { Clear } from "@mui/icons-material";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";


export default function RFQController({ }) {
  const { loading, response, refetch } = useRequest('/rfq/list');
  const { request: requestDelRFQ, response: responseDelRFQ } = useLazyRequest('/rfq/delete', REQUEST_METHOD.DELETE);
  const [selected, setSelected] = useState(undefined);
  const [brands, setBrands] = useState(undefined)
  const [txt, setTxt] = useState(undefined)
  const { enqueueSnackbar } = useSnackbar()


  useEffect(() => {
    refetch();
  }, [responseDelRFQ])

  useEffect(() => {
    if (response?.data) {
      const tmp = [];
      response.data.forEach(el => {
        if (!tmp.find(tt => tt.name === el.product.brand))
          tmp.push({ name: el.product.brand, selected: true })
      })
      setBrands(tmp)
    }
  }, [response])

  useEffect(() => {
    if (response?.data && brands) {
      setSelected([...response.data.filter(el => brands.find(brand => brand.name === el.product.brand)?.selected)])
    }
  }, [brands])

  useEffect(() => {
    if (selected) {
      let res = ''
      for (let row of selected) {
        if (row && row.product)
          res += `${row.product.name}${(row.stock && row.stock > 0) ? ` - ${row.stock} pcs` : ''}\r\r`
      }
      setTxt(res)
    }
  }, [selected])

  if (loading) return <Box sx={{ padding: 5, display: 'flex', justifyContent: 'center' }}>
    <CircularProgress size={50} />
  </Box>

  const handleCheckbox = (rfq, checked) => {
    if (checked) {
      setSelected([...selected, rfq])
    } else {
      setSelected(selected.filter(el => el.id !== rfq.id))
    }
  }

  const handleBrandChange = (brand) => {
    let tmp = brands;
    const idx = tmp.findIndex(el => el.name === brand.name);
    tmp[idx].selected = !brands[idx].selected
    setBrands([...tmp])
  }

  return <Box>
    <Box sx={{ overflow: 'auto', display: 'flex', flexDirection: 'row', p: 1 }}>
      {brands && brands.map((brand) => <Chip sx={{ mx: 1 }} label={brand.name} onClick={() => { handleBrandChange(brand) }} color="secondary" variant={brand.selected ? 'filled' : 'outlined'} />)}
    </Box>
    {selected && brands && response?.data?.map(rfq => <Box key={rfq.id} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      <FormControlLabel control={<Checkbox checked={selected.find(el => el.id === rfq.id)} onChange={(event) => handleCheckbox(rfq, event.target.checked)} />} label={rfq.product.name} />
      <IconButton onClick={() => { requestDelRFQ({ id: rfq.id }, {}) }}><Clear color="error" /></IconButton>
    </Box>)}
    <Divider sx={{ my: 5 }} />

    <Box sx={{ my: 1, display: "flex", justifyContent: 'flex-end' }}>
      <Button variant="contained" color="primary" onClick={() => {
        copy(txt, {format: 'text/plain'})
        enqueueSnackbar('Text copied to clipboard', { variant: "success" })
      }}>
        Copy text
      </Button>
    </Box>

    {selected && <TextField
      multiline
      fullWidth
      onChange={value => setTxt(value.target.value)}
      value={txt}
      sx={{}}
    />}

  </Box>
}