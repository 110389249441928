import React, {useEffect, useState} from 'react'
import {REQUEST_STATUS, useRequest} from "../hooks/request";
import {FormControl, TextField} from "@mui/material";
import { Autocomplete } from '@mui/material';

export default function SelectPriceTrend({disable = false, onChange, className, value}) {
    const [selected, setSelected] = useState(value)
    const [options, setOptions] = useState([])
    const {response} = useRequest('/product/price-trends-names')

    useEffect(() => {
        if (selected) {
            onChange(selected)
        }

    }, [selected])

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            setOptions(response.data)
        }
    }, [response])

    return (
        <div className={className}>
            <FormControl fullWidth variant="outlined">
                <Autocomplete
                    key={selected}
                    labelId="product-select-label"
                    label="PriceTrend"
                    id="product-select"
                    options={options.map(el => (el.name))}
                    // value={selected}
                    disabled={disable}
                    // getOptionLabel={(option) => option.label}
                    onChange={(event, val) => setSelected(val)}
                    style={{ minWidth: "60%" }}
                    renderInput={(params) => <TextField {...params} label="PriceTrend" variant="outlined" />}
                />
            </FormControl>
        </div>
    )
}