export function extractBuy(book) {
    if (!book || !book.filter) return []
    return book.filter(elem => elem.type === "BUY" && (elem.from === "INTERNAL" || elem.from === "CUSTOMER"))
}

export function extractASK(book) {
    if (!book || !book.filter) return []
    return book.filter(elem => elem.type === "SELL" && (elem.from === "INTERNAL"))
}

export function extractSell(book) {
    if (!book || !book.filter) return []
    return book.filter(elem => elem.type === "SELL" && (elem.from === "INTERNAL" || elem.from === "DISTRIBUTOR"))
}

export function extractCustomerBuy(book) {
    if (!book || !book.filter) return []
    return book.filter(elem => elem.type === 'BUY' && elem.from === "CUSTOMER")
}

export function extractDistributorOffer(book) {
    if (!book || !book.filter) return []
    return book.filter(elem => elem.type === "SELL" && elem.state === 'OPEN' && elem.from === "DISTRIBUTOR")
}

export function extractResellerOffer(book) {
    if (!book || !book.filter) return []
    return book.filter(elem => elem.type === "SELL" && elem.from === "RESELLER" && elem.user?.name === "Yukatel")
}

export function isASK(row) {
    return row.type === 'SELL' && row.from === "INTERNAL"
}

export function isWTS(row) {
    return row.type === 'SELL' && row.from === "DISTRIBUTOR"
}

export function isBID(row) {
    return row.type === 'BUY' && row.from === "INTERNAL"
}

export function isWTB(row) {
    return row.type === 'BUY' && row.from === "CUSTOMER"
}

export function formatRow(row) {
    let type = row.type;
    if (row.type === 'SELL' && row.from !== "INTERNAL") type = 'WTS'
    else if (row.type === 'BUY' && row.from !== "INTERNAL") type = 'WTB'
    else if (row.type === 'BUY') type = 'BID'
    else if (row.type === 'SELL') type = 'ASK'

    // if (type === 'ASK') {
    //     let stock = row.stock
    //     const WTS = row.attachedBy?.filter(elem => isWTB(elem) && elem.stateExtra === 'ACCEPTED')
    //     WTS?.forEach(elem => {
    //         stock -= elem.stock
    //     })
    //     if (stock !== row.stock)
    //         return { ...row, type, stockRemaining: stock }
    // }
    // if (type === 'BID') {
    //     let stock = row.stock
    //     const BIDs = row.attachedBy?.filter(elem => isBID(row) && elem.stateExtra === 'ACCEPTED')
    //     BIDs?.forEach(elem => {
    //         stock -= elem.stock
    //     })
    //     if (stock !== row.stock)
    //         return { ...row, type, stockRemaining: stock }
    // }
    return { ...row, type, stockRemaining: undefined }
}

export const getTypeColor = (theme, type) => {
    if (type === 'BID') return theme.palette.error.main;
    else if (type === 'ASK') return theme.palette.success.main;
    else if (type === 'WTB') return theme.palette.primary.main;
    else if (type === 'WTS') return theme.palette.warning.main;
    return theme.palette.success.main;
}