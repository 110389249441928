import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest, useRequest} from "../../hooks/request";
import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Chip,
    FormControl,
    InputLabel, ListSubheader, MenuItem, OutlinedInput, Select
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useRefetchContext} from "../../contexts/refetch.context";
import {useSnackbar} from "notistack";

export default function LinkTagToUser({userId, linkedTags}){
    const {request, loading, response} = useLazyRequest('/user-tag/link', REQUEST_METHOD.PUT)
    const {response: tagResponse} = useRequest(`user-tag`)

    const [tags, setTags] = useState([]);
    const [selected, setSelected] = useState([]);

    const {setToRefetch} = useRefetchContext()
    const {enqueueSnackbar} = useSnackbar()

    const onAddTag = () => {
        if (selected && selected.length > 0)
            request({},  {userId, tags: selected.map(el => el.id)});
    }

    useEffect(() => {
        if (tagResponse && tagResponse.data) {
            const tmp = [];
            tagResponse.data.forEach(el => {
                tmp.push(el);
                el.tags.forEach(tg => {
                    if (!linkedTags || !linkedTags.find(tt => tt.id === tg.id))
                        tmp.push(tg)
                })
            });
            setTags(tmp)
        }
    }, [tagResponse])

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            setToRefetch([`/client/${userId}`]);
            enqueueSnackbar('Tag linked to user !', {variant: "success"});
            setSelected([])
        }
    }, [loading, response])

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelected(value)
    };

    return  <Box sx={{padding: 1}}>
        <FormControl required>
            <InputLabel id="add-tag-select-label">Link Tags</InputLabel>
            <Select
                sx={{minWidth: '200px'}}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={selected}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value.id} label={value.name} />
                        ))}
                    </Box>
                )}
            >
                {tags.map(tag => tag.tagGroupId ? <MenuItem key={tag.id} value={tag}>{tag.name}</MenuItem> : <ListSubheader>{tag.name}</ListSubheader>)}
            </Select>
            <Button sx={{marginTop: (theme) => theme.spacing(1)}} variant="contained" startIcon={<AddIcon/>} onClick={onAddTag}>Add</Button>
        </FormControl>
    </Box>

}