import React, {useEffect} from 'react'
import { Button, Card, CardActions, CardContent, LinearProgress, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment";
import {REQUEST_STATUS, useLazyRequest} from "../../hooks/request";
import {useSnackbar} from "notistack";
import {useRefetchContext} from "../../contexts/refetch.context";

const useStyle = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        flex: 1
    }
}))

export default function DistributorCrawlerCard({distributor, loading, isLoading}) {
    const classes = useStyle()

    const {enqueueSnackbar} = useSnackbar()
    const {setToRefetch} = useRefetchContext()

    const {request: fetchRequest, response: fetchResponse, loading: fetchLoading} = useLazyRequest(`/distributor/start-crawler/${distributor.name}`)
    const {request: updateRequest, response: updateResponse, loading: updateLoading} = useLazyRequest(`/distributor/start-update/${distributor.name}`)

    useEffect(() => {
        if (fetchResponse && fetchResponse.status === REQUEST_STATUS.SUCCESS) {
            setToRefetch(['/crawler-log'])
            enqueueSnackbar(`${fetchResponse.data.updated} offers updated, ${fetchResponse.data.created} offers created`, {variant: "success"})
        }
    }, [fetchResponse])

    useEffect(() => {
        if (updateResponse && updateResponse.status === REQUEST_STATUS.SUCCESS) {
            enqueueSnackbar(`${updateResponse.data.updated} offers updated`, {variant: "success"})
        }
    }, [updateResponse])

    useEffect(() => (isLoading(fetchLoading || updateLoading)), [fetchLoading, updateLoading])

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography variant={'h6'}>{distributor.name}</Typography>
                <Typography>Last run: <b>{distributor.log ? moment(distributor.log.lastRun).format('HH:mm DD/MM/YYYY') : '-'}</b></Typography>
            </CardContent>
            <CardActions>
                <Button size="small" disabled={loading || fetchLoading || updateLoading} onClick={() => updateRequest()}>Update</Button>
                <Button size="small" disabled={loading || fetchLoading || updateLoading} onClick={() => fetchRequest()}>Fetch</Button>
            </CardActions>
            {(fetchLoading || updateLoading) && <LinearProgress/>}

        </Card>
    )
}