import React, {useEffect, useState} from 'react'
import {Autocomplete, Box, Button, Container, IconButton, TextField, Typography} from "@mui/material";
import {REQUEST_METHOD, useLazyRequest, useRequest} from "../../hooks/request";
import {Add, Close} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import BookFormController from "../book/form/book.form.controller";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import UnmappedPriceTrendSolver from "../price/UnmappedPriceTrendSolver";
import GoBack from "../goBack";

function SelectOrCreateTag({type, sx, label, onChange}) {
    const [add, setAdd] = useState(false)
    const {response, loading} = useRequest('/tag', REQUEST_METHOD.GET, {type})

    if (!response || !response.data) return <Box/>
    return (
        <Box sx={{...sx, display: "flex"}}>

            {!add ? <Autocomplete
                    fullWidth
                    onChange={(event, newValue) => onChange(newValue)}
                    renderInput={(params) => <TextField variant="outlined" {...params} label={label}/>}
                    options={response.data.map(elem => elem.value)}/>
                :
                <TextField
                    fullWidth
                    variant="outlined"
                    onChange={({target: {value}}) => onChange(value)}
                    label={label}/>
            }

            <IconButton onClick={() => setAdd(!add)} >
                {!add ? <Add/> : <Close/>}
            </IconButton>
        </Box>
    )
}

function RenderTags({onChange}) {
    const [tags, setTags]    = useState({brand: null, ram: null, color: null, model: null, storage: null})

    useEffect(() => {
        onChange(tags)
    }, [tags])
    return (
        <Box sx={{display: "flex", flexDirection: "column" ,gap: 2}}>
            <Typography variant={"h5"}>Tags</Typography>
            <SelectOrCreateTag type={"brand"}   label={"Brand"} onChange={(brand) => setTags({...tags, brand})} />
            <SelectOrCreateTag type={"model"}   label={"Model"} onChange={(model) => setTags({...tags, model})}/>
            <Box sx={{display: "flex", gap: 2}}>
                <SelectOrCreateTag sx={{flex: 1}} type={"storage"} label={"Storage"} onChange={(storage) => setTags({...tags, storage})} />
                <SelectOrCreateTag sx={{flex: 1}} type={"ram"}     label={"Ram"} onChange={(ram) => setTags({...tags, ram})}/>
            </Box>
            <SelectOrCreateTag type={"color"}   label={"Color"} onChange={(color) => setTags({...tags, color})}/>
        </Box>
    )
}


function CreateProduct({onCreated}) {
    const [tags, setTags]   = useState(null)
    const [name, setName]   = useState("")
    const [mpn, setMpn]     = useState("")
    const {request, response, loading} = useLazyRequest(`product/create`, REQUEST_METHOD.POST)
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if (response && response.data) {
            enqueueSnackbar('Offer created', {variant: "success"})
            onCreated(response.data)
        }
    }, [response])

    useEffect(() => {
        if (tags && tags.brand) {
            setName(`${tags.brand ? `${tags.brand }` : ''}${tags.model ? ` ${tags.model},` : ','} ${tags.ram ? ` ${tags.ram}` : ''}${tags.storage ? ` ${tags.storage}` : ''}${tags.color ? ` ${tags.color}` : ''}`)
        }
    }, [tags])

    return (
        <React.Fragment>
            <RenderTags onChange={setTags}/>
            <Box sx={{display: "flex", flexDirection: "column" ,gap: 2}}>
                <Typography variant={"h5"}>Details</Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    onChange={({target: {value}}) => setMpn(value)}
                    label={"Mpn"}/>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={name}
                    onChange={({target: {value}}) => setName(value)}
                    label={"Name"}/>
            </Box>

            <LoadingButton
                onClick={() => !loading && request({}, {
                    mpn,
                    brand: tags.brand,
                    name: name,
                    tags: Object.keys(tags).map((type) => ({type, value: tags[type]}))
                })}
                loading={loading}
                variant="contained"
                sx={{textAlign: "right"}}>Submit</LoadingButton>
        </React.Fragment>)
}

export default function CreateProductPage() {
    const [created, setCreate]  = useState(null)
    const [offer, setOffer]     = useState(null)
    const [step, setStep]       = useState(0)

    const history = useHistory()

    return (
        <Container>
            <GoBack />
            <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                {step === 0 && <React.Fragment>
                    <Typography variant={"h4"}>Create product</Typography>
                    <CreateProduct onCreated={(value) => {
                        console.log(value)
                        setCreate(value)
                        setStep(1)
                    }} />
                </React.Fragment>}

                {step === 3 && !!created && <React.Fragment>
                    <UnmappedPriceTrendSolver product={created} onSubmit={() => {
                        setStep(1)
                    }} />
                </React.Fragment>}

                {step === 2 && !!created && <React.Fragment>
                    <BookFormController product={created} onSubmit={(offer) => {
                        setOffer(offer)
                        setStep(1)
                    }} />
                </React.Fragment>}

                {step === 1 && !!created &&
                    <Box sx={{display: "flex", flexDirection: "column" ,gap: 2}}>
                        <Typography variant={"h4"}>{created.name}</Typography>
                        <Box sx={{display: "flex", flexDirection: "row", gap: 2}}>
                            <Button onClick={() => {setStep(3)}} fullWidth variant="contained">Attach price trends</Button>
                            <Button onClick={() => {setStep(2)}} disabled={!!offer} fullWidth variant="contained">Attach offer</Button>
                            {/*<Button fullWidth variant="contained" disabled={!offer}>Attach Sell</Button>*/}
                        </Box>
                        <Button
                            onClick={() => history.push('/product')}
                            fullWidth
                            variant="outlined">Finish</Button>
                    </Box>
                }

            </Box>
        </Container>
    )
}