import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { ArrowRight, StarOutline, Star } from "@mui/icons-material";
import { REQUEST_METHOD, useLazyRequest } from "../hooks/request";
import BookController from "./book/book.controller";
import { useHistory, useLocation } from "react-router-dom";
import { useModalsContext } from "../contexts/modals.context";

function useIsWidthUp(breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
}

export default function UserCard({ user, onClick, onChange, type = "BUYER" }) {
  const { request: requestFav, response: responseFav } = useLazyRequest(
    "/user/favorite",
    REQUEST_METHOD.POST
  );
  const history = useHistory();
  const { setOpenRow } = useModalsContext();
  const isWidthUp = useIsWidthUp("md");
  const [isFav, setIsFav] = useState(undefined);

  useEffect(() => {
    // if (onChange) {
    //   onChange()
    // }
    if (responseFav) {
      setIsFav(!isFav);
    }
  }, [responseFav]);

  useEffect(() => {
    if (isFav === undefined && user) setIsFav(user.isFavorite);
  }, [user]);

  if (!user) return <Box />;

  const changeFav = (event, userId, fav) => {
    event.stopPropagation();
    if (userId) {
      requestFav({}, { userId, favorite: !!fav });
    }
  };

  return (
    <Paper sx={{ my: 2, p: 2 }} key={user.id}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          onClick={onClick && onClick}
          sx={{ flex: 1, ":hover": { cursor: "pointer" } }}
        >
          <Typography variant="h6">{user.name}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            gap: 2,
          }}
        >
          {user.phoneNumber && (
            <Link href={`tel:${user.phoneNumber}`} underline="hover">
              {isWidthUp ? user.phoneNumber : <Button>Call</Button>}
            </Link>
          )}
          <Link href={`mailto:${user.email}`} underline="hover">
            {isWidthUp ? user.email : <Button>Email</Button>}
          </Link>
          <IconButton onClick={(event) => changeFav(event, user.id, !isFav)}>
            {!isFav ? <StarOutline /> : <Star />}
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ overflow: "auto", display: "flex" }}>
        {user.userTags.map((tag) => (
          <Chip
            key={tag.id}
            color="primary"
            label={tag.name}
            sx={{ margin: (theme) => theme.spacing(1) }}
          />
        ))}
      </Box>
      <Box sx={{ my: 4 }}>
        <BookController
          onClick={onClick && onClick}
          showProduct
          filters={{
            productId: undefined,
            user: user.id,
            take: 5,
            monotypes: ["ASK", "WTB", "BID", "WTS"],
            states: ["OPEN", "CLOSE"],
          }}
          showState
        />
      </Box>

      <Stack direction="row" sx={{ flexWrap: "wrap", gap: 1, mt: 4 }}>
        <Button
          sx={{ flex: 1 }}
          size="small"
          variant="contained"
          color={"warning"}
          onClick={() =>
            setOpenRow({
              type: "wts",
              open: true,
              sellerId: user.id,
              productId: undefined,
            })
          }
        >
          WTS
        </Button>
        <Button
          sx={{ flex: 1 }}
          size="small"
          variant="contained"
          color={"error"}
          onClick={() =>
            setOpenRow({
              type: "bid",
              open: true,
              sellerId: user.id,
              productId: undefined,
            })
          }
        >
          BID
        </Button>
        <Button
          sx={{ flex: 1 }}
          size="small"
          variant="contained"
          color={"success"}
          onClick={() =>
            setOpenRow({
              type: "ask",
              open: true,
              buyerId: user.id,
              productId: undefined,
            })
          }
        >
          ASK
        </Button>
        <Button
          sx={{ flex: 1 }}
          size="small"
          variant="contained"
          color={"primary"}
          onClick={() =>
            setOpenRow({
              type: "wtb",
              open: true,
              buyerId: user.id,
              productId: undefined,
            })
          }
        >
          WTB
        </Button>
      </Stack>
    </Paper>
  );
}
