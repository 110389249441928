import React, { useEffect, useState } from "react";
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Legend,
} from "recharts";
import { initPriceTrends } from "../../utils/priceTrend";
import { Box, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useLazyRequest } from "../../hooks/request";

export default React.memo(function PriceTrendSummary({
  productId,
  formula = 0,
}) {
  const { request, response, loading } = useLazyRequest(
    `/product/price-trends/${productId}`
  );
  const theme = useTheme();
  const [series, setSeries] = useState(undefined);

  function getGraphColor(index) {
    switch (index) {
      case 0:
        return theme.palette.info;
      case 1:
        return theme.palette.secondary;
      default:
        return theme.palette.warning;
    }
    return;
  }

  useEffect(() => {
    if (!series && !loading && !response) {
      request();
    }
  }, [productId]);

  useEffect(() => {
    if (response && response.data) {
      setSeries(initPriceTrends(response.data, formula));
    }
  }, [response, formula]);

  if (!series) return <Box />;

  return (
    <ResponsiveContainer width={"100%"} height={300}>
      <ComposedChart
        // data={series.map(el => ({time: el.x.getTime(), price: el.y}))}
        data={series.series}
      >
        <defs>
          <linearGradient id="0" x1="0" y1="0" x2="0" y2="1">
            <stop stopColor={theme.palette.info.dark} stopOpacity={0.3} />
            <stop
              offset="95%"
              stopColor={theme.palette.info.dark}
              stopOpacity={0}
            />
          </linearGradient>
          <linearGradient id="1" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={theme.palette.secondary.dark}
              stopOpacity={0.3}
            />
            <stop
              offset="95%"
              stopColor={theme.palette.secondary.dark}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <CartesianGrid
          strokeDasharray="3 3"
          stroke="rgba(255, 255, 255, .15)"
        />
        <XAxis
          dataKey="time"
          domain={["auto", "auto"]}
          name="Time"
          tickFormatter={(unixTime) => moment(unixTime).format("DD/MM")}
          scale="time"
          // type = 'number'
        />
        <YAxis domain={["auto", "auto"]} orientation="right" />
        <Tooltip
          content={({ active, payload, label }) => (
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                p: 1,
                borderRadius: 2,
              }}
            >
              {payload.map((pp) => (
                <Typography>{`${pp?.value}`}€</Typography>
              ))}
              <Typography>{`${moment(label).format("DD/MM/YYYY")}`}</Typography>
            </Box>
          )}
        />
        {/*{book[0] && <ReferenceLine y={book[0].price} stroke={theme.palette.warning.main}/>}*/}
        {series.keys.map((name, index) => (
          <Area
            key={name}
            connectNulls={true}
            type="linear"
            dataKey={name}
            strokeWidth={2}
            stroke={getGraphColor(index).main}
            fillOpacity={1}
            fill={`url(#${index})`}
            dot={{ stroke: getGraphColor(index).main, strokeWidth: 1.2 }}
          />
        ))}
        {series.axKeys.map((name, index) => (
          <Area
            key={name}
            type="linear"
            dataKey={name}
            stroke={theme.palette.text.primary}
            fillOpacity={0}
          />
        ))}
        {/*<Area type="linear" dataKey={"Samsung Galaxy A52s 5G A528 Dual Sim 6GB RAM 128GB Black"} strokeWidth={2} stroke={theme.palette.primary.main} fillOpacity={1} fill="url(#colorPrice)" dot={{ stroke: theme.palette.primary.main, strokeWidth: 1.2}}/>*/}
        <Legend verticalAlign="top" height={36} />
      </ComposedChart>
    </ResponsiveContainer>
  );
});
