import React, { useState } from 'react'
import { IconButton, InputBase, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Close, Search } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
	searchPaper: {
		paddingLeft: theme.spacing(1),
		display: "flex",
		justifyContent: 'space-between',
		height: "auto",
		maxHeight: "60px"
	}
}))

export default function SearchInput({ value, onSearch, style, submitOnEnter = false, focus = false }) {
	const [searchInput, setSearchInput] = useState(value || '')
	const [searched, setSearched] = useState(!!value)

	const classes = useStyles()

	const search = () => {
		if (searched || searchInput.length === 0) {
			setSearchInput('')
			onSearch(null)
			setSearched(false)
		} else if (searchInput.length >= 2) {
			setSearched(true)
			onSearch(searchInput)
		}
	}

	return (
		<Paper className={classes.searchPaper} sx={style}>
			<InputBase
				inputRef={input => {
					if (input && focus) {
						input.focus()
					}
				}}
				style={{ flex: 1 }}
				placeholder={"Product name"}
				inputProps={{ 'aria-label': 'search product' }}
				value={searchInput}
				onKeyPress={(event) => {
					if (submitOnEnter && event.key === "Enter") search()
				}}
				onChange={({ target: { value } }) => {
					setSearched(false)
					setSearchInput(value)
				}}
			/>
			<IconButton aria-label="search" onClick={search} size="large">
				{!searched ? <Search /> : <Close />}
			</IconButton>
		</Paper>
	);
}