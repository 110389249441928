import React from 'react'
import {Route, Redirect} from "react-router-dom";
import {useAuth} from "../contexts/auth.context";
import AppLayout from "../appLayout";

export default function PrivateRoute({children, ...rest}) {
    const {user} = useAuth()

    return (
        <Route
            {...rest}
            render={({ location }) =>
                user ? (
                    <AppLayout>
                        {children}
                    </AppLayout>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/auth/signIn",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    )
}