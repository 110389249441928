import React from "react";
import {Box, Button} from "@mui/material";
import moment from "moment";
import {useModalsContext} from "../../contexts/modals.context";

export default function ProductSummaryButtons({product, onCreateBid, onCustomWts, onCreateWtb, onCreatAsk}) {
    const {setOpenTP, setOpenRow} = useModalsContext();

    return (
        <React.Fragment>
            <Box sx={{display: "flex", flexWrap: "wrap", gap: 1, mt: 2}}>
                <Button onClick={(event) => {
                    event.stopPropagation()
                    // onCustomWts(product.id)
                    setOpenRow({open: true, type: 'wts', productId: product.id})
                }} size="small" variant="contained" sx={{flex: 1}} color={(product.book && product.book.filter(el => el.type === 'SELL' && el.from === 'DISTRIBUTOR').length) ? 'warning'  : 'neutral'}>wts{(product.book && product.book.filter(el => el.type === 'SELL' && el.from === 'DISTRIBUTOR').length) ? moment().diff(product.book.filter(el => el.type === 'SELL' && el.from === 'DISTRIBUTOR')[0].updatedAt, 'days') > 0 ? ` ${moment().diff(product.book.filter(el => el.type === 'SELL' && el.from === 'DISTRIBUTOR')[0].updatedAt, 'days')}D` : '' : ''}</Button>

                <Button onClick={(event) => {
                    event.stopPropagation()
                    //injectSelf(product.id)
                    // onCreateBid(product.id)
                    setOpenRow({open: true, type: 'bid', productId: product.id})

                }} size="small" variant="contained" color={(product.book && product.book.filter(el => el.type === 'BUY' && el.from === 'INTERNAL').length) ? 'error'  : 'neutral'} sx={{flex: 1}}
                        >Bid{(product.book && product.book.filter(el => el.type === 'BUY' && el.from === 'INTERNAL').length) ? moment().diff(product.book.filter(el => el.type === 'BUY' && el.from === 'INTERNAL')[0].updatedAt, 'days') > 0 ? ` ${moment().diff(product.book.filter(el => el.type === 'BUY' && el.from === 'INTERNAL')[0].updatedAt, 'days')}D` : '' : ''}</Button>

                <Button onClick={(event) => {
                    event.stopPropagation()
                    // onCreatAsk(product.id)
                    setOpenRow({open: true, type: 'ask', productId: product.id})

                }} size="small" variant="contained"
                        color={(product.book && product.book.filter(el => el.type === 'SELL' && el.from === 'INTERNAL').length) ? 'success'  : 'neutral'}
                        sx={{flex: 1}} >Ask{(product.book && product.book.filter(el => el.type === 'SELL' && el.from === 'INTERNAL').length) ? moment().diff(product.book.filter(el => el.type === 'SELL' && el.from === 'INTERNAL')[0].updatedAt, 'days') > 0 ? ` ${moment().diff(product.book.filter(el => el.type === 'SELL' && el.from === 'INTERNAL')[0].updatedAt, 'days')}D` : '' : ''}</Button>


                <Button onClick={(event) => {
                    event.stopPropagation()
                    //injectSelf(product.id)
                    // onCreateWtb(product.id)
                    setOpenRow({open: true, type: 'wtb', productId: product.id})

                }} size="small" variant="contained" sx={{flex: 1}} color={(product.book && product.book.filter(el => el.type === 'BUY' && el.from === 'CUSTOMER').length) ? 'primary'  : 'neutral'}>wtb{(product.book && product.book.filter(el => el.type === 'BUY' && el.from === 'CUSTOMER').length) ? moment().diff(product.book.filter(el => el.type === 'BUY' && el.from === 'CUSTOMER')[0].updatedAt, 'days') > 0 ? ` ${moment().diff(product.book.filter(el => el.type === 'BUY' && el.from === 'CUSTOMER')[0].updatedAt, 'days')}D` : '' : ''}</Button>

                {/* <Button onClick={(event) => {
                    event.stopPropagation()
                    setOpenTP({open: true, productId: product.id})
                }} size="small" variant="outlined" sx={{flex: 1, display: {xs: 'none', md: 'flex'}}} color="secondary">TP</Button> */}
            </Box>
        </React.Fragment>
    )
}