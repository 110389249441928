import React from 'react'
import {Box, Typography} from "@mui/material";
import moment from "moment";

export default function BuyHistory({buy: row}) {

    let events = row.history ? row.history : []

    events.sort((a, b) => {
        a = new Date(a.date)
        b = new Date(b.date)
        return b - a
    })

    const Item = ({history, index}) => {
        return (
            <Box key={index}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Box sx={{width: 10, height: 10, borderRadius: 100, backgroundColor: 'divider'}} />
                    <Typography sx={{pl: 2, color: 'text.secondary'}}>{moment(history.date).format('MMM DD, YYYY, HH:mm')}</Typography>
                </Box>
                <Box sx={{py: 2, pl: 2, marginLeft: '4px', borderLeft: 'solid 2px', borderColor: 'divider'}}>
                    {`${history.type} ${history.value ? `,${history.value}` : ''}`}
                  {history.type === 'REJECTED'&& row.meta?.proposedPrice && <Typography sx={{marginLeft: '4px'}}>
                    {`${row.meta?.proposedPrice}€`}
                  </Typography>}
                  {history.type === 'REJECTED'&& row.meta?.proposedStock && <Typography sx={{marginLeft: '4px'}}>
                    {`${row.meta?.proposedStock}pcs`}
                  </Typography>}
                </Box>

            </Box>
        )
    }

    return (
        <Box sx={{my: 2}}>
            <Typography sx={{my: 1}} variant="h6">Timeline</Typography>
            <Box>
                {events && events.map((elem, index) => <Item history={elem} index={index}/>)}
            </Box>
        </Box>
    )
}