import React, {useCallback, useEffect, useState} from 'react'
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Paper,
	Typography,
} from "@mui/material";
import {Box} from "@mui/system";
import ProductSearch from "../product/product.search";
import NumTextField from "../NumTextField";
import {LoadingButton} from "@mui/lab";
import {REQUEST_METHOD, useLazyRequest} from "../../hooks/request";
import {useRefetchContext} from "../../contexts/refetch.context";
import {Add, Delete} from "@mui/icons-material";
import UsersSelector from "../clients/users.selector";
import moment from "moment";

const CreateTargetPrice = React.memo(function ({tp, onChange, onDelete}) {
	const onProductChange = (productId) => onChange({...tp, productId})
	const onStockChange = (stock) => onChange({...tp, stock})
	const onExpireAtChange = (expireAt) => onChange({...tp, expireAt})

	return (
		<Paper sx={{p: 2, my: 1}}>
			<Box>
				<ProductSearch sx={{mb: 2}} defaultSelected={tp.productId} onSelect={onProductChange} />
				<NumTextField label={'Stock'} value={tp.stock} onChange={onStockChange} />
				<NumTextField sx={{ml: 1}} label={'Expire (days)'} value={tp.expireAt} onChange={onExpireAtChange} />
			</Box>

			<Divider sx={{my: 2}} />
			<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
				<Button variant={'contained'} color={'error'} onClick={onDelete}><Delete /> </Button>
			</Box>
		</Paper>
	)
})

function TargetPriceMultiCreateCont({tps, setTps, setUsers, buyerId}) {

	const onChange = useCallback((index) => (value) => {
		setTps(prev => {
			const res = prev.map((_, _index) => {
				if (index !== _index) return _
				return value
			})
			if ((value.productId !== '' && value.productId !== null && value.productId !== 0) &&
				res.every(elem =>  elem.productId !== 0 && elem.productId !== '' && elem.productId !== null)) {
				res.push({productId: null, stock: 0, expireAt: undefined})
			}

			return res
		})
	}, [])

	const onDelete = useCallback((index) => () => {
		setTps(prev => prev.filter((_, _index) => index !== _index))
	}, [])

	const onCreate = () => setTps([...tps, {productId: null, stock: 0, expireAt: undefined}])

	return (
		<Container sx={{overflowY: 'auto', display: 'flex', flexDirection: 'column'}}>
			{
				tps.map((elem, index) =>
					<CreateTargetPrice
						tp={elem}
						onChange={onChange(index)}
						onDelete={onDelete(index)}
						key={`MultiCreateTp_${index}`}/>)
			}
			<Button variant='outlined' sx={{flex: 1}} onClick={onCreate}><Add /></Button>
			<Paper sx={{p: 2, my: 1}}>
				<UsersSelector onSelect={setUsers} leads={false} defaultIncludedIds={buyerId ? [buyerId] : []}/>
			</Paper>
		</Container>
	)
}

export default function TargetPriceMultiCreateModal({data, onClose}) {
	const {response, loading, request} = useLazyRequest(`/target-price`, REQUEST_METHOD.POST)
	const {setToRefetch} = useRefetchContext()
	const [tps, setTps] = useState([])
	const [users, setUsers] = useState(undefined)
	const _onSubmit = () => request({},
		tps
			.filter(elem => elem.productId !== 0 && elem.productId !== null && elem.productId !== '')
			.map(el =>({...el, expireAt: el.expireAt ? moment().add(el.expireAt, 'd') : undefined , scopeIds: users.tags, includedUserIds: users.include, excludedUserIds: users.exclude })))

	useEffect(() => {
		setTps(
			[
				{productId: data?.productId ? data.productId : null, stock: 0, expireAt: undefined}
			]
		)
	}, [data])

	useEffect(() => {
		if (response) {
			setTps([
				{productId: data?.productId ? data.productId : null, stock: 0, expireAt: undefined}
			])
			setToRefetch(['/target-price/list'])
			onClose()
		}
	}, [response])

	if (!data) return <Box/>


	return (
		<React.Fragment>
			<Dialog fullScreen={true} open={data.open} onClose={onClose}>

				<DialogTitle>
					<Container>
						<Typography variant={'h5'}>Request Target Prices</Typography>
					</Container>
				</DialogTitle>

				<DialogContent dividers>
					<TargetPriceMultiCreateCont setTps={setTps} tps={tps} setUsers={setUsers} buyerId={data?.buyerId}/>
				</DialogContent>

				<DialogActions>
					<Container sx={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button onClick={onClose}>Cancel</Button>
						<LoadingButton disabled={!users} loading={loading} variant={'contained'} onClick={_onSubmit}>Submit</LoadingButton>
					</Container>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}