import React, {useEffect, useState} from 'react'
import {Box} from "@mui/system";
import {REQUEST_METHOD, useLazyRequest} from "../../../hooks/request";
import {LoadingButton} from "@mui/lab";
import {useSnackbar} from "notistack";
import RejectAction from "./rejectAction";

export default function WtbActions({row, onSubmit}) {
    const [reject, setReject]   = useState(false)
    const {enqueueSnackbar} = useSnackbar()
    const {response, loading, request} = useLazyRequest(`/book/wtb/${row.id}`, REQUEST_METHOD.POST)

    useEffect(() => {
        if (response && response.status !== 'error') {
            enqueueSnackbar('Offer created', {variant: "success"})
            onSubmit(response.data)
        }
    }, [response])

    const onCancel = () => {
        request({}, {stateExtra: 'CANCELED'})
    }

    const onAccept = () => {
        request({}, {stateExtra: 'ACCEPTED'})
    }

    const onForceAccept = () => {
        request({}, {stateExtra: 'ACCEPTED', forceAccepted: true})
    }

    const onReject = (meta) => {
        request({}, {stateExtra: 'REJECTED', meta})
    }

    const onPreReject = () => {
        setReject(true)
    }

    if (reject) {
        return <RejectAction row={row} loading={loading} setReject={setReject} onSubmit={onReject} />
    }
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Box/>
            <Box>
                <LoadingButton loading={loading} onClick={onCancel} disabled={row.state === 'CLOSE'}>Cancel</LoadingButton>
                <LoadingButton loading={loading} onClick={onPreReject} disabled={row.state === 'CLOSE' || row.stateExtra === 'REJECTED'}>Counter</LoadingButton>
                <LoadingButton loading={loading} onClick={onAccept} disabled={row.state === 'CLOSE' || row.stateExtra === 'REJECTED'
                    // || row.attachedTo[0]?.attachedTo[0]?.stateExtra !== 'ACCEPTED'
                }>Accept</LoadingButton>
                {/*<LoadingButton loading={loading} onClick={onForceAccept} disabled={row.state === 'CLOSE' || row.stateExtra === 'REJECTED'}>Force Accept</LoadingButton>*/}

            </Box>
        </Box>
    )
}