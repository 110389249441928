import React, { useEffect, useState } from 'react'
import { REQUEST_METHOD, REQUEST_STATUS, useLazyRequest, useRequest } from "../../hooks/request";
import {
    Box,
    LinearProgress,
} from "@mui/material";

import Products from "./products";
import ProductsSortAndFilter from "./productsSortAndFilter";
import ProductSummaryButtons from "./productSummaryButtons";
import { useSettingsContext } from '../../contexts/settings.context';

export default React.memo(function ProductsController({
    onMapUnmappedProduct,
    onSelectProduct,
    onCreateBid, onCustomWts, onCreateWtb, onCreatAsk
}) {
    const { marketFilters } = useSettingsContext()
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [products, setProducts] = useState(null)
    const { request, loading, response } = useLazyRequest('/product', REQUEST_METHOD.GET,
        {
            name: marketFilters.productName, orderBy: marketFilters.orderBy,
            watched: marketFilters.watched, rfq: marketFilters.rfq,
            category: marketFilters.category, types: marketFilters.types.join(',')
        })
    const { request: requestWatch } = useLazyRequest('/product/add-watchlist', REQUEST_METHOD.POST);
    const { request: requestAddRFQ } = useLazyRequest('/rfq', REQUEST_METHOD.POST);
    const { request: requestDelRFQ } = useLazyRequest('/rfq/delete', REQUEST_METHOD.DELETE);


    const onSelect = (productId) => {
        let res = [...selectedProduct]
        let found = selectedProduct && selectedProduct.find(elem => elem === productId)

        if (found) res = ([...res].filter(elem => elem !== productId))
        else res = ([...res, productId])

        setSelectedProduct(res)
    }

    const myFetch = () => {
        if (marketFilters.orderBy) {
            request({
                name: marketFilters.productName, orderBy: marketFilters.orderBy,
                watched: marketFilters.watched, rfq: marketFilters.rfq,
                category: marketFilters.category, types: marketFilters.types.join(',')
            }, {})
        }
    }

    useEffect(() => {
        if (marketFilters.orderBy !== undefined && !loading) {
            myFetch()
        }
    }, [marketFilters])

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            setProducts(response.data)
        }
    }, [response])

    const handleRequestWatch = (params, body) => {
        requestWatch(params, body);
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            const tmp = products;
            tmp.forEach(pdt => {
                if (pdt.id === body.productId) {
                    pdt.isWatched = body.watched;
                }
            })
            setProducts(tmp);
        }
    }

    const handleRequestAddRFQ = (params, body) => {
        requestAddRFQ(params, body);
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            const tmp = products;
            tmp.forEach(pdt => {
                if (pdt.id === body[0]?.productId) {
                    pdt.rfqs = response.data
                    // pdt.isWatched = body.watched;
                }
            })
            setProducts(tmp);
        }
    }

    const handleRequestDelRFQ = (params, body) => {
        requestDelRFQ(params, body);
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            const tmp = products;
            tmp.forEach(pdt => {
                if (pdt.id === params.productId) {
                    pdt.rfqs = []
                    // pdt.isWatched = body.watched;
                }
            })
            setProducts(tmp);
        }
    }

    const drawButtons = (product) => {

        return <ProductSummaryButtons
            product={product}
            onCreateBid={onCreateBid}
            onCustomWts={onCustomWts}
            onCreateWtb={onCreateWtb}
            onCreatAsk={onCreatAsk}
        />
    }

    return (
        <Box width={1} sx={{mb: 10}}>
            <ProductsSortAndFilter />
            {loading && <LinearProgress />}
            {products ? <Products
                onSelectProduct={onSelectProduct}
                products={[...products]}
                onCustomOffer={(product) => { }}//TODO
                onMapUnmappedProduct={onMapUnmappedProduct}
                requestWatch={handleRequestWatch}
                addRFQ={handleRequestAddRFQ}
                deleteRFQ={handleRequestDelRFQ}
                selected={selectedProduct}
                onSelectedChange={onSelect}
                extra={drawButtons}
            /> : undefined}
        </Box>
    );
})