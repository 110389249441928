import React from "react";
import {
    Backdrop,
    Container,
    Fade,
    Modal,
    Paper,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import UnmappedPriceTrendSolver from '../components/price/UnmappedPriceTrendSolver'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        padding: theme.spacing(2)
    }
}))


export default function UnmappedPriceTrendSolverModal ({product, onClose}) {
    const classes = useStyles()

    return (
        <Modal
            sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            open={!!product}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    onClose()
                }
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={!!product} style={{zIndex: 100}}>
                <Container maxWidth='md'>
                    <Paper className={classes.modalPaper}>
                        <UnmappedPriceTrendSolver onSubmit={onClose} product={product} />
                    </Paper>
                </Container>
            </Fade>
        </Modal>
    )
}