import React, { useEffect, useState } from 'react'
import {
	Backdrop,
	Box,
	Button,
	Chip,
	CircularProgress,
	Container,
	IconButton,
	Paper,
	TextField,
	Typography
} from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { REQUEST_METHOD, useLazyRequest, useRequest } from "../hooks/request";
import LinkTagToUser from "../components/clients/linkTagToUser";
import { Check, Edit, StarOutline, Star } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import BookWithFilters from "../components/book/bookWithFilters";
import ActionsBar from "../components/actionsBar";
import { GroupedTags } from '../components/tag/tagsUtils';

export default function ClientPage() {
	const { id } = useParams()
	const history = useHistory()
	const { response, loading, refetch } = useRequest(`/client/${id}`)
	const { request, loading: editLoading, response: editResponse } = useLazyRequest(`/client/${id}`, REQUEST_METHOD.PUT)
	const { request: deleteRequest, loading: deleteLoading, response: deleteResponse } = useLazyRequest(`/client/${id}`, REQUEST_METHOD.DELETE)
	const { request: unlinkTagRequest, loading: unlinkTagLoading, response: unlinkTagResponse } = useLazyRequest(`/user-tag/unlink`, REQUEST_METHOD.POST)
	const { request: signInLinkRequest, loading: signInLinkLoading, response: signInLinkResponse } = useLazyRequest(`/client/${id}/resend-signin-link`, REQUEST_METHOD.GET)
	const { enqueueSnackbar } = useSnackbar()
	const { request: requestFav, response: responseFav } = useLazyRequest('/user/favorite', REQUEST_METHOD.POST);

	const [user, setUser] = useState(undefined);
	const [editMode, setEditMode] = useState(false);
	const [password, setPassword] = useState(undefined)
	const [phoneNumber, setPhoneNumber] = useState();
	const [email, setEmail] = useState();
	const [name, setName] = useState();

	useEffect(() => {
		if (deleteResponse) {
			history.goBack();
		}
	}, [deleteResponse])


	useEffect(() => {
		if (response && response.data) {
			setUser(response.data);
			if (response.data.phoneNumber) setPhoneNumber(response.data.phoneNumber);
		}
	}, [response])

	useEffect(() => {
		refetch()
	}, [unlinkTagResponse, editResponse, responseFav])

	useEffect(() => {
		if (signInLinkResponse) {
			if (signInLinkResponse.data)
				enqueueSnackbar('User Link sent.', { variant: "success" });
			else {
				enqueueSnackbar('Link not sent', { variant: "error" });
			}
		}
	}, [signInLinkResponse])

	const handleEditMode = () => {
		if (editMode) {
			request({}, { email, name, phoneNumber, password })
			setEditMode(false);
		} else {
			setEditMode(true)
		}
	}

	const unlinkTag = (tagId) => {
		if (tagId && id) {
			unlinkTagRequest({}, { userId: id, tagId: tagId });
		}
	}

	const changeFav = (event, userId, fav) => {
		event.stopPropagation()
		if (userId) {
			requestFav({}, { userId, favorite: !!fav })
		}
	}

	return (
		<Container sx={{ py: 2 }}>
			{history.goBack && <Button onClick={() => history.goBack()} sx={{ m: 1 }} variant='text' color='inherit'>Back</Button>}
			{loading && <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>}


			{user && <React.Fragment>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button size='small' disabled={loading || editLoading} variant="outlined" startIcon={!editMode ? <Edit /> : <Check />} onClick={() => handleEditMode()}>{editMode ? 'Save' : 'Edit'}</Button>
					<Button sx={{ ml: 1 }} size='small' disabled={loading || editLoading || signInLinkLoading} variant="outlined" onClick={() => signInLinkRequest()}>Resend Link</Button>
					<Button sx={{ ml: 1 }} size='small' disabled={loading || editLoading} variant="outlined" onClick={() => request({}, { disabled: !user.disabled })}>{user.disabled ? 'Unblock' : 'Block'}</Button>
					{user?.disabled && <Button sx={{ ml: 1 }} size='small' disabled={loading || editLoading || deleteLoading} variant="outlined" onClick={() => deleteRequest({}, {})}>Delete</Button>}
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					{editMode ?
						<TextField sx={{ margin: 1 }} id="name" label="Name" variant="outlined" value={name ? name : user.name} onChange={event => setName(event.target.value)} />
						: <Typography sx={{ textAlign: 'left', my: 2, fontWeight: 'bold' }} variant="h4">{user.name}</Typography>}
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<IconButton onClick={(event) => changeFav(event, user.id, !user.isFavorite)}>
								{!user.isFavorite ? <StarOutline /> : <Star />}
							</IconButton>
					</Box>

				</Box>


				<Paper sx={{ marginTop: 3, padding: 2 }}>
					{editMode ?
						<TextField sx={{ margin: 1 }} id="email" label="Email" variant="outlined" value={email ? email : user.email} onChange={event => setEmail(event.target.value)} />
						: <Typography sx={{ textAlign: 'left' }}>{user.email}</Typography>}
					{editMode ?
						<TextField sx={{ margin: 1 }} id="phone-number" label="Phone Number" variant="outlined" value={phoneNumber} onChange={event => setPhoneNumber(event.target.value)} />
						: <Typography sx={{ textAlign: 'left' }}>Tel. {user.phoneNumber} {!user.phoneNumber && '-'}</Typography>}
					{/*{editMode && <TextField sx={{margin: 1}} id="password" label="New Password" variant="outlined" value={password} onChange={event => setPassword(event.target.value)} />}*/}
				</Paper>

				<Box sx={{ my: 2 }}>
					<Paper sx={{ p: 2 }}>
						<ActionsBar buyerId={parseInt(id)} />
						{/*<ActionsButton onWts={() => setWts(true)} />*/}
						{/*<MultiCreateDialog onClose={() => setWts(false)} open={wts} userId={parseInt(id)} />*/}
					</Paper>
				</Box>

				 {user.userTags?.length > 0 && <Paper sx={{ marginTop: 3, padding: 2 }}>
					<GroupedTags tags={user.userTags} deleteMode={editMode} onDelete={unlinkTag} />
				</Paper>}

				{editMode && <Paper sx={{ marginTop: 3, padding: 2 }}>
					<LinkTagToUser userId={id} linkedTags={user.userTags} />
				</Paper>}

				<Box sx={{ mt: 2 }}>
					<BookWithFilters userId={id} />
				</Box>
			</React.Fragment>}
		</Container>
	)
}