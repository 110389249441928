import React, {useState} from "react";
import {useRequest} from "../../../hooks/request";
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    Paper, Radio,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import Loading from "../../loading";
import UserSearch from "../../clients/user.search";
import CreateRowInputs from "./createRowInputs";
import {formatRow} from "../../../utils/book.utils";
import StockTypo from "../row/stockTypo";

const getAttachTabLabel = (type) => {
    switch (type.toUpperCase()) {
        case 'WTB':
            return 'Select ASK'
        case 'ASK':
            return 'Select BID'
        case 'BID':
            return 'Select WTS'
    }
}

function RenderSelectableRows({rows, selected, onSelect}) {
    const _rows = rows.map(formatRow)

    const renderHeader = (withRemaining = false) => {
        return (
            <Grid container sx={{alignItems: 'center'}}  columns={10}>
                <Grid Item xs={1} />
                <Grid Item xs={3} sx={{textAlign: 'center'}}>
                    <Typography variant="caption" >Name</Typography>
                </Grid>
                <Grid Item xs={3} sx={{textAlign: 'right'}}>
                    <Typography variant="caption">Price</Typography>
                </Grid>
                <Grid Item xs={3} sx={{textAlign: 'right'}}>
                    <Typography variant="caption">Stock</Typography>
                </Grid>
            </Grid>
        )
    }

    const renderRow = (_row) => {
        return (
            <Grid container sx={{alignItems: 'center', borderBottom: `solid 1px`, borderBottomColor: 'divider'}}  columns={10} key={_row.id} onClick={() => onSelect(_row)}>
                <Grid Item xs={1}>
                    <Checkbox checked={selected === _row.id}/>
                </Grid>
                <Grid Item xs={3} sx={{textAlign: 'center'}}>
                    <Typography>{_row.user.name}</Typography>
                </Grid>
                <Grid Item xs={3} sx={{textAlign: 'right'}}>
                    <Typography>{_row.price.toFixed(2)}</Typography>
                </Grid>
                <Grid Item xs={3} sx={{textAlign: 'right'}}>
                    <Typography>{_row.stock || '-'}</Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <React.Fragment>
            {renderHeader(_rows[0]?.stockRemaining !== undefined)}
            {_rows.map(renderRow)}
        </React.Fragment>
    )
}

function RenderCreateBid ({row, onRowChange, display}) {

    if (display && !row.chainHelperCreateWts) {
        onRowChange({
            ...row,
            chainHelperCreateWts: {
                type: 'WTS',
                price: 0,
                stock: 0,
                meta: {estimatedTimeOfArrival: 0},
                userId: null
            }
        })
    }

    const onChanHelperChange = (data) => {
        onRowChange({
            ...row,
            chainHelperWtsSelected: null,
            attachedTo: null,
            chainHelperCreateWts: {
                ...row.chainHelperCreateWts, ...data
            }
        })
    }

    return (
        <Box sx={{marginTop: 2}}>
            {row.chainHelperCreateWts && <CreateRowInputs row={row.chainHelperCreateWts} onRowChange={onChanHelperChange} />}
            <UserSearch
                sx={{marginTop: 2}}
                type={['DISTRIBUTOR']}
                label={'Distributor'}
                onSelect={(user) => onRowChange({...row, chainHelperCreateWts: {...row.chainHelperCreateWts, userId: user?.id}, chainHelperWtsSelected: null, attachedTo: null})}
            />
        </Box>
    )
}

function RenderCreateOther({row, onRowChange, display}) {
    const [noWts, setNoWts] = useState(false)
    const {response, loading} = useRequest(`/book/possible-relations/bid/${row.productId}`)

    const onSelect = (attachedTo) => {
        if (row.attachedTo === attachedTo?.id) onRowChange({...row, chainHelperCreateWts: null, chainHelperWtsSelected: null, attachedTo: null})
        else onRowChange({...row, chainHelperCreateWts: null, chainHelperWtsSelected: attachedTo.id, attachedTo: null})
    }

    if (loading || !response) return <Loading />
    if (response.status === 'error') return <Typography color='palette.error.main'>{response.message ? response.message : 'Something goes wrong'}</Typography>


    return (
        <React.Fragment>
            {/*<FormControlLabel control={<Radio checked={!noWts} onClick={() => setNoWts(false)} />} label="Select WTS" />*/}
            {/*<FormControlLabel control={<Radio checked={noWts} onClick={() => setNoWts(true)} />} label="Create WTS" />*/}

            <Box sx={{display: noWts ? 'block' : 'none'}}>
                <RenderCreateBid display={display} row={row} onRowChange={onRowChange} />
            </Box>
            <Box sx={{display: !noWts ? 'block' : 'none'}}>
                <RenderSelectableRows rows={response?.data} selected={row.chainHelperWtsSelected} onSelect={onSelect} />
            </Box>
        </React.Fragment>
    )
}

export default React.memo(function SelectRelation ({row, onRowChange}) {
    const {response, loading} = useRequest(`/book/possible-relations/${row.type}/${row.productId}`)
    const [tab, setTab] = useState('attach')

    if (row.type === 'WTS') return null

    const onSelect = (attachedTo) => {
        if (row.attachedTo === attachedTo?.id) onRowChange({...row, attachedTo: null, chainHelperCreateWts: null, chainHelperWtsSelected: null})
        else if (row.type === 'BID' && (!row.stock && !row.price)) onRowChange({...row, price: attachedTo.price, stock: attachedTo.stock, attachedTo: attachedTo?.id, attachedFallback: null, chainHelperCreateWts: null, chainHelperWtsSelected: null})
        else onRowChange({...row, attachedTo: attachedTo?.id, attachedFallback: null, chainHelperCreateWts: null, chainHelperWtsSelected: null})
    }

    if (loading || !response) return <Loading loading={loading} />
    if (response.status === 'error') return <Typography color='palette.error.main'>{response.message ? response.message : 'Something goes wrong'}</Typography>

    const onTabChange = (event, value) => {
        onRowChange({...row, attachedTo: null, attachedFallback: null, chainHelperCreateWts: null, chainHelperWtsSelected: null})
        setTab(value)
    }

    return (
        <React.Fragment>

            <Tabs value={tab} onChange={onTabChange}>
                <Tab value={"attach"} label={getAttachTabLabel(row.type)} />
                {/*<Tab value={"fallBack"} label={"Create"} />*/}
            </Tabs>

            <Paper sx={{padding: 2}}>
                <Box
                    sx={{display: tab === 'attach' ? 'block' : 'none'}}
                >
                    <RenderSelectableRows rows={response.data} selected={row.attachedTo} onSelect={onSelect} />
                </Box>

                <Box
                    sx={{display: tab === 'fallBack' ? 'block' : 'none'}}
                >
                    {/*{row.type === 'BID' ? <RenderCreateBid*/}
                        // display={tab === 'fallBack'}
                        {/*display={true}*/}
                        {/*onRowChange={onRowChange} row={row}/> : <RenderCreateOther display={tab === 'fallBack'} onRowChange={onRowChange} row={row}/>}*/}
                </Box>
            </Paper>

            {row.type !== 'BID' && <>
                <Tabs value={tab} onChange={onTabChange}>
                    <Tab value={"attach"} label={'Select WTS'} />
                    {/*<Tab value={"fallBack"} label={"Create"} />*/}
                </Tabs>
                <Paper sx={{padding: 2}}>
                    <Box>
                        <RenderCreateOther display={tab === 'fallBack'} onRowChange={onRowChange} row={row}/>
                    </Box>
                </Paper>
            </>}

        </React.Fragment>
    )
})