import React from 'react'
import {Container, Typography} from "@mui/material";
import AddLead from "../components/leads/addLead";
import LeadsListController from "../components/leads/leadsList.controller";

export default function LeadsPage() {
    return (
        <Container>
            <Typography sx={{textAlign: 'left', my: 2, fontWeight: 'bold'}} variant="h4">Leads</Typography>
            <AddLead/>
            <LeadsListController/>
        </Container>
    )
}