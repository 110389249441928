import React from "react";
import {
	Box,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { ArrowRight, StarOutline, Star } from "@mui/icons-material";
import UserCard from "../userCard";


export default function DistributorList({ distributors, onSelect }) {
	const drawRow = (distri) => {
		return (
			<ListItem hover sx={{ ":hover": { cursor: 'pointer' }, bgcolor: 'background.paper', borderRadius: 1, my: 1 }} onClick={() => onSelect && onSelect(distri.id)} secondaryAction={
				<IconButton edge="end" aria-label="see">
					<ArrowRight />
				</IconButton>
			}>

				{distri && <ListItemIcon>
					{!distri.isFavorite ? <StarOutline /> : <Star />}
				</ListItemIcon>}

				<ListItemText
					primary={distri.name}
					secondary={distri.email}
				/>
			</ListItem>
		)
	}
	return <Box>
		<List>
			{distributors && distributors.map(cl => <UserCard type="SELLER" user={cl} onClick={() => onSelect(cl.id)}/>)}
		</List>
	</Box>

}