import React, { useCallback, useEffect, useState } from 'react'
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Paper, TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import NumTextField from "../../NumTextField";
import ProductSearch from "../../product/product.search";
import { Add, Delete } from "@mui/icons-material";
import { REQUEST_METHOD, useLazyRequest } from "../../../hooks/request";
import { LoadingButton } from "@mui/lab";
import { useRefetchContext } from "../../../contexts/refetch.context";
import UserSearch from "../../clients/user.search";
import moment from "moment";
import UsersSelector from '../../clients/users.selector';
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";

const CreateWts = React.memo(function ({ wts, onChange, onDelete }) {
	const onProductChange = (product) => onChange({ ...wts, productId: product.id, product: product })
	const onPriceChange = (price) => onChange({ ...wts, price })
	const onStockChange = (stock) => onChange({ ...wts, stock })
	const onETAChange = (eta) => onChange({ ...wts, eta })
	const onExpireAtChange = (expireAt) => onChange({ ...wts, expireAt })
	const onNoteChange = (note) => onChange({ ...wts, meta: { ...wts.meta, note: note.target.value } })


	return (
		<Paper sx={{ p: 2, my: 1 }}>
			<Box>
				<ProductSearch sx={{ mb: 2 }} defaultSelected={wts.productId} onSelect={onProductChange} />
				<NumTextField sx={{ mr: { md: 2 }, mb: { xs: 2 } }} label={'Quantity'} value={wts.stock} onChange={onStockChange} />
				<NumTextField sx={{ mr: { md: 2 }, mb: { xs: 2 } }} label={'Price'} value={wts.price} onChange={onPriceChange} />
				<NumTextField sx={{ mr: { md: 2 }, mb: { xs: 2 } }} label={'ETA'} value={wts.eta} onChange={onETAChange} />
				<NumTextField label={'Expire (Days)'} value={wts.expireAt} onChange={onExpireAtChange} />
				<TextField fullWidth label="Note" multiline={true} onChange={onNoteChange} />
			</Box>

			<Divider sx={{ my: 2 }} />
			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button variant={'contained'} color={'error'} onClick={onDelete}><Delete /> </Button>
			</Box>
		</Paper>
	)
})

function MultiCreateWts({ type, wtss, setWtss, userId, setUsers, buyerId }) {

	const onChange = useCallback((index) => (value) => {
		setWtss(prev => {
			const res = prev.map((_, _index) => {
				if (index !== _index) return _
				return value
			})
			if ((value.productId !== '' && value.productId !== null && value.productId !== 0) &&
				res.every(elem => elem.productId !== 0 && elem.productId !== '' && elem.productId !== null)) {
				res.push({ productId: null, price: 0, stock: 0, userId, type: type, eta: null, expireAt: 10 })
			}

			return res
		})
	}, [])

	const onDelete = useCallback((index) => () => {
		setWtss(prev => prev.filter((_, _index) => index !== _index))
	}, [])

	const onCreate = () => setWtss([...wtss, { productId: null, price: 0, stock: 0, userId, type: type, eta: null, expireAt: 10 }])

	return (
		<Container sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
			{
				wtss.map((elem, index) =>
					<CreateWts
						wts={elem}
						onChange={onChange(index)}
						onDelete={onDelete(index)}
						key={`MultiCreateWts_${index}`} />)
			}
			<Button variant='outlined' sx={{ flex: 1 }} onClick={onCreate}><Add /></Button>
			{type === 'ask' && <Paper sx={{ p: 2, my: 1 }}>
				<UsersSelector onSelect={setUsers} leads={false} defaultIncludedIds={buyerId ? [buyerId] : []} />
			</Paper>}
		</Container>
	)
}

export default function MultiCreateDialog({ data, onClose }) {
	const { response, loading, request } = useLazyRequest(`/book/many`, REQUEST_METHOD.PUT)
	const { setToRefetch } = useRefetchContext()
	const [usrId, setUsrId] = useState(undefined);
	const [wtss, setWtss] = useState([])
	const [users, setUsers] = useState(undefined)
	const [txt, setTxt] = useState(undefined)
	const { enqueueSnackbar } = useSnackbar()


	const _onSubmit = () => request({}, wtss.filter(elem => elem.productId !== 0 && elem.productId !== null && elem.productId !== '').map(el => ({
		productId: el.productId,
		price: el.price,
		stock: el.stock,
		userId: usrId,
		type: el.type,
		expireAt: el.expireAt ? moment().add(el.expireAt, 'd') : undefined,
		meta: el.eta ? { ...el.meta, estimateTimeOfArrival: el.eta } : el.meta,
		scopeIds: users?.tags || [], includedUserIds: users?.include || [], excludedUserIds: users?.exclude || []
	})))

	useEffect(() => {
		setWtss([
			{ productId: data?.productId ?? null, price: 0, stock: 0, userId: usrId, type: data.type, eta: null, expireAt: 10 }
		])
		if (data?.type === 'wtb') {
			if (!usrId && data?.buyerId) {
				setUsrId(data?.buyerId)
			}
		} else if (data?.type === 'ask') {
			setUsrId(undefined)
		} else if (data?.type === 'bid') {
			setUsrId(undefined)
		} else if (data?.type === 'wts') {
			if (data?.sellerId) {
				setUsrId(data?.sellerId)
			}
		}
		// if (!usrId) {
		// 	if (data?.buyerId && data?.type === 'wtb') setUsrId(data?.buyerId)
		// 	else if (data?.sellerId && data?.type === 'bid') setUsrId(data?.sellerId)
		// 	else if (data?.sellerId) setUsrId(data?.sellerId)
		// }
	}, [data])

	useEffect(() => {
		if (response) {
			setWtss([
				{ productId: data?.productId ?? null, price: 0, stock: 0, userId: usrId, type: data.type, eta: null, expireAt: 10 }
			])
			setToRefetch(['/book'])
			onClose()
		}
	}, [response])

	useEffect(() => {
		if (wtss) {
			let res = ''
			for (let row of wtss) {
				if (row && row.product)
					res += `${row.product.name}${(row.stock && row.stock > 0) ? ` - ${row.stock} pcs` : ''}${(row.price && row.price > 0) ? ` - ${row.price}€` : ''}\r`
			}
			setTxt(res)
		}
	}, [wtss])

	const canSubmit = () => {
		if (data?.type === 'ask' && (users?.include?.length || users?.tags?.length)) {
			return false
		} else if (data?.type === 'wts' && usrId) {
			return false
		} else if (data?.type === 'bid' && usrId) {
			return false
		} else if (data?.type === 'wtb' && usrId) {
			return false
		}
		return true;
	}


	return (
		<React.Fragment>
			<Dialog fullScreen={true} open={data.open} onClose={onClose}>

				<DialogTitle>
					<Container>
						<Typography variant={'h5'}>Create {data.type && data.type.toUpperCase()}</Typography>
					</Container>
				</DialogTitle>

				<DialogContent dividers>
					{['wts', 'bid'].find(el => el === data?.type) && <Container>
						<UserSearch onSelect={(usr) => setUsrId(usr?.id)} type={['DISTRIBUTOR']} label={"Seller"} defaultValueId={data?.sellerId} />
					</Container>}
					{['wtb'].find(el => el === data?.type) && <Container>
						<UserSearch onSelect={(usr) => setUsrId(usr?.id)} type={['CUSTOMER']} label={"Buyer"} defaultValueId={data?.buyerId} />
					</Container>}
					<MultiCreateWts setWtss={setWtss} wtss={wtss} userId={usrId} type={data.type} buyerId={data?.buyerId} setUsers={setUsers} />
					<Container>
						<Box sx={{ my: 1, display: "flex", justifyContent: 'flex-end' }}>
							<Button variant="contained" color="primary" onClick={() => {
								copy(txt, { format: 'text/plain' })
								enqueueSnackbar('Text copied to clipboard', { variant: "success" })
							}}>
								Copy text
							</Button>
						</Box>

						<TextField
							multiline
							fullWidth
							onChange={value => setTxt(value.target.value)}
							value={txt}
							sx={{}}
						/>

					</Container>

				</DialogContent>
				<DialogActions>
					<Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button onClick={onClose}>Cancel</Button>
						<LoadingButton loading={loading} variant={'contained'} disabled={canSubmit()} onClick={_onSubmit}>Submit</LoadingButton>
					</Container>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}