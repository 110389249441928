import React, {useState} from 'react'
import makeStyles from '@mui/styles/makeStyles';
import {ArrowDropUp, ArrowDropDown} from "@mui/icons-material"
import {green, red} from "@mui/material/colors";
import {Typography, useMediaQuery} from "@mui/material";

const useStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}))

const TREND = {
    NONE: 'NONE',
    UP: 'UP',
    DOWN: 'DOWN',

}

export default function PriceTrend({marketPrice, books, diffPrice = null,className}) {
    const classes = useStyle()
    // const [trend, setTrend] = useState()
    const [visible, setVisible] = useState(marketPrice);
    const matches = useMediaQuery((theme) => theme.breakpoints.down('md'));


    const getTrend = () => {
        if (diffPrice) {
            if (diffPrice > 0) return TREND.UP;
            else if (diffPrice < 0) return TREND.DOWN;
        }
        else {
            let i = 0
            let avg = 0
            while (++i < books.length && i <= 3) { //skip the first elem
                if (avg) avg = (books[i].price + avg) / 2
                else avg = books[i].price
            }

            if (marketPrice > avg) return TREND.UP
            else return TREND.DOWN;
        }
    }

    const drawTrend = () => {
        const trend = getTrend()
        if (trend === TREND.UP) return <ArrowDropUp width={"10px"} height={"10px"} style={{color: green[500], width: "15px", height: "15px"}}/>
        if (trend === TREND.DOWN) return <ArrowDropDown width={"10px"} height={"10px"} style={{color: red[500], width: "15px", height: "15px"}}/>
        return ''
    }

    return (
        <div className={`${classes.root} ${className}`}>
            <Typography>{!matches && '€ '}{visible}</Typography>
            {drawTrend()}
        </div>
    )
}