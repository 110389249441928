import React, { useRef, useState } from "react";
import {
  Box,
  Card,
  IconButton,
  CircularProgress,
  Typography,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import {
  BookmarkBorder,
  MoreVert,
  Star,
  StarOutline,
  Bookmark,
} from "@mui/icons-material";
import ProductMetrics from "./productMetrics";
import { AutoSizer, List, WindowScroller } from "react-virtualized";

//export default function Products({products, onMapUnmappedProduct, onCustomOffer, requestWatch, onSelectedChange, selected, extra}) {
export default React.memo(function Products({
  products,
  requestWatch,
  onMapUnmappedProduct,
  extra,
  onSelectProduct,
  addRFQ,
  deleteRFQ,
}) {
  // const [open, setOpen] = useState(null)
  const [more, setMore] = useState(null);
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  // const history = useHistory()

  const listRef = useRef();

  const changeWatch = (event, productId, watch) => {
    event.stopPropagation();
    if (productId) {
      requestWatch({}, { productId: productId, watched: !!watch });
    }
  };

  const changeRFQ = (event, productId, add) => {
    event.stopPropagation();
    if (productId && addRFQ && deleteRFQ) {
      if (add) {
        addRFQ({}, [{ productId: productId }]);
      } else {
        deleteRFQ({ productId: productId }, {});
      }
    }
  };

  const rowRenderer = ({ key, index, style, parent, isScrolling }) => {
    return (
      <div style={style} key={key}>
        <Card
          sx={{
            my: 2,
            p: 1,
            borderRadius: 2,
            borderWidth: 5,
            borderColor: "palette.divider",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            // onClick={() => handleClickRow(index)}
            onClick={() => onSelectProduct(products[index].id)}
          >
            <Box sx={{ flex: 9 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontSize={"1rem"}
                  textAlign={"left"}
                  sx={{
                    fontSize: { xs: 16, md: 19 },
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                  }}
                >
                  {" "}
                  {products[index].name}{" "}
                  {isScrolling && <CircularProgress size={12} />}{" "}
                </Typography>
                <Box
                  sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <>
                    <IconButton
                      onClick={(event) =>
                        changeWatch(
                          event,
                          products[index].id,
                          !products[index].isWatched
                        )
                      }
                    >
                      {!products[index].isWatched ? <StarOutline /> : <Star />}
                    </IconButton>
                    <IconButton
                      onClick={(event) =>
                        changeRFQ(
                          event,
                          products[index].id,
                          !products[index].rfqs?.length
                        )
                      }
                    >
                      {!products[index].rfqs?.length ? (
                        <BookmarkBorder />
                      ) : (
                        <Bookmark />
                      )}
                    </IconButton>
                  </>
                  {/*{menu(products[index], true)}*/}
                </Box>
              </Box>
              <ProductMetrics product={products[index]} />
            </Box>

            {/*<Box sx={{flex: 1, display: 'flex', flexDirection: "column", justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>*/}
            {/*    <IconButton onClick={() => changeWatch(products[index].id, !products[index].isWatched)}>*/}
            {/*        {!products[index].isWatched ? <StarOutline /> : <Star/>}*/}
            {/*    </IconButton>*/}
            {/*    {checkBox(products[index], true)}*/}
            {/*    {menu(products[index], true)}*/}
            {/*</Box>*/}
          </Box>

          {extra && extra(products[index])}
          {/*<Box>*/}
          {/*    <Collapse in={open === index} timeout={0} unmountOnExit onExited={() =>  {}}>*/}
          {/*        {open === index && <BookProductSummaryController onLoaded={() => {}} productId={products[index].id}/>}*/}
          {/*    </Collapse>*/}
          {/*</Box>*/}
        </Card>
      </div>
    );
  };

  return (
    <Box>
      <WindowScroller>
        {({ height, isScrolling, onChildScroll, scrollTop }) => (
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              <List
                ref={listRef}
                autoHeight
                height={height}
                onScroll={onChildScroll}
                isScrolling={isScrolling}
                rowCount={products.length}
                // rowHeight={(ref) => (open !== null && ref.index == open) ? 650 : 250}
                rowHeight={() => (matches ? 240 : 215)}
                rowRenderer={rowRenderer}
                scrollTop={scrollTop}
                width={width}
                overscanRowCount={10}
                scrollToAlignment={"start"}
              />
            )}
          </AutoSizer>
        )}
      </WindowScroller>

      {/*{products && products.map((product, index) => row(product, index))}*/}
    </Box>
  );
});
