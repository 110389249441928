import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import PriceTrend from "../priceTrend/priceTrend";
import {
  extractDistributorOffer,
  extractResellerOffer,
} from "../../utils/book.utils";

function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

function Item({ label, value }) {
  return (
    <Paper
      elevation={2}
      sx={{
        p: "5px",
        border: 1,
        borderColor: "divider",
        borderRadius: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        justifyContent: "space-between",
        gap: 1,
      }}
    >
      <Typography variant="caption">{label}</Typography>
      <Typography sx={{ alignSelf: "flex-end", fontSize: "1.2rem" }} noWrap>
        {value}
      </Typography>
    </Paper>
  );
}

export default React.memo(function ProductMetrics({ product }) {
  const offerRow = extractDistributorOffer(product.book);

  if (!product.metrics) return <Box />;

  return (
    <Box
      sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 1 }}
    >
      <Item
        label={"P"}
        value={
          <React.Fragment>
            <span>
              {product.metrics?.bestPrice && product.metrics?.bestPrice}
            </span>{" "}
            {product.metrics?.priceAvg &&
              offerRow.length > 1 &&
              `(${product.metrics?.priceAvg})`}
          </React.Fragment>
        }
      />
      <Item
        label={"B"}
        value={
          <React.Fragment>
            {product.metrics?.lastClientCompletedBuy || "-"}
          </React.Fragment>
        }
      />
      <Item
        label={"A"}
        value={
          <React.Fragment>
            {product.metrics?.lastClientAskedBuy || "-"}
          </React.Fragment>
        }
      />
      <Item
        label={"M"}
        value={
          <PriceTrend
            marketPrice={product.metrics?.marketPrice}
            books={extractResellerOffer(product.book)}
            diffPrice={product.metrics?.diffPrice || "-"}
          />
        }
      />
      <Item
        label={"QTY"}
        value={
          <React.Fragment>
            {product.metrics?.bestStock}{" "}
            {product.metrics?.bestStock !== product.metrics?.totalStock &&
              `(${product.metrics?.totalStock})`}
          </React.Fragment>
        }
      />
      <Item
        label={"Diff. price"}
        value={
          <React.Fragment>
            {product.metrics?.diffPrice
              ? numberWithSpaces(Number(product.metrics?.diffPrice).toFixed(2))
              : "-"}
          </React.Fragment>
        }
      />
      <Item
        label={"PP"}
        value={
          <React.Fragment>
            {product.metrics?.totalProfit
              ? numberWithSpaces(
                  Number(product.metrics?.totalProfit).toFixed(2)
                )
              : "-"}
          </React.Fragment>
        }
      />
      <Item
        label={"ChiDa"}
        value={
          <React.Fragment>
            {product.metrics?.bestChida &&
              numberWithSpaces(Number(product.metrics?.totalChida).toFixed(2))}
          </React.Fragment>
        }
      />
    </Box>
  );
});
