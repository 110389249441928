import React, {useState} from 'react'
import {Button, Container, Typography} from "@mui/material";
import {Box} from "@mui/system";
import RFQController from '../components/rfq/rfq.controller';

export default function RFQPage () {

	return <Container>
		<Typography sx={{textAlign: 'left', my: 2, fontWeight: 'bold'}} variant="h4">RFQs</Typography>
		<RFQController/>
	</Container>
}