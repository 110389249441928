import React from 'react'
import { Container } from "@mui/material";
import AuthPage from "../pages/auth.page";
import PrivateRoute from "./privateRoute";
import BoardPage from "../pages/board.page";
import ProductsPage from "../pages/products.page";
import ProductPage from "../pages/product.page";

import {
    Redirect,
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import CrawlerPage from "../pages/crawler.page";
import ClientsPage from "../pages/clients.page";
import ClientPage from "../pages/client.page";
import TagsPage from "../pages/tags.page";
import CreateProductPage from "../components/product/createProduct";
import LeadsPage from "../pages/leads.page";
import LeadPage from "../pages/lead.page";
import BookPage from "../pages/book.page";
import DistributorPage from '../pages/distributor.page.js'
import DistributorsPage from '../pages/distributors.page.js'
import CreateRowPage from "../pages/createRow.page";
import RowPage from "../pages/row.page";
import AuthPage2 from '../pages/auth/auth.page'
import SignInEmailPage from "../pages/auth/signInEmail.page";
import SetPasswordPage from "../pages/auth/setPassword.page";
import {useAuth} from "../contexts/auth.context";
import Loading from "../components/loading";
import TargetPricePage from "../pages/targetPrice.page";
import RFQPage from '../pages/rfq.page';
import WatchListPage from '../pages/watchlist.page';

export default function AppRouter() {
    const {loading} = useAuth()
    return (
        <Loading loading={loading}>
            <Router>
                <Switch>
                    <Route path="/login">
                        <Container maxWidth="lg" id="root-container">
                            <AuthPage />
                        </Container>
                    </Route>

                    <Route path="/auth/signIn">
                        <AuthPage2/>
                    </Route>
                    <Route path="/auth/emailSignIn">
                        <SignInEmailPage/>
                    </Route>
                    <Route path="/auth/setPassword">
                        <SetPasswordPage/>
                    </Route>
                    <PrivateRoute exact path="/">
                        <Redirect to="/product" />
                    </PrivateRoute>

                    <PrivateRoute exact path="/board">
                        <BoardPage />
                    </PrivateRoute>

                    <PrivateRoute path="/product" >
                        <React.Fragment>
                            <Route path="/product">
                                <ProductsPage />
                            </Route>

                            <Switch>
                                <Route exact path="/product/create">
                                    <CreateProductPage />
                                </Route>
                                <Route path="/product/:productId/book/edit-row/:rowType">
                                    <CreateRowPage />
                                </Route>
                                <Route path="/product/book/:rowId">
                                    <RowPage />
                                </Route>
                                <Route path="/product/:id">
                                    <ProductPage />
                                </Route>
                            </Switch>
                        </React.Fragment>
                    </PrivateRoute>

                    <PrivateRoute path="/book">
                        <React.Fragment>
                            <Route path="/book">
                                <BookPage />
                            </Route>

                            <Switch>
                                <Route path="/book/:rowId">
                                    <RowPage />
                                </Route>
                            </Switch>
                        </React.Fragment>
                    </PrivateRoute>

                    <PrivateRoute path="/clients">
                        <ClientsPage />
                    </PrivateRoute>

                    <PrivateRoute path="/client/:id">
                        <ClientPage />
                    </PrivateRoute>

                    <PrivateRoute path="/distributors">
                        <DistributorsPage />
                    </PrivateRoute>

                    <PrivateRoute path="/distributor/:id">
                        <DistributorPage />
                    </PrivateRoute>

                    <PrivateRoute path="/leads">
                        <LeadsPage />
                    </PrivateRoute>

                    <PrivateRoute path="/lead/:id">
                        <LeadPage />
                    </PrivateRoute>

                    <PrivateRoute path="/tags">
                        <TagsPage />
                    </PrivateRoute>

                    <PrivateRoute path="/tags">
                        <TagsPage />
                    </PrivateRoute>

                    <PrivateRoute path="/crawler">
                        <CrawlerPage />
                    </PrivateRoute>

                    <PrivateRoute path="/targetprices">
                        <TargetPricePage />
                    </PrivateRoute>

                    <PrivateRoute path="/rfq">
                        <RFQPage />
                    </PrivateRoute>

                    <PrivateRoute path="/rft">
                        <WatchListPage />
                    </PrivateRoute>
                </Switch>
            </Router>
        </Loading>
    )
}