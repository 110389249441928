import React, {useEffect, useState} from 'react'
import {REQUEST_METHOD, REQUEST_STATUS, useLazyRequest} from "../../hooks/request";
import {Autocomplete, Box, Button, Divider, Grid, IconButton, TextField} from "@mui/material";
import Close from "@mui/icons-material/Close";
import {LoadingButton} from "@mui/lab";

export default function UnmappedPriceTrendSolver({product, onSubmit}) {
    const {request, response, loading} = useLazyRequest(`/product/unmapped-price-trends`, REQUEST_METHOD.GET)
    const {request: request2, response: response2, loading: loading2} = useLazyRequest(`/product/link-price-trends`, REQUEST_METHOD.POST)
    const [unmapped, setUnmapped] = useState()
    const [mappedPrice, setMappedPrice] = useState(undefined)

    useEffect(() => {
        request()
    }, [product])

    useEffect(() => {
        if (response && response.status === REQUEST_STATUS.SUCCESS) {
            setUnmapped(response.data)
        }
    }, [response])

    useEffect(() => {
        if (response2 && response.status === REQUEST_STATUS.SUCCESS) {
            onSubmit && onSubmit(null);
        }
    }, [response2])


    const linkPriceTrendNameToProduct = () => {
        if (mappedPrice) {
            request2({},{productId: product.id, priceName: mappedPrice.name})
        }
    }

    return (
        <Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <h2>Link Prices Name to Product</h2>
                <IconButton onClick={() => onSubmit(null)} size="large">
                    <Close/>
                </IconButton>
            </Box>
            <Divider />

            <Box sx={{display: 'flex', flexDirection: "column", gap: 2}}>
                <Box display="flex">
                    <h4>Product: {product && product.name}</h4>
                    <Box flexGrow="1"/>
                </Box>
                { unmapped && <Autocomplete
                    id="combo-box-demo"
                    options={unmapped}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => setMappedPrice(value)}
                    style={{ minWidth: "60%" }}
                    renderInput={(params) => <TextField {...params} label="Unmapped names" variant="outlined" />}
                />}
                <LoadingButton loading={loading || loading2} title="Add Link" onClick={linkPriceTrendNameToProduct}  variant="contained">Add link</LoadingButton>
            </Box>
        </Box>
    )
}