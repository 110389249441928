import React from "react";
import {
	Box, Button, Chip,
	IconButton,
	List,
	ListItem,
	ListItemText, Typography,
} from "@mui/material";
import {ArrowRight, Delete} from "@mui/icons-material";
import moment from "moment";
import {REQUEST_METHOD, useLazyRequest, useRequest} from "../../hooks/request";

export default function TargetPriceList({tps, onDelete}){
	const drawRow = (tp) => {
		return (
			<Box sx={{":hover": {cursor: 'pointer'}, bgcolor: 'background.paper', borderRadius: 1, my: 1, p: 2}} key={tp.id}>
				<Typography sx={{fontSize: {xs: 17, md: 19}}}>{tp.product?.name}</Typography>
				<Typography sx={{fontSize: {xs: 12, md: 14}}}>Created at: {moment(tp.createdAt).format("DD-MM-YYYY")}</Typography>
				<Typography sx={{fontSize: {xs: 12, md: 14}}}>Expire at: {tp.expireAt ? moment(tp.expireAt).format("DD-MM-YYYY") : 'never'}</Typography>
				{tp.scope?.length ?  <Box>
					<Typography>Groups</Typography>
					{tp.scope.map(scope => <Chip
						key={scope.id}
						color="primary"
						label={scope.name}
						sx={{margin: (theme) => theme.spacing(1)}}
					/>)}
					</Box> : undefined}
				{tp.includedUsers?.length ?  <Box>
					<Typography>Included Users</Typography>
					{tp.includedUsers.map(scope => <Chip
						key={scope.id}
						color="primary"
						label={scope.name}
						sx={{margin: (theme) => theme.spacing(1)}}
					/>)}
				</Box> : undefined}
				{tp.excludedUsers?.length ?  <Box>
					<Typography>Excluded Users</Typography>
					{tp.excludedUsers.map(scope => <Chip
						key={scope.id}
						color="error"
						label={scope.name}
						sx={{margin: (theme) => theme.spacing(1)}}
					/>)}
				</Box> : undefined}
				<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
					<Button variant={'contained'} color={'error'} onClick={() => onDelete && onDelete(tp.id)}><Delete /> </Button>
				</Box>
			</Box>
		)
	}
	return  <Box>
		{tps?.map(cl => drawRow(cl))}
	</Box>

}